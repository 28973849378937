import React from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import AddEmployee from "../employees/AddEmployee";
import TextInput from "../../components/TextInput";

import TimezoneSelect from "react-timezone-select";
import { TextInputLabel } from "../../components/TextInput/TextInput.style";

export function Input({ label, ...rest }) {
  return (
    <Form.Group>
      <Label className="label">{label}</Label>
      <Form.Control {...rest} />
    </Form.Group>
  );
}

function AddTeam({
  hidePassword,
  errors,
  toggleHidePassword,
  handleChange,
  values,
  timezone,
  handleTimeZoneSelect,
  type,
}) {
  return (
    <Container>
      <div>
        <h1 style={{ paddingBottom: 5 }} className="facility-text">
          Team details
        </h1>
        <TextInput
          type="text"
          fullWidth
          name="name"
          error={errors && errors.name}
          errorMessage="Name field is required"
          onChange={handleChange}
          value={values.name || ""}
          label="Name of Team*"
          placeholder="Name of Team"
        />
        <TextInputLabel className={"mb-1"}>Timezone</TextInputLabel>
        <TimezoneSelect
          placeholder="Select timezone"
          label="Name of timezone"
          labelStyle="original"
          styles={{ width: "100%" }}
          value={timezone}
          onChange={handleTimeZoneSelect}
          timezones={{
            'Africa/Lagos': 'WAT',
            'America/Panama': 'EST',
          }}
        />
        <h2 style={{ paddingTop: 15 }} className="facility-text">
          Manager details
        </h2>
        <AddEmployee
          errors={errors}
          formType={type}
          hidePassword={hidePassword}
          toggleHidePassword={toggleHidePassword}
          handleChange={handleChange}
          values={values.manager}
        />
      </div>
    </Container>
  );
}

export default AddTeam;

const Container = styled.div`
  .top {
    display: flex;
    justify-content: space-between;
    margin: 32px 0;
  }
  .text {
    color: #003271;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
  }
  .facility-text {
    font-size: 18.72px;
    font-weight: bold;
    line-height: 23px;
    color: #003271;
    margin-top:10%;
    margin-bottom: 2px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;

  }

  .button {
    box-sizing: border-box;
    height: 46px;
    width: 130px;
    color: #7B8D8D;
    border: 1px solid #7B8D8D;
    border-radius: 5px;
    background-color: #FFFFFF;
  }

  .button--red {
    margin-left: 20px;
    box-sizing: border-box;
    height: 46px;
    width: 130px;
    border: 1px solid #f97b6b;
    border-radius: 5px;
    background-color: #f97b6b;
  }
`;

export const Label = styled(Form.Label)`
  color: #6d7278;
`;
