import React from "react";

const ICON_TYPE = "FULL";

function getBadgeImageName(status) {
  let badge = "";
  switch (status) {
    case "APPROVED":
      badge = "approved";
      break;
    case "PENDING":
      badge = "pending";
      break;
    case "REJECTED":
      badge = "rejected";
      break;
    case "UNCERTAIN":
      badge = "uncertain";
      break;
    case "NOT_ALLOWED":
      badge = "not-allowed";
      break;
    default:
      badge = "not-allowed";
      break;
  }
  return ICON_TYPE === "FULL"
    ? `${badge}-f`
    : ICON_TYPE === "HOLLOW"
    ? `${badge}-o`
    : badge;
}

function VerificationBadge({ onClick, status }) {
  let badge = getBadgeImageName(status);

  return (
    <div className={"w-100 d-flex justify-content-end "} style={{}}>
      <img
        height={30}
        src={`/img/badges/badges-${badge}.svg`}
        title={status}
        alt={"Badge"}
        onClick={onClick}
      />
    </div>
  );
}

export default VerificationBadge;
