import Drawer from "../../components/DrawerWrapper";
import AddWorklog from "../worklogs/AddWorklog";
import React, { useState } from "react";
import * as PropTypes from "prop-types";
import uuid from "uuid/v4";
import moment from "moment";
import useOfflineOnline from "../../hooks/useOfflineStatus";
import { isEmpty } from "../../helpers/utils";
const taskFieldId = uuid();
const initialState = { [taskFieldId] : {} };
const projectFieldId = uuid();
const initialProject = { [projectFieldId] : {} };

export function AddWorklogDrawer ({
                                    open, onClose, shiftHappens, match, setAddWorklogDrawerOpen,
                                    worklogs, employee, user, behaviours, createWorklog,
                                  }) {
  const [ data, setData ] = useState(initialState);
  const [ record, setRecord ] = useState({ projects : [], tasks : [] });
  const [ projectData, setProjectData ] = useState(initialProject);
  const [ feedback, setFeedback ] = useState("");
  const [ feeling, setFeeling ] = useState("");
  const [ error, setError ] = useState(false);
  const { isOnline } = useOfflineOnline();


  function onRemove (id) {
    // if (Object.values(data).length === 1) return;
    return function () {
      const newState = { ...data };
      delete newState[id];
      setData(newState);
    };
  }

  function onRemoveProject (id) {
    // if (Object.values(projectData).length === 1) return;
    return function () {
      const newState = { ...projectData };
      delete newState[id];
      setProjectData(newState);
    };
  }

  function onAddProject () {
    const id = uuid();
    const newState = { ...projectData, [id] : [] };
    setProjectData(newState);
  }

  function onAddRow () {
    const id = uuid();
    const newState = { ...data, [id] : [] };
    setData(newState);
  }

  const handleChange = ({ target }) => {
    if (target.name === "feedback") {
      return setRecord({
        ...record,
        feedback : target.value,
      });
    } else if (record.skills && record.skills.length) {
      let skills = record.skills;
      let { value, checked } = target;

      if (checked) {
        return setRecord({
          ...record,
          skills : [ ...skills, value ],
        });
      } else {
        return setRecord({
          ...record,
          skills : skills.filter((skill) => skill !== value),
        });
      }
    } else {
      setRecord({
        ...record,
        skills : [ target.value ],
      });
    }
  };

  function onChange (id) {
    return function ({ target }) {
      setRecord({
        ...record,
        [target.name] : record[target.name].map((el, i) => {
          let found = i === id;
          if (found) {
            el = Object.assign(el, { ...found, log : target.value });
          }
          return el;
        }),
      });
    };
  }

  function handleSelect () {
    return function ({ id }, { name }) {
      if (name === "projects") {
        return setRecord({
          ...record,
          projects : [ ...record.projects, { projectId : id } ],
        });
      }
      return setRecord({
        ...record,
        tasks : [ ...record.tasks, { taskId : id } ],
      });
    };
  }

  function handleFeeling (feeling) {
    setFeeling(feeling);
  }

  const handleFeedbackFormatChange = (value) => {
    setFeedback(value);
  };

  function onSubmit (type) {

    setAddWorklogDrawerOpen( isEmpty(feeling) );
    const rep = {
      ...record,
      feedback,
      mood : feeling,
      projects : record.projects.filter((pro) => Boolean(pro.log)), // Remove all projects and tasks with no log
      tasks : record.tasks.filter((task) => Boolean(task.log)),
      smeId : (user && match?.params?.employerId) || user.employers?.[0]?.id,
    };
    return createWorklog(rep, isOnline, user.id);
  }

  let today = new Date();

  let addWorklogHeader = (
    <h4>
      Worklog - <span>{moment(today).format("ddd")}</span>{" "}
      <span className={"font-weight-600"}>
        {moment(today).format("MMM DD, YYYY")}
      </span>
    </h4>
  );

  return (
    <Drawer
      width="700px"
      formType={"create"}
      title={addWorklogHeader}
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
    >
      <AddWorklog
        isShiftHappens={shiftHappens}
        onRemoveProject={onRemoveProject}
        projectData={projectData}
        onAddProject={onAddProject}
        employee={employee}
        worklogs={worklogs}
        tasks={employee?.tasks || []}
        projects={employee?.projects || []}
        fields={data}
        feeling={feeling}
        record={record}
        handleFeeling={handleFeeling}
        handleSelect={handleSelect}
        onAddRow={onAddRow}
        onRemove={onRemove}
        handleChange={handleChange}
        behaviours={behaviours}
        onChange={onChange}
        feedback={feedback}
        onFeedbackFormatChange={handleFeedbackFormatChange}
      />
    </Drawer>
  );
}

AddWorklogDrawer.propTypes = {
  title : PropTypes.any,
  open : PropTypes.bool,
  onSubmit : PropTypes.func,
  onClose : PropTypes.func,
  shiftHappens : PropTypes.any,
  onRemoveProject : PropTypes.func,
  projectData : PropTypes.shape({}),
  onAddProject : PropTypes.func,
  worklogs : PropTypes.any,
  employee : PropTypes.any,
  user : PropTypes.shape({}),
  fields : PropTypes.shape({}),
  handleSelect : PropTypes.func,
  onAddRow : PropTypes.func,
  onRemove : PropTypes.func,
  handleChange : PropTypes.func,
  behaviours : PropTypes.any,
  onChange : PropTypes.func,
  feedback : PropTypes.string,
  onFeedbackFormatChange : PropTypes.func,
};
