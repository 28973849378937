import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

export default class Graph extends PureComponent {
  render() {
    const { data } = this.props;
    return (
      <LineChart width={800}  height={300} data={data} style={{marginTop:20}}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="createdAt" />
        <YAxis dataKey="worklogs" />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="worklogs" stroke="#8884d8" activeDot={{ r: 8 }} />
      </LineChart>
    );
  }
}

Graph.propTypes = {
  data: PropTypes.array,
};
