export const CLOSE_SNACK_BAR = 'CLOSE_SNACK_BAR';
export const SHOW_SNACK_SUCCESS = 'SHOW_SNACK_SUCCESS';
export const SHOW_SNACK_ERROR = 'SHOW_SNACK_ERROR';
export const SHOW_SNACK_WARNING = 'SHOW_SNACK_WARNING';

export const INITIAL_STATE = {
  open: false,
  success: false,
  error: false,
  warning: false,
  message: '',
};

export const closeSnackbar = () => ({
  type: CLOSE_SNACK_BAR,
});

export const showSnackbarSuccess = data => ({
  type: SHOW_SNACK_SUCCESS,
  ...data,
});

export const showSnackbarWarning = data => ({
  type: SHOW_SNACK_WARNING,
  ...data,
});

export const showSnackbarError = data => ({
  type: SHOW_SNACK_ERROR,
  ...data,
});

const snackBarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_SNACK_SUCCESS:
      return {
        ...state,
        open: true,
        style: action.style,
        message: action.message,
        success: true,
        action: action.action,
        position: action.position || 'topRight',
      };
    case SHOW_SNACK_WARNING:
      return {
        ...state,
        open: true,
        message: action.message,
        warning: true,

        position: action.position,
      };
    case SHOW_SNACK_ERROR:
      return {
        ...state,
        open: true,
        message: action.message,
        error: true,
        position: action.position,
      };
    case CLOSE_SNACK_BAR:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default snackBarReducer;
