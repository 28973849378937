import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import WorklogTable from "./WorklogTable";
import BaseTable from "./BaseTable";
import ReviewTable from "./ReviewTable";
import {
  behavioursHeaders,
  empProject,
  empTaskHeaders,
} from "../helpers/constants";
// import { Button } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import DrawerWrapper from "./DrawerWrapper";
import useForm from "../hooks/useForm";
import AddProject from "../modules/projects/AddProject";
import AddTask from "../modules/tasks/AddTasks";
import SkillDetail from "./SkillDetails";
import { Button, Col, Row } from "react-bootstrap";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {children}
    </div>
  );
}

export default function SwipeTab({
  headers,
  onChangeTab,
  isEmployeeAndManager,
  isEmployee,
  onRowClick,
  openDrawer,
  tab,
  setTab,
  user = {},
  addProject,
  createProject,
  addTask,
  createTask,
  worklogs = [],
  reviews = [],
  tasks = [],
  projects = [],
  behaviours = [],
  isShiftHappens,
  openAddWorklogDrawer,
}) {
  const classes = useStyles();
  const [formType, setFormType] = useState("create");
  const [selected, setSelected] = useState(null);
  const { values, setValues, handleChange } = useForm();
  const [open, setOpenDrawer] = useState(false);
  const [openTask, setOpenTask] = useState(false);
  const theme = useTheme();
  const [description, setDescription] = useState("");

  function handleBehaviourClick(data) {
    setSelected(data);
  }

  function onSubmitTask(type) {
    setOpenDrawer(false);
    switch (type) {
      case "create":
        const data = {
          ...values,
          smeId: user.employers && user.employers[0].id,
          members: [user.id],
        };
        createTask(data, isEmployee);
        return setOpenTask(false);
      default:
        return;
    }
  }

  // function addTask() {
  //   setValues({});
  //   setFormType("create");
  //   setOpenTask(true);
  // }

  // Project
  function onSubmit(type) {
    setOpenDrawer(false);
    switch (type) {
      case "create":
        const data = {
          ...values,
          smeId: user.employers && user.employers[0].id,
          members: [user.id],
        };
        return createProject(data, isEmployee);

      default:
        return;
    }
  }

  // function addProject() {
  //   setValues({});
  //   setFormType("create");
  //   setOpenDrawer(true);
  // }

  const onDescriptionChange = (value) => {
    setDescription(value);

    // handleChange({ target : { name : "description", value } })
  };
  const onDescriptionBlur = (previousRange, source, editor) => {
    console.log("from on tasks desc", { editor, val: editor.getContents() });
    handleChange({
      target: { name: "description", value: editor.getContents() },
    });
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        className={"rounded-top"}
      >
        <Tabs
          value={tab}
          onChange={onChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Worklogs" />
          {/*<Tab label="Reviews" />*/}
          <Tab label={isShiftHappens ? "Helpers & Detractors" : "Tasks"} />
          <Tab label="Projects" />
          <Tab label={isShiftHappens ? "Values" : "Behaviours"} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={tab}
        onChangeIndex={onChangeTab}
      >
        <TabPanel value={tab} index={0} dir={theme.direction}>
          {worklogs.length > 0 || reviews.length > 0 ? (
            <WorklogTable
              isShiftHappens={isShiftHappens}
              isEmployee={isEmployee}
              isEmployeeAndManager={isEmployeeAndManager}
              openDrawer={openDrawer}
              onRowClick={onRowClick}
              data={[...(worklogs || []), ...(reviews || [])]}
              // headers={headers(isShiftHappens)}
            />
          ) : (
            <>
              <Row className={"m-0 text-center text-muted my-4"}>
                <Col>
                  {/*<h5 className="font-weight-600 my-3">You're yet to log any work</h5>*/}
                  <p>
                    Click{" "}
                    <a
                      className={"btn-link-primary"}
                      onClick={() => {
                        openAddWorklogDrawer();
                      }}
                    >
                      Add Worklog
                    </a>{" "}
                    to start
                  </p>
                  <div>
                    <p>
                      Not sure what to report on your worklogs?{" "}
                      <a
                        className={"btn-link-primary"}
                        onClick={() => {
                          setTab(3);
                        }}
                      >
                        {" "}
                        Review your targets
                      </a>{" "}
                    </p>
                    <p>
                      (Click on <strong>Tasks, Projects and Behaviours</strong>{" "}
                      on your Timeline )
                    </p>
                    <Row
                      className={
                        "flex-column-reverse flex-md-row m-0 justify-content-center"
                      }
                    >
                      <Button
                        className={"mt-3"}
                        variant="link-primary"
                        onClick={() => {
                          setTab(3);
                        }}
                      >
                        Review my target skills
                      </Button>
                      <Button
                        className={"mt-3"}
                        variant="secondary"
                        onClick={() => {
                          openAddWorklogDrawer();
                        }}
                      >
                        Add Worklog
                      </Button>
                    </Row>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </TabPanel>
        {/*<TabPanel value={tab} index={1} dir={theme.direction}>
          {reviews.length > 0 ? (
            <ReviewTable
              onRowClick={onRowClick}
              headers={headers}
              data={reviews}
            />
          ) : (
            <>
              <Row className={"m-0 text-center text-muted my-4"}>
                <Col>
                  <h5 className="font-weight-600 my-3">
                    You have no reviews here.
                  </h5>
                </Col>
              </Row>
            </>
          )}
        </TabPanel>*/}
        <TabPanel value={tab} index={1} dir={theme.direction}>
          {tasks.length > 0 ? (
            <>
              <BaseTable headers={empTaskHeaders} data={tasks} action={null} />
            </>
          ) : (
            <>
              <Row className={"m-0 text-center text-muted my-4"}>
                <Col>
                  <h5 className="font-weight-600 my-3">
                    You haven't added any task
                  </h5>
                  <p>
                    Click <strong>Add Task</strong> button to start
                  </p>
                  <div>
                    <Button
                      className={"mt-3"}
                      variant="secondary"
                      onClick={addTask}
                    >
                      Add Task
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}

          {/*<DrawerWrapper
            disableButton={!values.title || !values.description}
            onSubmit={onSubmitTask}
            formType={formType}
            title={formType === "create" ? "Add Task" : "Update Task"}
            open={openTask}
            onClose={() => setOpenTask(false)}
          >
            <AddTask
              employees={[]}
              handleChange={handleChange}
              values={values}
              description={description}
              onDescriptionChange={onDescriptionChange}
              onDescriptionBlur={onDescriptionBlur}
            />
          </DrawerWrapper>*/}
        </TabPanel>
        <TabPanel value={tab} index={2} dir={theme.direction}>
          {projects.length > 0 ? (
            <>
              <BaseTable headers={empProject} data={projects} action={null} />
            </>
          ) : (
            <>
              <Row className={"m-0 text-center text-muted my-4"}>
                <Col>
                  <h5 className="font-weight-600 my-3">
                    You haven't added any project
                  </h5>
                  <p>
                    Click <strong>Add Project</strong> button to start
                  </p>
                  <div>
                    <Button
                      className={"mt-3"}
                      variant="secondary"
                      onClick={addProject}
                    >
                      Add Project
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}

          {/*          <DrawerWrapper
            disableButton={
              !values.title ||
              !values.description ||
              !values.startDate ||
              !values.endDate
            }
            size="500px"
            formType={formType}
            onSubmit={onSubmit}
            title={formType === "create" ? "Add Project" : "Update Project"}
            open={open}
            onClose={() => setOpenDrawer(false)}
          >
            <AddProject
              employees={[]}
              handleChange={handleChange}
              values={values}
            />
          </DrawerWrapper>*/}
        </TabPanel>
        <TabPanel value={tab} index={3} dir={theme.direction}>
          {behaviours.length > 0 ? (
            <BaseTable
              handleRowClick={handleBehaviourClick}
              headers={behavioursHeaders}
              data={behaviours}
            />
          ) : (
            <>
              <Row className={"m-0 text-center text-muted my-4"}>
                <Col>
                  <h5 className="font-weight-600 my-3">No behaviours found</h5>
                </Col>
              </Row>
            </>
          )}

          <DrawerWrapper
            open={Boolean(selected)}
            onClose={() => setSelected(null)}
            noFooter
          >
            <SkillDetail showButton={false} {...selected} />
          </DrawerWrapper>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: "2%",
    width: "100%",
  },
  button: {
    float: "right",
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));
