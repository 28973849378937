export const links = (
  isEmployee,
  isOwner,
  isManager,
  isEmployeeAndManager,
  isShiftHappens
) => {
  return [
    ...(!isEmployee
      ? [
          {
            name: isEmployeeAndManager ? "Teams" : "People",
            link: isEmployeeAndManager ? "/teams" : "/",
          },
          // { name: "Skills", link: "/skills" },
        ]
      : []),
    ...(isEmployeeAndManager
      ? [
          { name: "Your worklogs", link: "worklogs" },
          { name: "Skills", link: "/skills" },
        ]
      : [{ name: isShiftHappens ? "Values" : "Skills", link: "/skills" }]),
  ];
};
