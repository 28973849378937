import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  makeStyles,
  createStyles,
  Card,
  CardContent,
  Typography,
  Chip,
  Tooltip,
  Button,
  withStyles,
  Badge,
} from '@material-ui/core';
import { deepOrange } from '@material-ui/core/colors';
import { connect } from 'react-redux';
import { getMasteredBehaviors, responseBadge } from '../modules/profile/duck/actions';

export function ReviewBadge({
  match,
  goBackToWorklog,
  getMasteredBehaviors,
  responseBadge,
  handleRequestBadge,
  employee,
  masteredBehaviors = [],
  setIsEmpty,
  setShow,
  open,
}) {
  const classes = useStyles();

  useEffect(() => {
    getMasteredBehaviors(match.params.employeeId);
  }, [getMasteredBehaviors, match.params.employeeId]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle>Behaviours mastered {`${employee.firstname} ${employee.lastname}`} </DialogTitle>
      <Card className={classes.card} variant="outlined">
        <CardContent>
          {!masteredBehaviors.length ? (
            <Typography>You have not practiced any behavior</Typography>
          ) : (
            masteredBehaviors.map((bh, i) => (
              <div key={i} style={{ display: 'block', paddingBottom: 10, paddingTop: 10 }}>
                <Badge badgeContent={bh.SkillUser && bh.SkillUser.count} color="secondary">
                  <LightTooltip
                    arrow
                    placement="right"
                    interactive
                    title={
                      <Button
                        disabled={bh.SkillUser && bh.SkillUser.count < 10}
                        onClick={() => responseBadge(match.params.employeeId, bh.id, 'revoke')}
                        className={classes.button}
                        variant="outlined"
                        size="small"
                      >
                        {'Rescind badge'}
                      </Button>
                    }
                  >
                    <Chip
                      onDelete={() => {}}
                      deleteIcon={
                        <span aria-labelledby="clap" style={{ fontSize: 20, color: 'orange' }} role="img">
                          &#x1f44f;
                        </span>
                      }
                      variant="outlined"
                      label={bh.title}
                    />
                  </LightTooltip>
                </Badge>
              </div>
            ))
          )}
          <div style={{ display: 'flex' }}>
            <Button onClick={goBackToWorklog} color="secondary">
              Go back to worklog
            </Button>
            <Button onClick={() => setShow(false)}>Go back</Button>
          </div>
        </CardContent>
      </Card>
    </Dialog>
  );
}

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    border: 'none',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles(theme =>
  createStyles({
    avatar: {
      paddingTop: 10,
      display: 'flex',
      justifyContent: 'center',
    },
    modal: {
      width: 400,
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
    button: {
      color: 'orange',
      border: 'none',
      fontWeight: 'bolder',
    },
    cardContainer: {
      marginTop: 10,
      display: 'grid',
      gridGap: 20,
      gridTemplateColumns: '1fr 1fr 1fr',
      '@media (max-width:600px)': {
        gridTemplateColumns: '1fr',
      },
    },
    card: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  })
);

const mapStateToProps = ({ profile: { masteredBehaviors = [] }, employees: { byId } }, { match }) => {
  return {
    masteredBehaviors,
    employee: byId[match.params.employeeId] || {},
  };
};
export default connect(mapStateToProps, { getMasteredBehaviors, responseBadge })(ReviewBadge);
