import { FETCH_NOTIFICATION_SUCCESS, UPDATE_NOTIFICATION_SUCCESS } from './actions';
import { combineReducers } from 'redux';

function byId(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_NOTIFICATION_SUCCESS:
      return payload.entities.notification || {};
    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        [payload.id]: payload
      };
    default:
      return state;
  }
}

function allIds(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_NOTIFICATION_SUCCESS:
      return payload.result || [];
    default:
      return state;
  }
}

export default combineReducers({ byId, allIds });
