import React, { useState, useEffect } from 'react';
import Rating from '@material-ui/lab/Rating';
import qs from 'query-string';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { TextArea } from '../../components/TextArea/TextArea.styles';
import { ReactComponent as LoadingIcon } from '../../images/load.svg';

export default function RatingComponent({ location, addBlanketReview, history }) {
  const classes = useStyles();
  const { rating, employee, manager, date, token } = qs.parse(location.search);

  useEffect(() => {
    setValues(qs.parse(location.search));
  }, [location.search]);

  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  function handleChange({ target }) {
    setValues({ ...values, [target.name]: target.value });
  }

  function onSubmit() {
    setLoading(true);
    addBlanketReview(token, history, setLoading, {
      rating: values.rating,
      feedback: values.feedback,
      improvement: values.improvement,
      userId: values.employeeId,
      smeId: values.employerId,
      date: values.date,
    });
  }

  return (
    <div className={classes.container}>
      <h1>Hey {manager && manager} </h1>
      <h1>
        How did {employee && employee.toUpperCase()} <br /> perform the week of {date}?
      </h1>
      <div className={classes.root}>
        <Rating
          disabled={loading}
          onChange={handleChange}
          className={classes.rate}
          name="rating"
          defaultValue={Number(rating && rating)}
          size="large"
        />
      </div>

      <div>
        <p className={classes.text}>Share your feedback by entering text</p>
        <div className={classes.textField}>
          <div>
            <p>What went well:</p>
            <TextArea
              width="400"
              disabled={loading}
              onChange={handleChange}
              fullWidth
              name="feedback"
              label="What went well:"
            />
          </div>

          <div>
            <p>What needs more work:</p>
            <TextArea
              style={{ marginLeft: 15 }}
              disabled={loading}
              width="400"
              onChange={handleChange}
              fullWidth
              name="improvement"
              label="What needs more work:"
            />
          </div>
        </div>
      </div>

      <Button
        disabled={!values.improvement || !values.feedback}
        onClick={onSubmit}
        color="secondary"
        variant="contained"
      >
        Submit {loading && <LoadingIcon />}
      </Button>
    </div>
  );
}

const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      marginTop: '10%',
      textAlign: 'center',
    },
    rate: {
      fontSize: 50,
    },
    text: {
      paddingTop: 15,
      font: 'Sofia Pro',
      fontSize: 16,
    },
    root: {
      paddingTop: 10,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      '& > * + *': {
        marginTop: theme.spacing(1),
      },
    },
    textField: {
      paddingTop: 15,
      display: 'flex',
      justifyContent: 'center',

      // textAlign: 'center',
      // paddingTop: 20,
      // paddingBottom: 20,
    },
  })
);
