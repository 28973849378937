import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { deepOrange } from "@material-ui/core/colors";

function ViewMode({ type = "grid", onSelectTab, superAdmin }) {
  return (
    <div>
      <ViewStyle>
        <div className="view-types">
          <span
            onClick={() => onSelectTab("table")}
            className={`view ${type === "table" && "active"}`}
          >
            Table view
          </span>
          <span
            onClick={() => onSelectTab("grid")}
            className={`view ${type === "grid" && "active"}`}
          >
            Grid view
          </span>
        </div>
      </ViewStyle>
    </div>
  );
}

const ViewStyle = styled.div`
  .view {
    padding: 5px 8px;
    margin-bottom: 1%;
    color: #7b8d8d;
    font-family: "Glacial";
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    margin-top: 48px;
    cursor: pointer;
  }
  .view-types {
    display: flex;
  }
  .active {
    color: #fff;
    border-radius: 3px;
    background-color: ${deepOrange[500]};
  }
`;

ViewMode.propTypes = {
  view: PropTypes.string,
  onSelectTab: PropTypes.func,
};

export default ViewMode;
