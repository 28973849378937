/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Header from "../../components/Header";
import { ExcelDateToJSDate } from "../../helpers/utils";
import AddEmployee from "./AddEmployee";
import useForm from "../../hooks/useForm";
import useExcel from "../../hooks/useExcel";
import Table from "mpharma-react-table";
import Upload from "../../components/UploadWorkersModal";
import { headerText } from "../../helpers/text";
import NavigateTo from "../../components/NavigateTo";
import DrawerWrapper from "../../components/DrawerWrapper";
import { Button, withStyles } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import Ellipsis from "../../components/Ellipsis";
import ContactInfo from "../../components/ContactInfo";
import Rating from "@material-ui/lab/Rating";
import ProfileReview from "../../components/ProfileReview";
import useFormNew from "../../hooks/userFormNew";
import { signupForm, teamForm } from "../../helpers/formUtils";
// import AddEmployee_ from "./AddEmployee_";
import Loading from "../../components/Loading";
import { useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import { Hint } from "../../components/Hint";
// import Select from "../../components/BaseSelect";
// import { days } from "../../helpers/constants";

function Employees ({
                      user,
                      match,
                      sme = {},
                      history,
                      getEmployees,
                      isManager,
                      loading,
                      createEmployee,
                      employees,
                      uploadEmployees,
                      updateEmployee,
                      deleteEmployee,
                      classes,
                      getSme,
                      isShiftHappens,
                    }) {
  const [ openDrawer, setopenDrawer ] = useState(false);
  const [ selected, setSelected ] = useState(null);
  const [ open, setOpen ] = useState(false);
  const [ formType, setFormType ] = useState("create");

  const { handleFileChange, data : records, setData } = useExcel();
  const [ openReview, setOpenReview ] = useState(false);
  const { handleChange, values, setValues } = useForm({});
  const cachedEmployees = useSelector((state) =>
    Object.values(state.employees.byId)
  );

  const {
    values : firstValues,
    setValues : setValuesFirst,
    renderFormInputs,
    isFormValid,
  } = useFormNew(signupForm);
  const {
    values : secondValues,
    setValues : setValuesSecond,
    renderFormInputs : renderSecond,
  } = useFormNew(teamForm);

  const fileInput = useRef();

  const employeeHeaders = [
    {
      title : isShiftHappens ? "Coach" : "Manager",
      dataIndex : "manager",

      render : (item = {}) =>
        item.manager && `${item.manager.firstname} ${item.manager.lastname}`,
    },
    {
      title : isShiftHappens ? "Coachees" : "Worker name",
      dataIndex : "fullname",
      render : (item) =>
        item && (
          <p style={{ color : "blue" }} onClick={() => onClickName(item)}>
            {item.firstname} {item.lastname}
          </p>
        ),
    },
    {
      title : "Level",
      dataIndex : "level",
      render : (item) => item.level && item.level.level,
    },
    { title : "No. of Worklogs", dataIndex : "totalWorklogs", align : "right" },
    { title : "No. of Reviews", dataIndex : "totalRatings", align : "right" },
    {
      title : "Avg. Star Rating",
      dataIndex : "averageRating",
      render : (item) =>
        item.averageRating && (
          <Rating name="rating" color={"#ff5722"} variant={"secondary"} className={""} readOnly defaultValue={item.averageRating}/>
        ),
    },
    {
      title : "No. of Badges",
      dataIndex : "totalBadges",
      align : "right",
      render : (item) => (item && item.totalBadges ? item.totalBadges : "0"),
    },
    {
      title : "Date started",
      dataIndex : "date",
      render : (item) =>
        item && item.startDate && new Date(item.startDate).toDateString(),
    },
    {
      title : "Last action (date)",
      align : "right",
      dataIndex : "last",
      render : (item) =>
        item && item.lastActive && new Date(item.lastActive).toDateString(),
    },
  ];

  function openUpload () {
    setData(null);
    return fileInput.current.click();
  }

  function openUploadDiaglog () {
    setOpen(true);
  }

  function closeDiaglog () {
    setOpen(false);
    setData(null);
  }

  function filterEmployees (employees = []) {
    return employees.filter((emp) => emp.id !== user.id);
  }

  useEffect(() => {
    const foundUser = cachedEmployees.find(
      (emp) => emp?.email === values?.email
    );

    if (foundUser) {
      setValues({ ...foundUser });
    }
  }, [ values.email ]);

  useEffect(() => {
    getEmployees(match.params.id);
    getSme(match.params.id);
  }, [ getEmployees, getSme, match.params.id ]);

  async function onSubmit (type) {
    setOpen(false);
    switch (type) {
      case "create":
        const data = {
          ...values,
          employerId : match.params.id,
        };
        await createEmployee(data);
        setValuesFirst({});
        setValuesSecond({});
        return await setopenDrawer(false);
      case "upload":
        const uploadRecords = records.map((rec) => {
          const record = {
            firstname : rec["Worker first name (required)"] || null,
            lastname : rec["Worker last name (required)"] || null,
            email : rec["Email (required)"] || null,
            password : rec["Password (required)"] || null,
            jobTitle : rec["Job title"] || null,
            contact : (rec.contact && rec.contact.toString()) || "",
            level : rec["Level"] || null,
            employerId : match.params.id || null,
            startDate : ExcelDateToJSDate(rec.startDate) || new Date(),
          };
          return record;
        });
        await uploadEmployees(uploadRecords);
        return setData(null);

      case "edit":
        setopenDrawer(false);
        const { firstname, lastname, password, contact, startDate } =
          firstValues;
        const { jobTitle, level } = secondValues;
        return updateEmployee(firstValues.id, {
          firstname,
          lastname,
          password,
          contact,
          jobTitle,
          level,
          employerId : firstValues.sme.id,
          startDate,
        });
      default:
        return;
    }
  }

  function onClickName (data) {
    return history.push(
      `/teams/${data.EmployerUser.employerId}/employees/${data.id}`
    );
  }

  const moreActions = [
    { name : "Contact", action : "contact" },
    { name : "Review", action : "review" },
    { name : "Timeline", action : "timeline" },
    { name : "Profile", action : "profile" },
    { name : "Edit", action : "edit" },
    { name : "Delete", action : "delete", className : "danger" },
  ];

  function onSelect (action, data) {
    switch (action) {
      case "contact":
        return setSelected(data);
      case "review":
        setOpenReview(true);
        return setSelected(data);
      case "timeline":
        return history.push(`/teams/${match.params.id}/employees/${data.id}`);
      case "profile":
        return history.push(`/smes/${data.sme.id}/profile/${data.id}`);
      case "edit":
        setopenDrawer(true);
        setFormType("edit");
        const level = data.level && data.level.level;
        setValuesSecond({ ...data, level });
        return setValuesFirst(data);
      case "delete":
        let confirm = window.confirm(
          "Are you sure you want to delete this employee?"
        );
        return confirm ? deleteEmployee(data.id) : null;
      default:
        return;
    }
  }

  function addEmployee () {
    setopenDrawer(true);
  }

  if (loading) {
    return <Loading/>;
  }

  return (
    <div>
      <Header
        header={`${sme.name}`}
        // subHeader={headerText["workers"].subText}
        headerHint={<Hint messages={headerText["workers"].hint} direction={"right"} minWidth={320}/>}
        navigate={
          <div className={classes.wrapper}>
            <NavigateTo name="Go back to People" link={`/`}/>
            <NavigateTo
              name={
                isShiftHappens
                  ? "Next: Assign Values"
                  : "Next: Assign Behaviours"
              }
              link={`/teams/${match.params.id}/behaviours`}
              left={"20%"}
            />
          </div>
        }
        action={
          <Row>
            <Button
              variant="contained"
              onClick={addEmployee}
              style={{ marginRight : 10 }}>
              {isShiftHappens ? "Add a Coachee" : "Add a worker"}
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor : deepOrange[500], color : "white" }}
              onClick={openUploadDiaglog}
            >
              {isShiftHappens ? "Upload coachees" : "Upload workers"}
            </Button>
          </Row>
        }
      />

      <input
        onChange={handleFileChange}
        ref={fileInput}
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        id="my_file"
        onClick={openUpload}
        style={{ display : "none" }}
      />

      <div style={{ paddingTop : "2%" }}>
        <Table
          headers={employeeHeaders}
          rowsPerPageOptions={[ 25, 50 ]}
          data={
            loading ? [] : isManager ? filterEmployees(employees) : employees
          }
          tableStyle={{
            width : "100%",
            backgroundColor : "#fff",
            marginTop : 20,
          }}
          emptyMessageStyle={{
            left : "45%",
            position : "absolute",
            fontSize : 25,
            width : "80%",
            paddingTop : 20,
          }}
          emptyMessage={
            !loading && !employees.length ? "No employee(s) available" : ""
          }
          tableBodyStyle={{ maxHeight : "50vh" }}
          hover
          renderIcon={(data) => (
            <Ellipsis
              as="td"
              onSelect={onSelect}
              data={data}
              moreActions={moreActions}
            />
          )}
        />
      </div>

      <DrawerWrapper
        loading={loading}
        // noFooter

        formType={formType}
        onSubmit={onSubmit}
        title={formType === "create" ? "Add a worker" : "Update worker"}
        open={openDrawer}
        onClose={() => setopenDrawer(false)}
      >
        {/*<AddEmployee_ secondForm={renderSecond()}>{renderFormInputs()}</AddEmployee_> */}
        <AddEmployee
          handleChange={handleChange}
          values={values}
          formType="create"
        />
      </DrawerWrapper>
      <DrawerWrapper
        open={Boolean(selected)}
        onClose={() => setSelected(null)}
        noFooter
      >
        <ContactInfo data={selected}/>
      </DrawerWrapper>
      <DrawerWrapper
        open={openReview}
        onClose={() => {
          setOpenReview(false);
          setSelected(null);
        }}
        noFooter
      >
        <ProfileReview data={selected}/>
      </DrawerWrapper>
      <Upload
        onSubmit={onSubmit}
        onClick={openUpload}
        open={open}
        file={records}
        closeDiaglog={closeDiaglog}
      />
    </div>
  );
}

const styles = (theme) => ({
  wrapper : {
    display : "flex",
    width : "30vw",
    "@media (max-width: 600px)" : {
      display : "block",
    },
    left : {
      marginLeft : 100,
    },
    button : {
      color : theme.palette.getContrastText(deepOrange[500]),
      backgroundColor : deepOrange[500],
    },
  },
});
Employees.propTypes = {
  user : PropTypes.object,
  match : PropTypes.object,
  history : PropTypes.object,
  getEmployees : PropTypes.func,
  isManager : PropTypes.bool,
  loading : PropTypes.bool,
  createEmployee : PropTypes.func,
  employees : PropTypes.array,
  uploadEmployees : PropTypes.func,
  updateEmployee : PropTypes.func,
  deleteEmployee : PropTypes.func,
};

export default withStyles(styles)(Employees);
