import React from "react";
import { MoreVert } from "@material-ui/icons";

let MenuIcon = function () {
  return <span style={{ padding : "3px", backgroundColor : "rgba(153, 153, 153, .3)" }}
               className={"rounded-circle"}><MoreVert style={{ fontSize : 16, verticalAlign : "text-top" }}/>
  </span>
}

export const headerText = {
  team : {
    header : "Your Organization's People",
    subText : ` .  `,
    hint : [
      "Your people are organized into teams (Each team has a manager).",
      <span>Click on a team’s menu <MenuIcon/> to view, edit, or assign work."</span>,
      "Click through each menu option, filling each team with employees and assigning behaviours, tasks, and project."
    ]
  },
  skills : {
    header : '’s Skill Library',
    subText : <span>Your organization needs workers to practice and master skills on the job. Some skills are about HOW workers behave on the job (behaviours), and some skills are about WHAT they do (job tasks). Click the menu <MenuIcon/> to edit the skills below or click the “Add skills” button to add your own.</span>,
    hint : [
      <span>"Some skills are about <strong className={"font-weight-600"}>HOW</strong> workers behave on the job (behaviours), and some skills are about <span
        className={"font-weight-600"}>WHAT</span> they do (job tasks)."</span>,
      <span>  Click the menu <MenuIcon/> to edit the skills below or click the “Add skills” button to add your own.</span>
    ]
  },
  workers : {
    header : 'Your Team’s Workers',
    subText :
      '  (::) ',
    hint : [
      "Use the “Add a Worker” or “Upload Workers” buttons to enter information on each worker in this team.",
      <span>Use the menu <MenuIcon/> to edit a worker’s information or see their Timeline (inc. all assigned behaviours, tasks, and projects).</span>,
    ]
  },
  behaviour : {
    header : "Your Team's Work Behaviours",
    subText :
      'Work behaviours are skills determining HOW workers do their work. What practices/habits do you want to see across all workers? Click the menu (::) to edit the default behaviours and categories below or click the “Add Behaviour” button to add your own. ',
  },
  project : {
    header : "Your Team's Projects",
    subText :
      'Projects are time-bound assignments for individual or groups of workers to deliver. Each project has a due date. Click the “Add Project” button to start assigning projects and click the menu (::) to edit projects you’ve assigned.',
  },
  task : {
    header : 'Your Team’s Job Tasks',
    subText :
      'Job tasks are skills determining WHAT workers actually do on the job. What are the repeatable tasks in their job description? Click “Add a Task” to add the tasks each worker should be doing on the job, and click the menu (::) to edit.',
  },
  multipleEmployee : {
    subText :
      'Here are all the teams you’re part of. As a worker on a given team, you can see your assigned tasks, projects and behaviours, and log work for your team’s manager to review. Click on each one to with tasks, projects and worklogs',
  },
  metrics : {
    subText :
      'These are your accumulated metrics on the number of teams you belong to, these metrics are calculated from your daily worklogs submitted',
  },
  worklogs : {
    subText : (
      <>
        <p className={"mb-0 line-height-12"}>Use your worklog to reflect on your progress.</p>
        <p className={"mb-0 line-height-12"}><span><span className={"font-weight-600"}>WHAT</span> did you do on the job?</span>
          <span><span className={"font-weight-600"}>HOW</span> did you do it?</span></p>
        <p className={"mb-0 line-height-12"}>and learn more about your assigned tasks, projects and behaviours clicking
          on those tabs </p>
      </>
    ),
    hint : [
      <span className={"mb-0 line-height-12"}><span className={"font-weight-600"}>WHAT</span> did you do on the job?</span>,
      <span className={"mb-0 line-height-12"}><span className={"font-weight-600"}>HOW</span> did you do it?</span>,
      <span className={"mb-0 line-height-12"}>Learn more about your assigned tasks, projects and behaviours clicking on those tabs</span>
    ]
  },
};
