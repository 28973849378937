import React from 'react';
import Graph from '../../components/Graph';
import Header from '../../components/Header';
import { headerText } from '../../helpers/text';

export const data1 = [
  {
    date: '2/2/2020',
    worklogs: 3,
  },
  {
    date: '4/2/2020',
    worklogs: 4,
  },
  {
    date: '8/2/2020',
    worklogs: 5,
  },
  {
    date: '15/2/2020',
    worklogs: 6,
  },
  {
    date: '18/2/2020',
    worklogs: 8,
  },
  {
    date: '20/20/2020',
    worklogs: 10,
  },
  {
    date: '10/3/2020',
    worklogs: 12,
  },
  {
    date: '15/3/2020',
    worklogs: 15,
  },
];

export const data2 = [
  {
    date: '2/2/2020',
    worklogs: 1,
  },
  {
    date: '4/2/2020',
    worklogs: 2,
  },
  {
    date: '8/2/2020',
    worklogs: 3,
  },
  {
    date: '15/2/2020',
    worklogs: 5,
  },
  {
    date: '18/2/2020',
    worklogs: 5,
  },
  {
    date: '20/20/2020',
    worklogs: 7,
  },
  {
    date: '10/3/2020',
    worklogs: 10,
  },
  {
    date: '15/3/2020',
    worklogs: 13,
  },
];

function EmployeeMetrics() {
  return (
    <div className="container-fluid">
      <Header header="Terrence Performance metrics" subHeader={headerText.metrics['subText']} />
      <div style={{ paddingTop: '5%', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <Graph data={data1} />

        <Graph data={data2} />
      </div>
    </div>
  );
}

export default EmployeeMetrics;
