import React from 'react';
import PropTypes from 'prop-types';
import SmeCard from './Card';
import styled from 'styled-components';
import Ellipsis from './Ellipsis';

function RenderGrid({ data = [], onClick = () => null, onSelect, moreActions }) {
  return (
    <Container>
      {data.map((record, i) => (
        <SmeCard
          children={
            <Ellipsis
              as="div"
              // style={{ position: 'absolute', right: -100 }}
              data={record}
              onSelect={onSelect}
              moreActions={moreActions}
            />
          }
          onClick={onClick.bind(this)}
          key={i}
          record={record}
        ></SmeCard>
      ))}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: grid;
  grid-gap: 20px;

  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    grid-template-columns: 1fr;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

RenderGrid.propTypes = {
  data: PropTypes.array,
  onSelect: PropTypes.func,
  moreActions: PropTypes.array,
};

export default RenderGrid;
