import React from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import TextInput from "../../components/TextInput";
import Select from "../../components/BaseSelect";
import { industries, companyStrength } from "../../helpers/selectFields";
import { images } from "../../helpers/utils";
import { ReactComponent as LoadingIcon } from "../../images/load.svg";

export default function SignUp({
  onSubmit,
  hidePassword,
  handleSelect,
  toggleHidePassword,
  onChange,
  values,
  loading,
  error,
}) {
  const classes = useStyles();

  return (
    <div className="sp-1">
      <CssBaseline />
      <div className={classes.paper}>
        <img
          style={{ margin: "0 auto", paddingBottom: "2%" }}
          width="150"
          src={images.logo}
          alt="logo"
        />
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        {error && <Alert severity="error">{error.errors[0]}</Alert>}
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextInput
                onChange={onChange}
                required
                fullWidth
                label="Organization name"
                name="org"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Select
                onChange={handleSelect}
                name="industry"
                isMulti={false}
                label="Industry"
                options={industries}
                placeholder="Industry"
                labelClassName={"text-left"}
                wrapperClassName={"text-left"}
                className={"text-left"}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Select
                isMulti={false}
                name="staffStrength"
                onChange={handleSelect}
                label="Company Strength"
                options={companyStrength}
                placeholder="Company Strength"
                labelClassName={"text-left"}
                wrapperClassName={"text-left"}
                className={"text-left"}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput
                onChange={onChange}
                name="firstname"
                required
                fullWidth
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                onChange={onChange}
                required
                fullWidth
                label="Last Name"
                name="lastname"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                onChange={onChange}
                required
                type="email"
                fullWidth
                label="Email address"
                name="email"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                hidden={hidePassword}
                onChange={onChange}
                toggleVisibility={toggleHidePassword}
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
              />
            </Grid>
          </Grid>
          <Button
            disabled={
              !values.firstname ||
              !values.lastname ||
              !values.email ||
              !values.staffStrength ||
              !values.industry ||
              !values.password ||
              values.password.length < 6 || // Refactor this
              loading
            }
            type="submit"
            onClick={onSubmit}
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Sign Up {loading && <LoadingIcon />}
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: theme.spacing(5),
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    width: "25vw",
    height: "100vh",
    backgroundColor: "#ffffff",
    padding: "0 20px 20px 20px",
    boxShadow: "0 0 20px 2px rgba(0, 0, 0, 0.4)",
    textAlign: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    marginTop: 10,
    margin: theme.spacing(0, 0, 2),
  },
}));
