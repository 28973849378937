import React from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

function MoreAction({ onSelect, moreActions = [], record }) {
  return (
    <Dropdown.Menu alignRight>
      {moreActions.map((action, i) => (
        <Dropdown.Item key={i} className={action.className} onClick={() => onSelect(action.action, record)}>
          {(action.render && action.render(record)) || action.name}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  );
}

MoreAction.propTypes = {
  onSelect: PropTypes.func,
  moreActions: PropTypes.array,
  record: PropTypes.object
};

export default MoreAction;
