import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ErrorIcon } from '../images/error-sign.svg';
import { Button } from '@material-ui/core';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: {},
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  onRefreshPage = () => {
    if (this.props.refreshPage) {
      return this.props.refreshPage();
    }
    this.setState({ hasError: false });
  };

  render() {
    const { hasError } = this.state;
    const { message, children, error: showError } = this.props;

    if (hasError || showError) {
      return (
        <Container>
          <div>
            <ErrorIcon />
            <ErrorState>Error</ErrorState>
            <ErrorText> {message ? message : 'Sorry something bad happened, Please refresh'} </ErrorText>
            <Button style={{ marginTop: 12 }} onClick={this.onRefreshPage} secondary>
              Try again
            </Button>
          </div>
        </Container>
      );
    }

    return children;
  }
}

ErrorBoundary.defaultProps = {
  action: null,
};
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
`;

const ErrorState = styled.p`
  color: rgba(0, 0, 0, 0.89);
  font-family: 'Sofia Pro';
  font-size: 40px;
  line-height: 60px;
`;

const ErrorText = styled.p`
  color: #959595;
  font-family: 'Sofia Pro';
  font-size: 12px;
  line-height: 18px;
`;

export default ErrorBoundary;
