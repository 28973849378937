import { useState, useEffect } from "react";

const useForm = (initialValue = {}, validate) => {
  const [values, setValues] = useState(initialValue);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    validate ? setErrors(validate(values)) : setErrors(values);
  }, [validate, values]);

  const reset = () => {
    setValues(initialValue);
  };

  const handleChange = (event) => {
    if (event && event.persist) {
      event.persist();
    }

    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value || event.target.checked,
    }));
  };

  return {
    handleChange,
    values,
    errors,
    reset,
    setValues,
  };
};

export default useForm;
