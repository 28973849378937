import { connect } from 'react-redux';
import teamComponent from './employeeTeams';
import { fetchWorklogsAnalysis } from '../worklogs/duck/actions';

const mapStateToProps = ({ worklogs: { analysis } }) => {
  return {
    analysis,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWorklogsAnalysis: (employeeId, smeId) => dispatch(fetchWorklogsAnalysis(employeeId, smeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(teamComponent);
