import React, { useEffect } from 'react';
import {
  Avatar,
  Badge,
  Button,
  Card,
  CardContent,
  Chip,
  createStyles,
  makeStyles,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { deepOrange } from '@material-ui/core/colors';

function ProfileComponent (props) {
  let {
    masteredBehaviors = [], practiceBehaviors = [], getMasteredBehaviors,
    getPracticedBehaviors, requestBadge, user = {}, isEmployee, match,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    getPracticedBehaviors(match.params.id);
    getMasteredBehaviors(match.params.id);
  }, [ getMasteredBehaviors, getPracticedBehaviors, match.params.id, user.id ]);

  function handleRequestBadge (id) {
    requestBadge(user.id, id, user.managers[0].id);
  }

  return (
    <div>
      <div className={classes.avatar}>
        <Avatar className={classes.orange}>{`${user.firstname[0]} ${user.lastname[0]}`}</Avatar>
      </div>

      <div className={classes.cardContainer}>
        <Card className={classes.card} variant="outlined">
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Behavior's Practiced
            </Typography>
            {!practiceBehaviors.length ? (
              <Typography>You have not practiced any behavior</Typography>
            ) : (
              practiceBehaviors.map(bh => (
                <div key={bh.id} style={{ display : 'block', paddingBottom : 10, paddingTop : 10 }}>
                  <Badge badgeContent={bh.SkillUser && bh.SkillUser.count} color="secondary">
                    <LightTooltip
                      arrow
                      placement="right"
                      interactive
                      title={
                        <Button
                          disabled={bh.SkillUser && bh.SkillUser.count < 10}
                          onClick={() => handleRequestBadge(bh.id)}
                          className={classes.button}
                          variant="outlined"
                          size="small"
                        >
                          {bh.SkillUser.count < 10 ? 'Practice behavior 10x' : 'Request a Badge'}
                        </Button>
                      }
                    >
                      <Chip variant="outlined" label={bh.title}/>
                    </LightTooltip>
                  </Badge>
                </div>
              ))
            )}
          </CardContent>
        </Card>
        <Card className={classes.card} variant="outlined">
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Behaviors Mastered
            </Typography>
            {!masteredBehaviors.length ? (
              <Typography>You have not practiced any behavior</Typography>
            ) : (
              masteredBehaviors.map(bh => (
                <div key={bh.id} style={{ display : 'block', paddingBottom : 10, paddingTop : 10 }}>
                  <Chip
                    label={bh.title}
                    onDelete={() => {}}
                    deleteIcon={
                      <span aria-labelledby="clap" style={{ fontSize : 20, color : 'orange' }} role="img">
                        &#x1f44f;
                      </span>
                    }
                    variant="outlined"
                  />
                </div>
              ))
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

const LightTooltip = withStyles(theme => ({
  tooltip : {
    backgroundColor : theme.palette.common.white,
    color : 'rgba(0, 0, 0, 0.87)',
    border : 'none',
    boxShadow : theme.shadows[1],
    fontSize : 11,
  },
}))(Tooltip);

const useStyles = makeStyles(theme =>
  createStyles({
    avatar : {
      paddingTop : 10,
      display : 'flex',
      justifyContent : 'center',
    },
    orange : {
      color : theme.palette.getContrastText(deepOrange[500]),
      backgroundColor : deepOrange[500],
      width : theme.spacing(9),
      height : theme.spacing(9),
    },
    button : {
      color : 'orange',
      border : 'none',
      fontWeight : 'bolder',
    },
    cardContainer : {
      marginTop : 10,
      display : 'grid',
      gridGap : 20,
      gridTemplateColumns : '1fr 1fr 1fr',
      '@media (max-width:600px)' : {
        gridTemplateColumns : '1fr',
      },
    },
    card : {
      minWidth : 275,
    },
    bullet : {
      display : 'inline-block',
      margin : '0 2px',
      transform : 'scale(0.8)',
    },
    title : {
      fontSize : 14,
    },
    pos : {
      marginBottom : 12,
    },
  })
);

export default ProfileComponent;
