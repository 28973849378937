import { combineReducers } from 'redux';
import {
  CREATE_PROJECT_SUCCESS,
  FETCH_PROJECT_SUCCESS,
  UPLOAD_PROJECT_SUCCESS,
  CREATE_PROJECT,
  CREATE_PROJECT_FAILURE,
  FETCH_PROJECT_FAILURE,
  DELETE_PROJECT_SUCCESS,
  UPDATE_PROJECT_SUCCESS
} from './types';
import { CREATE_BEHAVIOUR_SUCCESS } from '../../behaviors/duck/types';

function byId(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_PROJECT_SUCCESS:
      return { ...state, [payload.id]: payload };
    case FETCH_PROJECT_SUCCESS:
      return payload.entities.projects || {};
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        [payload.id]: payload
      };
    case UPLOAD_PROJECT_SUCCESS:
      return {
        ...state,
        ...payload.entities.employees
      };
    default:
      return state;
  }
}

function allIds(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_PROJECT_SUCCESS:
      return [payload.id, ...state];
    case FETCH_PROJECT_SUCCESS:
      return payload.result.reverse() || [];
    case DELETE_PROJECT_SUCCESS:
      return state.filter(id => id !== payload);
    case UPLOAD_PROJECT_SUCCESS:
      return state.concat(payload.result);
    default:
      return state;
  }
}

function loading(state = false, action) {
  const { type } = action;
  switch (type) {
    case CREATE_PROJECT:
      return true;
    case CREATE_PROJECT_FAILURE:
    case CREATE_BEHAVIOUR_SUCCESS:
      return false;
    default:
      return state;
  }
}

function error(state = null, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_PROJECT_FAILURE:
    case FETCH_PROJECT_FAILURE:
      return payload;
    default:
      return state;
  }
}

export default combineReducers({
  loading,
  byId,
  allIds,
  error
});
