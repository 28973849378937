import { connect } from "react-redux";
import EmployeeComponent from ".";
import {
  getBehaviours,
  createBehaviour,
  deleteBehaviour,
  updateBehaviour,
  uploadBehaviours,
} from "./duck/actions";
import { assignSkill, createSkill, getSkills } from "../skills/duck/actions";
import { getSme } from "../teams/duck/actions";
import { getEmployees } from "../employees/duck/actions";

const mapStateToProps = ({
  behaviours: { allIds, byId, loading },
  employees,
  skills,
  smes: { byId: smeById },
}) => {
  return {
    behaviours: allIds.map((id) => byId[id]),
    loading,
    skills: skills.allIds.map((id) => skills.byId[id]),
    sme: Object.values(smeById)[0],
    employees: Object.values(employees.byId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBehaviours: (data) => dispatch(getBehaviours(data)),
    createSkill: (data) => dispatch(createSkill(data)),
    uploadBehaviours: (data) => dispatch(uploadBehaviours(data)),
    updateBehaviour: (data) => dispatch(updateBehaviour(data)),
    createBehaviour: (data, setLoading, setOpen) =>
      dispatch(createBehaviour(data, setLoading, setOpen)),
    getSkills: (id) => dispatch(getSkills(id)),
    deleteBehaviour: (id) => dispatch(deleteBehaviour(id)),
    getSme: (smeId) => dispatch(getSme(smeId)),
    getEmployees: (id) => dispatch(getEmployees(id)),
    assignSkill: (data, setLoading, closeDrawer) =>
      dispatch(assignSkill(data, setLoading, closeDrawer)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeComponent);
