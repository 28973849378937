import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import ReduxThunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";
import storage from "redux-persist/lib/storage";
import { createLogger } from "redux-logger";
import auth from "../routes/ducks/reducer";
import smes from "../modules/teams/duck/reducer";
import employees from "../modules/employees/duck/reducer";
import behaviours from "../modules/behaviors/duck/reducer";
import tasks from "../modules/tasks/duck/reducer";
import projects from "../modules/projects/duck/reducer";
import worklogs from "../modules/worklogs/duck/reducer";
import reviews from "../modules/worklogs/duck/review.reducer";
import skills from "../modules/skills/duck/reducer";
import snackbar from "../modules/Snackbar/reducer";
import notification from "../modules/notifications/duck/reducers";
import profile from "../modules/profile/duck/reducer";

const rootReducer = combineReducers({
  auth,
  smes,
  employees,
  reviews,
  snackbar,
  behaviours,
  projects,
  tasks,
  worklogs,
  skills,
  notification,
  profile,
});

const _DEV_ = process.env.NODE_ENV === "development";
const middlewares = [ReduxThunk];
const logger = createLogger({ collapsed: true });
if (_DEV_) middlewares.push(logger);

const persistConfig = {
  key: "skillnav",
  whitelist: [
    "auth",
    "smes",
    "employees",
    "reviews",
    "snackbar",
    "behaviours",
    "projects",
    "tasks",
    "worklogs",
    // "skills",
    "notification",
    "profile",
  ],
  storage,
  stateReconciler: autoMergeLevel1,
  timeout: null,
  debug: _DEV_,
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
);

export const persistor = persistStore(store);

export default store;
