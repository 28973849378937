import { combineReducers } from "redux";
import { UPLOAD_BEHAVIOUR_FAILURE } from "../../behaviors/duck/types";
import {
  CREATE_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEES_SUCCESS,
  CREATE_EMPLOYEE,
  FETCH_EMPLOYEES,
  CREATE_EMPLOYEE_FAILURE,
  FETCH_EMPLOYEES_FAILURE,
  UPLOAD_EMPLOYEES_SUCCESS,
  EDIT_EMPLOYEE_FAILURE,
  EDIT_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILURE,
  UPLOAD_EMPLOYEE_FAILURE,
} from "./types";

function byId(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_EMPLOYEE_SUCCESS:
      return { ...state, [payload.id]: payload };
    case FETCH_EMPLOYEES_SUCCESS:
      return payload.entities.employees || {};
    case EDIT_EMPLOYEE_SUCCESS:
      return {
        ...state,
        [payload.id]: payload,
      };

    case UPLOAD_EMPLOYEES_SUCCESS:
      return {
        ...state,
        ...payload.entities.employees,
      };
    default:
      return state;
  }
}

function allIds(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_EMPLOYEE_SUCCESS:
      return [payload.id, ...state];
    case FETCH_EMPLOYEES_SUCCESS:
      return payload.result || [];
    case DELETE_EMPLOYEE_SUCCESS:
      return state.filter((id) => id !== payload);
    case UPLOAD_EMPLOYEES_SUCCESS:
      return state.concat(payload.result);
    case FETCH_EMPLOYEES_FAILURE:
      return [];
    default:
      return state;
  }
}

function loading(state = false, action) {
  const { type } = action;
  switch (type) {
    case CREATE_EMPLOYEE:
    case FETCH_EMPLOYEES:
      return true;
    case CREATE_EMPLOYEE_SUCCESS:
    case FETCH_EMPLOYEES_SUCCESS:
    case CREATE_EMPLOYEE_FAILURE:
    case FETCH_EMPLOYEES_FAILURE:
    case UPLOAD_EMPLOYEES_SUCCESS:
    case UPLOAD_EMPLOYEE_FAILURE:
      return false;
    default:
      return state;
  }
}

function error(state = null, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_EMPLOYEE_FAILURE:
    case FETCH_EMPLOYEES_FAILURE:
    case EDIT_EMPLOYEE_FAILURE:
    case DELETE_EMPLOYEE_FAILURE:
      return payload;
    default:
      return state;
  }
}

export default combineReducers({
  loading,
  byId,
  allIds,
  error,
});
