import axios from 'axios';
import { API_ROOT } from '../../../helpers/constants';
import { showSnackbarSuccess, showSnackbarError } from '../../Snackbar/reducer';

export const addBlanketReview = (token, history, setLoading, data) => async dispatch => {
  axios.defaults.headers.common['Authorization'] = token;
  try {
    await axios.post(`${API_ROOT}/reviews/blanket`, data);
    dispatch(showSnackbarSuccess({ message: 'Thanks for reviewing this worklog' }));
    history.push('/');
  } catch (e) {
    setLoading(false);
    dispatch(showSnackbarError({ message: 'Sorry something went wrong, Please try again' }));
  }
};
