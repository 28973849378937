import { combineReducers } from 'redux';
import { FETCH_REVIEWS_SUCCESS, FETCH_REVIEWS_FAILURE } from './types';

function byId(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_REVIEWS_SUCCESS:
      return payload.entities.review || {};
    default:
      return state;
  }
}

function allIds(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_REVIEWS_SUCCESS:
      return payload.result || [];
    default:
      return state;
  }
}

function error(state = null, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_REVIEWS_FAILURE:
      return payload;
    default:
      return state;
  }
}

export default combineReducers({
  byId,
  allIds,
  error
});
