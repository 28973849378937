import { combineReducers } from "redux";
import {
  CREATE_TASK_SUCCESS,
  FETCH_TASK_SUCCESS,
  UPLOAD_TASK_SUCCESS,
  CREATE_TASK,
  CREATE_TASK_FAILURE,
  FETCH_TASK_FAILURE,
  EDIT_TASK_SUCCESS,
  DELETE_TASK_SUCCESS,
} from "./types";

function byId(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_TASK_SUCCESS:
      return { ...state, [payload.id]: payload };
    case FETCH_TASK_SUCCESS:
      return payload.entities.task || {};
    case EDIT_TASK_SUCCESS:
      return {
        ...state,
        [payload.id]: payload,
      };
    case UPLOAD_TASK_SUCCESS:
      return {
        ...state,
        ...payload.entities.employees,
      };
    default:
      return state;
  }
}

function allIds(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_TASK_SUCCESS:
      return [payload.id, ...state];
    case FETCH_TASK_SUCCESS:
      return payload.result || [];

    case DELETE_TASK_SUCCESS:
      return state.filter((id) => id !== payload);
    case UPLOAD_TASK_SUCCESS:
      return state.concat(payload.result);
    default:
      return state;
  }
}

function loading(state = false, action) {
  const { type } = action;
  switch (type) {
    case CREATE_TASK:
      return true;
    case FETCH_TASK_FAILURE:
    case FETCH_TASK_SUCCESS:
    case UPLOAD_TASK_SUCCESS:
    case CREATE_TASK_SUCCESS:
    case CREATE_TASK_FAILURE:
      return false;
    default:
      return state;
  }
}

function error(state = null, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_TASK_FAILURE:
    case FETCH_TASK_FAILURE:
      return payload;
    default:
      return state;
  }
}

export default combineReducers({
  loading,
  byId,
  allIds,
  error,
});
