import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core";
import { connect, useDispatch, useSelector } from "react-redux";
import ProfileTab from "../../components/ProfileTabs";
import Rating from "@material-ui/lab/Rating";
import { deepOrange } from "@material-ui/core/colors";
import { getUserBehaviours } from "../skills/duck/actions";
// import ImageUpload from "../../components/Image";
import { getEmployee, getEmployeeMetrics } from "../employees/duck/actions";

import { Button, Col, Container, Row } from "react-bootstrap";

import Loading from "../../components/Loading";
import Avatar from "react-avatar";
import { AiOutlineCrown } from "react-icons/all";
import "./user-profile.scss";
import {
  getMasteredBehaviors,
  getPracticedBehaviors,
  requestBadge,
} from "./duck/actions";
import useSWR from "swr";
import axios from "axios";
import { API_ROOT } from "../../helpers/constants";

import {
  createWorklog,
  getReview,
  getWorklogs,
  reviewWorklog,
} from "../worklogs/duck/actions";

import { createProject } from "../projects/duck/actions";
import { createTask } from "../tasks/duck/actions";
import { Link } from "react-router-dom";
import { AddWorklogDrawer } from "./AddWorklogDrawer";
import { ViewReflectionsDrawer } from "./ViewReflectionsDrawer";

// const Container = styled.div`
//   margin-top: 5%;
//
// `;

const UserProfileComponent = ({
  user = {},
  match = {},
  behaviours = {},
  isEmployee,
  getEmployee,
  worklogs,
  isShiftHappens,
  getBehaviours,
  getWorklogs,
  createWorklog,
  requestBadge,
  isEmployeeAndManager,
}) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  const dispatch = useDispatch();
  // const behaviours = useSelector((state) =>
  //   state.behaviours.allIds.map((id) => state.behaviours.byId[id])
  // );
  const employee = useSelector(
    (state) => Object.values(state.employees.byId)[0]
  );

  // console.log({ employee, user });

  const loading = useSelector((state) => state.employees.loading);

  useEffect(() => {
    match.params.employeeId && getBehaviours(match.params.employeeId);
  }, [getBehaviours, match.params.employeeId]);

  // Fetch for only employees
  useEffect(() => {
    if (isEmployee && user.employers.length === 1) {
      getWorklogs(user.id, user.employers[0].id);
      getBehaviours(user.id);
      getEmployee(user.employers[0].id, user.id);
    }
  }, [
    getBehaviours,
    getEmployee,
    getWorklogs,
    isEmployee,
    user.employerId,
    user.employers,
    user.employers.length,
    user.id,
  ]);

  // Fetch for employees with multiple team
  useEffect(() => {
    if (isEmployee && match.params.employerId) {
      getWorklogs(user.id, match.params.employerId);
      getBehaviours(match.params.employerId);
      getEmployee(match.params.employerId, user.id);
    }
  }, [
    getBehaviours,
    getEmployee,
    getWorklogs,
    isEmployee,
    match.params.employerId,
    user.employerId,
    user.employers,
    user.employers.length,
    user.id,
  ]);

  // Fetch for  person with manager and employee
  useEffect(() => {
    if (isEmployeeAndManager) {
      getWorklogs(user.id, user.employers[0].id);
      getBehaviours(user.id);
      getEmployee(user.employers[0].id, user.id);
    }
  }, [getBehaviours, getEmployee, getWorklogs, isEmployeeAndManager, user]);

  useEffect(() => {
    if (!isEmployee) {
      getBehaviours(match.params.id);
      getEmployee(match.params.id);

      /*if (user.employers > 0) {
        getEmployee(user.employers[0].id, match.params.id);
      }*/
    } else {
      getEmployee(user.id);
    }
  }, [dispatch, getBehaviours, getEmployee, isEmployee, match.params.id, user]);

  // console.log({ user, match })
  // console.log({ isEmployee, match, params : match.params })

  useEffect(() => {
    if (!isEmployee && employee) {
      dispatch(getEmployeeMetrics(employee?.sme.id, match.params.id));
    }
  }, [dispatch, employee, isEmployee, match.params.id, match.params.smeId]);

  let {
    data: practicedBehaviours,
    error: practicedBehavioursError,
    isValidating: practicedBehavioursLoading,
    mutate: mutatePracticedBehaviors,
  } = useSWR(
    () => {
      let url = isEmployee
        ? `${API_ROOT}/users/${user.id}/skills/evolving`
        : `${API_ROOT}/users/${match?.params?.id}/skills/evolving`;
      // console.log({ url });
      return url || null;
    },
    async function fetcher(url) {
      try {
        let { data: behaviours } = await axios.get(url);

        behaviours = behaviours
          .map((behaviour) => {
            let {
              SkillUser: { count, isMastered },
            } = behaviour;
            return {
              ...behaviour,
              mastered: isMastered,
              isMastered,
              timesPracticed: count,
            };
          })
          .sort((a, b) => {
            // console.log(b.timesPracticed, b.timesPracticed, b.timesPracticed > a.timesPracticed);
            return b.timesPracticed - a.timesPracticed;
          });

        //let's collapse duplicates
        // let first, convert data into a Map with reduce
        behaviours = behaviours.reduce((map, currBehaviour) => {
          // if the current behaviour is not in the map, add it
          let { title, timesPracticed } = currBehaviour;
          let previousBehaviour = map.get(title);

          map.set(title, {
            ...currBehaviour,
            timesPracticed:
              (previousBehaviour ? previousBehaviour.timesPracticed : 0) +
              timesPracticed,
          });

          return map;
        }, new Map());

        // console.log({ behaviours });

        // let's convert Map into an array
        // return [ ...behaviours].map(([ key, value ]) => value);
        return [...behaviours.values()];
      } catch (e) {
        return e;
      }
    },
    {
      initialData: [],
    }
  );

  const [addWorklogDrawerOpen, setAddWorklogDrawerOpen] = useState(false);
  const [viewReflectionsDrawerOpen, setViewReflectionsDrawerOpen] =
    useState(false);

  let [masteredBehavioursLength, setMasteredBehavioursLength] = useState(0);
  let [practicedBehavioursLength, setPracticedBehavioursLength] = useState(0);

  let {
    data: masteredBehaviours,
    error: masteredBehavioursError,
    isValidating: masteredBehavioursLoading,
    mutate: mutateMasteredBehaviors,
  } = useSWR(
    `${API_ROOT}/users/${user.id}/skills/mastered`,
    async function fetcher(url) {
      try {
        const { data: resp } = await axios.get(url);

        return resp
          .map((behaviour) => {
            let {
              SkillUser: { count, isMastered },
            } = behaviour;
            return { ...behaviour, isMastered, timesPracticed: count };
          })
          .sort((a, b) => {
            return b.timesPracticed > a.timesPracticed;
          });
      } catch (e) {
        return e;
      }
    },
    {
      initialData: [],
    }
  );

  /*  let {
      data : practiceBehaviors,
      error : practiceBehaviorsError,
      isValidating : practiceBehaviorsLoading,
      mutate : mutatePracticeBehaviors,
    } = useSWR('/transaction/payment-methods', async function fetcher (url) {
      try {
        const { data: resp } = await axios.get(`${API_ROOT}/users/${match.params.id}/skills/mastered`);
        console.log({resp});
        return resp;
      } catch (e) {
        return e;
      }
    }, {

      initialData : [
        /!*{
        cardHolderName : "George Koomson",
        lastFourDigits : "2342",
        token : '123ed23f23',
        value : '123ed23f23',
        provider : 'visa',
        defaultCard : true,

      }, {
        cardHolderName : "Theodore Attigah",
        lastFourDigits : "1983",
        token : 'av50dad1az',
        provider : 'visa',
        value : 'av50dad1az',
      }, {
        cardHolderName : "Hamza Bawah",
        lastFourDigits : "3123",
        token : '4we6fq125',
        value : '4we6fq125',
        provider : 'visa',
      }*!/ ],
    });*/

  /*  useEffect(() => {
      getPracticedBehaviors(match.params.id);
      getMasteredBehaviors(match.params.id);

      console.log({match, isEmployee, user, practiceBehaviors, masteredBehaviors});

    },[]);*/

  function changeTab(e, tab) {
    setActiveTab(tab);
  }

  if (loading) {
    return <Loading />;
  }

  let owner = isEmployee ? user : employee;

  return (
    <Container className={""} style={{ marginTop: "5%" }}>
      <Row
        className={"justify-content-center mt-4 col-md-12 mx-auto px-lg-3 px-0"}
      >
        <Col className={"px-0 px-md-3 mb-2 mb-md-0"}>
          <h2 className={classes.profileText + " text-center text-md-left"}>
            Your Profile
          </h2>
          <p className={classes.proSubText + " text-center text-md-left"}>
            Your personal details and prospects
          </p>
        </Col>
        {isEmployee && (
          <div className="col-auto">
            <Button
              variant="secondary"
              className={"mr-3"}
              onClick={() => {
                setAddWorklogDrawerOpen(true);
              }}
            >
              New worklog
            </Button>
            <Link to={"/worklogs"}>
              <Button variant="outline-secondary">Timeline</Button>
            </Link>
          </div>
        )}
      </Row>
      <Row
        className={"justify-content-center mt-4 col-md-12 mx-auto px-lg-3 px-0"}
      >
        {activeTab === 0 && (
          <Col sm={12} lg={3} md={4} className={" mr-md-3 mr-lg-4"}>
            <Row className={""}>
              <Col className={"border rounded"}>
                <Row className={classes.avatar}>
                  <Col className={"d-flex mb-2 mt-3"}>
                    {/* <ImageUpload /> */}
                    <Avatar
                      className={"m-auto border-secondary padding-1"}
                      size="60px"
                      round
                      color="orange"
                      name={`${owner?.firstname} ${owner?.lastname}`}
                    />
                  </Col>
                </Row>
                <Row className={classes.avatar}>
                  {
                    <Col>
                      <p
                        className={classes.name}
                      >{`${owner?.firstname} ${owner?.lastname}`}</p>
                      <p className={classes.role}>{owner?.jobTitle}</p>
                      <p className={classes.company}>{owner?.sme?.name}</p>
                    </Col>
                  }
                </Row>
                <Row className={classes.userContainer}>
                  <Col>
                    <div
                      className={`${classes["profile-card"]} ${classes["profile-card-rating"]} row`}
                    >
                      <Col className={"px-0"}>
                        <p className={classes.rateText}>Rating</p>
                        <Rating
                          name="rating"
                          defaultValue={0}
                          precision={0.5}
                          value={employee?.averageRating || 0}
                          style={{ color: deepOrange[700], paddingLeft: 15 }}
                        />
                      </Col>
                      <div className={"col-auto d-flex"}>
                        <div className={` text-large d-flex m-auto rating`}>
                          <p className={"m-auto"}>{owner?.averageRating}</p>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`${classes["profile-card"]} ${classes["profile-card-worklog"]} row`}
                    >
                      <Col className={"px-0"}>
                        <div
                          className={classes.rateText}
                          style={{ color: "#22669E" }}
                        >
                          Worklogs:
                        </div>
                        <div className={"font-weight-600 text-large pl-3"}>
                          {employee?.totalWorklogs}
                        </div>
                      </Col>
                      <div className={"col-auto d-flex"}>
                        <div
                          className={`${classes["card-icon"]} d-flex m-auto rating`}
                          style={{
                            backgroundColor: "rgba(34,102,158,0.21)",
                            color: "#22669E",
                          }}
                        >
                          <AiOutlineCrown className={"m-auto"} />
                        </div>
                      </div>
                    </div>

                    <div
                      className={`${classes["profile-card"]} ${classes["profile-card-badge"]} row`}
                    >
                      <Col className={"px-0"}>
                        <div
                          className={classes.rateText}
                          style={{ color: "#6154b1" }}
                        >
                          Badges:
                        </div>
                        <div
                          className={"font-weight-600 text-large pl-3"}
                          style={{ color: "#6154b1" }}
                        >
                          {owner?.totalBadges || 0}
                        </div>
                      </Col>
                      <div className={"col-auto d-flex"}>
                        <div
                          className={`${classes["card-icon"]} d-flex m-auto rating`}
                          style={{
                            backgroundColor: "rgba(84,89,159,0.21)",
                            color: "#6154b1",
                          }}
                        >
                          <AiOutlineCrown className={"m-auto"} />
                        </div>
                      </div>
                    </div>

                    {/*<div className={`${classes["profile-card"]}   d-block`}>
                        <div className={classes.rateText} style={{ color : "#6154b1" }}/>
                        <div className={"font-weight-600 text-large pl-3"}>{}</div>
                      </div>*/}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className={"d-none d-md-block"}>
              <Col className={"py-2 d-flex justify-content-between"}>
                <a href="#" className={" text-normal"}>
                  About
                </a>
                <a href={"#"} className={"mx-3 text-normal"}>
                  Privacy & Terms
                </a>
                <a href={"#"} className={" text-normal"}>
                  Help
                </a>
              </Col>
            </Row>
          </Col>
        )}
        <Col className={"border rounded"}>
          <ProfileTab
            activeTab={activeTab}
            onChangeTab={changeTab}
            behaviours={behaviours}
            requestBadge={requestBadge}
            user={user}
            practicedBehaviours={practicedBehaviours}
            practicedBehavioursError={practicedBehavioursError}
            practicedBehavioursLoading={practicedBehavioursLoading}
            mutatePracticedBehaviours={mutatePracticedBehaviors}
            masteredBehaviours={masteredBehaviours}
            masteredBehavioursError={masteredBehavioursError}
            masteredBehavioursLoading={masteredBehavioursLoading}
            mutateMasteredBehaviors={mutateMasteredBehaviors}
            masteredBehavioursLength={masteredBehavioursLength}
            practicedBehavioursLength={practicedBehavioursLength}
          />
        </Col>
      </Row>

      <ViewReflectionsDrawer
        open={viewReflectionsDrawerOpen}
        onClose={() => setViewReflectionsDrawerOpen(false)}
      />
      {isEmployee && (
        <AddWorklogDrawer
          open={addWorklogDrawerOpen}
          onClose={() => setAddWorklogDrawerOpen(false)}
          setAddWorklogDrawerOpen={setAddWorklogDrawerOpen}
          shiftHappens={isShiftHappens}
          worklogs={worklogs}
          tasks={employee?.tasks}
          employee={employee}
          user={user}
          behaviours={behaviours}
          createWorklog={createWorklog}
        />
      )}
    </Container>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr 3fr",
    },
    "profile-card": {
      height: 71,
      margin: "15px 0",
      display: "flex",
      borderRadius: 10,
    },
    "profile-card-worklog": {
      backgroundColor: "#E6F8FF",
    },
    "profile-card-badge": {
      backgroundColor: "#E7EBF9",
    },
    "profile-card-rating": {
      backgroundColor: "rgba(253,127,0,0.2)",
    },
    "card-icon": {
      height: "40px",
      width: "40px",
      marginRight: "10px",
      borderRadius: "50%",
      backgroundColor: "rgba(253, 107, 0, 0.27)",
    },
    rateText: {
      padding: "10px 16px",
      color: "#FD5600",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "16px",
    },
    proSubText: {
      paddingTop: 10,
      color: "#888888",
      fontSize: "12px",
      lineHeight: "15px",
    },
    profileText: {
      color: "#FD5600",
      fontSize: "24px",
      fontWeight: "bold",
      lineHeight: "30px",
    },
    profile: {
      marginTop: 20,
      boxSizing: "border-box",
      height: "499px",
      width: "301px",
      border: "1px solid #EEEEEE",
      borderRadius: "5px",
      backgroundColor: "#FFFFFF",
    },
    name: {
      marginTop: 20,
      fontSize: "16px",
      fontWeight: "bold",
      letterSpacing: 0,
      lineHeight: "23px",
      textAlign: "center",
    },
    userContainer: {
      marginTop: 10,
    },
    company: {
      paddingTop: 8,
      color: "#FD894D",
      fontSize: "14px",
      fontWeight: "bolder",
      letterSpacing: 0,
      lineHeight: "16px",
      textAlign: "center",
    },
    role: {
      fontSize: "14px",
      fontWeight: "500",
      letterSpacing: 0,
      lineHeight: "16px",
      textAlign: "center",
    },
    avatar: {
      paddingTop: 10,
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },
    large: {
      width: theme.spacing(15),
      height: theme.spacing(15),
    },
  })
);

const mapStateToProps = ({
  profile: { masteredBehaviors = [], practiceBehaviors = [], loading },
  worklogs: { allIds, byId },
  employees,
  behaviours,
  reviews,
}) => {
  return {
    masteredBehaviors,
    practiceBehaviors,
    employee: employees.allIds.map((id) => employees.byId[id])[0] || {},
    worklogs: allIds.map((id) => byId[id]),
    review: reviews.allIds.map((id) => reviews.byId[id])[0] || {},
    behaviours: behaviours.allIds.map((id) => behaviours.byId[id]),
    loading,
    byId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPracticedBehaviors: (smeId) => dispatch(getPracticedBehaviors(smeId)),
    getMasteredBehaviors: (id) => dispatch(getMasteredBehaviors(id)),
    requestBadge: (userId, skillId, managerId) =>
      dispatch(requestBadge(userId, skillId, managerId)),
    getEmployee: (smeId, id) => dispatch(getEmployee(smeId, id)),
    getBehaviours: (userId) => dispatch(getUserBehaviours(userId)),
    getReview: (id) => dispatch(getReview(id)),
    createWorklog: (data, isOnline, userId) =>
      dispatch(createWorklog(data, isOnline, userId)),
    createProject: (data, isEmployee) =>
      dispatch(createProject(data, isEmployee)),
    createTask: (data, isEmployee) => dispatch(createTask(data, isEmployee)),
    getWorklogs: (employeeId, smeId) =>
      dispatch(getWorklogs(employeeId, smeId)),
    reviewWorklog: (data) => dispatch(reviewWorklog(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileComponent);
