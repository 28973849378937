import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { useDispatch, useSelector } from "react-redux";
import { getWorklogs } from "../modules/worklogs/duck/actions";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    paddingBottom: 10,
  },
  pos: {
    marginBottom: 12,
    paddingBottom: 10,
  },
  links: {
    marginBottom: 12,
    paddingBottom: 10,
    cursor: "pointer",
    color: "blue",
  },
  flex: {
    marginTop: 10,
    display: "flex",
    justifyContent: "space-between",
  },
});

function ProfileReview({ data = {}, history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const worklogs = useSelector((state) => Object.values(state.worklogs.byId));

  useEffect(() => {
    if (data?.id && data?.sme?.id) {
      dispatch(getWorklogs(data?.id, data?.sme.id));
    }
  }, [data, dispatch]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          variant="h2"
          component="h2"
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Recent Worklogs and Reviews
        </Typography>
        <div style={{ textAlign: "center" }}>
          <Typography className={classes.pos} color="textSecondary">
            Full name:{" "}
            <strong>
              {data && data.firstname} {data && data.lastname}
            </strong>
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            Job title:<strong>{data && data.jobTitle}</strong>
          </Typography>
        </div>

        <div className={classes.flex}>
          <div>
            <Typography className={classes.pos} color="textSecondary">
              <strong>Latest Worklogs</strong>
            </Typography>
            {data && data.latestWorklogs
              ? data.latestWorklogs.reverse().map((worklog) => (
                  <Typography
                    onClick={() =>
                      history.push(
                        `/teams/${data.sme.id}/employees/${data.id}?type=worklogs&id=${worklog.id}`
                      )
                    }
                    key={worklog.id}
                    className={classes.links}
                    color="textSecondary"
                  >
                    {new Date(worklog.createdAt).toDateString()}
                  </Typography>
                ))
              : "No latest worklogs"}
          </div>
          <div>
            <Typography className={classes.pos} color="textSecondary">
              <strong>Reviews</strong>
            </Typography>
            {worklogs
              ? worklogs
                  .filter((wrk) => wrk.review)
                  .map((worklog) => (
                    <div style={{ display: "flex" }} key={worklog.id}>
                      <DoneAllIcon
                        style={{
                          fontSize: "20px",
                          fill: "green",
                          paddingRight: 5,
                        }}
                      />
                      <Typography
                        onClick={() =>
                          history.push(
                            `/teams/${data.sme.id}/employees/${data.id}?type=reviews&id=${worklog.id}`
                          )
                        }
                        key={worklog.id}
                        className={classes.links}
                        color="textSecondary"
                      >
                        {new Date(worklog.createdAt).toDateString()}
                      </Typography>
                    </div>
                  ))
              : "No latest reviews"}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default withRouter(ProfileReview);
// http://localhost:3000/teams/2/employees/4?type=worklogs&id=6
