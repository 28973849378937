import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { formatDate } from "../../helpers/utils";
import { Badge } from "react-bootstrap";
import RenderHTML from "../../components/RenderHTML";

function ProjectDetails({
  title,
  description,
  startDate,
  endDate,
  assigner = {},
  optional_url,
}) {
  return (
    <div>
      <div style={{ marginTop: "2%" }}>
        <div>
          <p style={{ fontWeight: "bold" }}>Project title: </p>
          <Badge variant="info">{title}</Badge>
        </div>
        <div>
          <Text>Project description:</Text>
          <RenderHTML html={description} />
        </div>

        <div style={{ display: "flex" }}>
          <Text>
            <strong>Started:</strong> {new Date(startDate)?.toDateString()}
          </Text>
          <Text style={{ paddingLeft: 40 }}>
            <strong>Ended: </strong> {new Date(endDate)?.toDateString()}
          </Text>
        </div>
        {optional_url && (
          <div style={{ marginTop: "3%", marginBottom: 10 }}>
            <h3>Learn more</h3>
            <p>
              <a target="__blank" href={optional_url}>
                {optional_url}
              </a>
            </p>
          </div>
        )}
        {assigner && assigner.firstname && (
          <div>
            <Text>
              Assigned By: {`${assigner.firstname} ${assigner.lastname}`}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
}

ProjectDetails.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  assigner: PropTypes.object,
};

const Text = styled.p`
  padding-top: 4%;
  font-size: 15;
  font-weight: bolder;
`;

export default ProjectDetails;
