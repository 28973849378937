import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Header from "../../components/Header";
import { headerText } from "../../helpers/text";
import {
  CardWrapper,
  CardHeader,
  CardHeading,
  CardFieldset,
} from "../../components/TeamCard";
import Graph from "../../components/Graph";

function EmployeeTeams({
  user,
  analysis,
  fetchWorklogsAnalysis,
  teams = [],
  history,
  ...rest
}) {
  function filterAnalysis() {
    return analysis.map((rep) => rep).flat();
    // .map((rep) => console.log(rep.createdAt));
  }

  useEffect(() => {
    if (teams.length) {
      teams.forEach((team) => fetchWorklogsAnalysis(user.id, team.id));
    }
  }, [fetchWorklogsAnalysis, teams, teams.length, user.id]);

  function gotoEmployer(data) {
    return history.push(`/employers/${data.id}/worklogs?employer=${data.name}`);
  }

  return (
    <div>
      <div style={{ paddingLeft: "2%", paddingRight: "2%", marginBottom: 30 }}>
        <Header
          header={`${user.firstname}, here are your teams`}
          subHeader={headerText.multipleEmployee["subText"]}
        />
      </div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
        {teams.map((team, i) => (
          <CardWrapper onClick={() => gotoEmployer(team)} key={team.id}>
            <CardHeader>
              <CardHeading>{team.name}</CardHeading>
              {/* <CardFieldset>
                <Graph data={filterAnalysis(analysis[i])} />
              </CardFieldset> */}
            </CardHeader>
          </CardWrapper>
        ))}
      </div>
    </div>
  );
}

EmployeeTeams.propTypes = {
  user: PropTypes.object,
  analysis: PropTypes.array,
  fetchWorklogsAnalysis: PropTypes.func,
  teams: PropTypes.array,
  history: PropTypes.object,
};

export default EmployeeTeams;
