import React, { useState } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import TextInput from "../../components/TextInput";
import { resetPassword } from "../../routes/ducks/reducer";
import { images } from "../../helpers/utils";
import { ReactComponent as LoadingIcon } from "../../images/load.svg";
import { Link } from "react-router-dom";

export function ResetPassword({ loading, resetPassword, error, emailSent }) {
  const classes = useStyles();
  const [email, setEmail] = useState(null);

  function onChange({ target }) {
    setEmail(target.value);
  }

  function onSubmit(e) {
    e.preventDefault();
    return resetPassword(email);
  }

  return (
    <div style={{ display: "flex" }}>
      <div className="hero" />;
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            style={{ margin: "0 auto", paddingBottom: "2%" }}
            width="150"
            src={images.logo}
            alt="logo"
          />
          <Typography component="h1" variant="h5">
            Reset your password
          </Typography>
          {error && <Alert severity="error">Email does not exist</Alert>}
          {emailSent && (
            <Alert severity="success">
              Check your email to complete your password reset! If you can't
              find a message from SkillNav, email us at{" "}
              <code>technology@generationenterprise.org</code>
            </Alert>
          )}

          <form className={classes.form} noValidate>
            <TextInput
              onChange={onChange}
              placeholder="Email address"
              required
              fullWidth
              label="Email address"
              name="email"
            />
            <Button
              disabled={!email || loading}
              type="submit"
              onClick={onSubmit}
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              Verify {loading && <LoadingIcon />}
            </Button>
            <Link to="/login" variant="body2">
              Go back to login screen
            </Link>
          </form>
        </div>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    height: "10px !important", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(0, 0, 2),
  },
}));

const mapState = ({ auth }) => ({
  loading: auth.loading,
  error: auth.error,
  emailSent: auth.emailSent,
});

export default connect(mapState, { resetPassword })(ResetPassword);
