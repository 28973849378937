import axios from "axios";
import {
  CREATE_SME_SUCCESS,
  FETCH_SMES,
  FETCH_SMES_FAILURE,
  FETCH_SMES_SUCCESS,
  EDIT_SME_SUCCESS,
  EDIT_SME_FAILURE,
  DELETE_SME_SUCCESS,
  DELETE_SME_FAILURE,
} from "./types";
import { API_ROOT } from "../../../helpers/constants";
import { normalizeSmes } from "./normalizr";
import { showSnackbarSuccess, showSnackbarError } from "../../Snackbar/reducer";

export const createSme = (data,setLoading, setOpen) => async (dispatch) => {
  try {
    setLoading(true)
    const { data: resp } = await axios.post(`${API_ROOT}/smes`, data);
    dispatch({ type: CREATE_SME_SUCCESS, payload: resp });
    dispatch(
      showSnackbarSuccess({
        message: `${data.name} has been created successfully`,
      })
    );
    setLoading(false)
    setOpen(false)
  } catch (e) {
    setLoading(false)
    dispatch(
      showSnackbarError({
        message:
          e.response.data.error.errors || "Sorry error occurred, try again ",
      })
    );
    dispatch(
      showSnackbarError({
        message: `Sorry an error occurred, Please try again`,
      })
    );
  }
};

export const getOwnerSme = (ownerId, roles, match) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SMES });

    let data;
    if (match) {
      data = await axios.get(`${API_ROOT}/users/${match}/smes/owned`);
    } else if (roles.find((role) => role.name === "super-admin" && !match)) {
      data = await axios.get(`${API_ROOT}/smes/admin`);
    } else if (roles.find((role) => role.name === "sme-owner")) {
      data = await axios.get(`${API_ROOT}/users/${ownerId}/smes/owned`);
    } else {
      data = await axios.get(`${API_ROOT}/users/${ownerId}/smes/managed`);
    }
    dispatch({
      type: FETCH_SMES_SUCCESS,
      payload: normalizeSmes(data.data),
    });
  } catch (e) {
    dispatch({ type: FETCH_SMES_FAILURE, payload: e });
  }
};

export const getEmployeeTeams = (id) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SMES });
    const { data } = await axios.get(`${API_ROOT}/users/${id}/employers`);
    dispatch({ type: FETCH_SMES_SUCCESS, payload: normalizeSmes([data]) });
  } catch (e) {
    dispatch({ type: FETCH_SMES_FAILURE, payload: e });
  }
};

export const getSme = (id) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SMES });
    const { data } = await axios.get(`${API_ROOT}/smes/${id}`);
    dispatch({ type: FETCH_SMES_SUCCESS, payload: normalizeSmes([data]) });
  } catch (e) {
    dispatch({ type: FETCH_SMES_FAILURE, payload: e });
  }
};

export const editSme = (id, rec) => async (dispatch) => {
  try {
    const { data } = await axios.patch(`${API_ROOT}/smes/${id}`, rec);
    dispatch({ type: EDIT_SME_SUCCESS, payload: data });
    dispatch(showSnackbarSuccess({ message: "Team updated successfully" }));
  } catch (e) {
    dispatch({ type: EDIT_SME_FAILURE, payload: e });
    dispatch(
      showSnackbarError({ message: "Unable to update team, try again" })
    );
  }
};

export const deleteSme = (id) => async (dispatch) => {
  try {
    await axios.delete(`${API_ROOT}/smes/${id}`);
    dispatch({ type: DELETE_SME_SUCCESS, payload: id });
    dispatch(showSnackbarSuccess({ message: "Team deleted successfully" }));
  } catch (e) {
    dispatch(
      showSnackbarError({ message: "Unable to delete team, try again" })
    );
    dispatch({ type: DELETE_SME_FAILURE, payload: e });
  }
};
