import React, { Fragment, useEffect, useState } from "react";
import uuid from "uuid/v4";
import qs from "query-string";
import Drawer from "../../components/DrawerWrapper";
import DrawerWrapper from "../../components/DrawerWrapper";
import AddWorklog from "./AddWorklog";
import EmptyWorklogsModal from "../../components/EmptyWorklogModal";
import ProjectDetails from "../projects/ProjectDetails";
import Header from "../../components/Header";
import WorklogTabs from "../../components/WorklogTabs";
import Details from "./WorklogDetails";
import Loader from "../../components/Loader";
import { headerText } from "../../helpers/text";
import ReviewBadge from "../../components/ReviewBadges";
import useOfflineOnline from "../../hooks/useOfflineStatus";
import moment from "moment";
import { Button } from "react-bootstrap";
import AddTask from "../tasks/AddTasks";
import AddProject from "../projects/AddProject";
import useForm from "../../hooks/useForm";
import {
  isEmpty as stringIsEmpty,
  someEmpty,
  allEmpty,
} from "../../helpers/utils";
import { Hint } from "../../components/Hint";
import { reverse } from "lodash/fp";

const taskFieldId = uuid();
const initialState = { [taskFieldId]: {} };
const projectFieldId = uuid();
const initialProject = { [projectFieldId]: {} };

function Worklog({
  user,
  match = {},
  location,
  getEmployee,
  employee,
  history,
  worklogs,
  createWorklog,
  createProject,
  createTask,
  byId = {},
  getBehaviours,
  behaviours = {},
  isEmployee,
  getReview,
  getWorklogs,
  review,
  reviewWorklog,
  isEmployeeAndManager,
  loading,
  isShiftHappens,
}) {
  const [addWorklogDrawerOpen, setAddWorklogDrawerOpen] = useState(false);
  const [record, setRecord] = useState({ projects: [], tasks: [] });
  const [values, setValues] = useState({});
  const [isEmpty, setIsEmpty] = useState(false);
  const [notify, setNotify] = useState(false);
  const [selected, setSelected] = useState(null);
  const [tab, setTab] = useState(0);
  const [data, setData] = useState(initialState);
  const [projectData, setProjectData] = useState(initialProject);
  const [show, setShow] = useState(false);
  const { isOnline } = useOfflineOnline();
  const [description, setDescription] = useState("");
  const [open, setOpenDrawer] = useState(false);

  const [feedback, setFeedback] = useState("");
  const [feeling, setFeeling] = useState("");

  const {
    values: drawerValues,
    setValues: setDrawerValues,
    handleChange: drawerHandleChange,
  } = useForm();

  //region hooks

  useEffect(() => {
    if (worklogs.length === 0) {
      setIsEmpty(true);
    }
  }, [worklogs.length]);

  useEffect(() => {
    match.params.employeeId && getBehaviours(match.params.employeeId);
  }, [getBehaviours, match.params.employeeId]);

  // Fetch for only employees
  useEffect(() => {
    isEmployee &&
      user.employers.length === 1 &&
      getWorklogs(user.id, user.employers[0].id);
    isEmployee && user.employers.length === 1 && getBehaviours(user.id);
    isEmployee &&
      user.employers.length === 1 &&
      getEmployee(user.employers[0].id, user.id);
  }, [
    getBehaviours,
    getEmployee,
    getWorklogs,
    isEmployee,
    user.employerId,
    user.employers,
    user.employers.length,
    user.id,
  ]);

  // Fetch for employees with mutiple team
  useEffect(() => {
    isEmployee &&
      match.params.employerId &&
      getWorklogs(user.id, match.params.employerId);
    isEmployee &&
      match.params.employerId &&
      getBehaviours(match.params.employerId);
    isEmployee &&
      match.params.employerId &&
      getEmployee(match.params.employerId, user.id);
  }, [
    getBehaviours,
    getEmployee,
    getWorklogs,
    isEmployee,
    match.params.employerId,
    user.employerId,
    user.employers,
    user.employers.length,
    user.id,
  ]);

  // Fetch for  person with manager and employee
  useEffect(() => {
    isEmployeeAndManager && getWorklogs(user.id, user.employers[0].id);
    isEmployeeAndManager && getBehaviours(user.id);
    isEmployeeAndManager && getEmployee(user.employers[0].id, user.id);
  }, [getBehaviours, getEmployee, getWorklogs, isEmployeeAndManager, user]);

  useEffect(() => {
    match.params.employeeId &&
      match.params.smeId &&
      getWorklogs(match.params.employeeId, match.params.smeId);
  }, [getWorklogs, match.params.employeeId, match.params.smeId]);

  useEffect(() => {
    const params = qs.parse(location.search);
    if (params.type === "reviews") {
      getReview(params.id);
    }
  }, [getReview, location.search]);

  useEffect(() => {
    match.params.employeeId &&
      getEmployee(match.params.smeId, match.params.employeeId);
  }, [getEmployee, match.params.employeeId, match.params.smeId]);

  useEffect(() => {
    const params = qs.parse(location.search);
    if (params.type === "reviews" && review && review.worklogId) {
      setTab(1);
      const selectt =
        byId &&
        Object.values(byId).find((work) => work.review && work.review.id);
      selectt && setSelected(selectt);
      setNotify({ worklog: true });
    }
    if (params.type === "project") {
      setTab(3);
      setNotify({ project: true });
      setSelected(employee.projects && employee.projects.find((pro) => pro.id));
    }
    if (byId[params.id]) {
      setTab(params.type && params.type.toLowerCase());
      setSelected(byId[params.id]);
      setNotify({ worklog: true });
    }
  }, [byId, employee, employee.projects, location.search, review, tab]);

  //endregion

  let today = new Date();

  const [formType, setFormType] = useState("create");
  const [openTask, setOpenTask] = useState(false);

  function addTask() {
    setDrawerValues({});
    setFormType("create");
    setOpenTask(true);
  }

  function onSubmitTask(type) {
    setOpenDrawer(false);
    switch (type) {
      case "create":
        const data = {
          ...drawerValues,
          smeId: user.employers && user.employers[0].id,
          members: [user.id],
          description,
        };
        createTask(data, isEmployee);
        return setOpenTask(false);
      default:
        return;
    }
  }

  const onDescriptionChange = (value) => {
    setDescription(value);
  };

  const onDescriptionBlur = (previousRange, source, editor) => {
    handleChange({
      target: { name: "description", value: editor.getContents() },
    });
  };

  let header = (
    <h4>
      Worklog - <span>{moment(today).format("ddd")}</span>,{" "}
      <span className={"font-weight-600"}>
        {moment(today).format("DD MMM, YYYY")}
      </span>
    </h4>
  );

  //region eventHandler
  function onSubmit(type) {
    setAddWorklogDrawerOpen(false);
    const rep = {
      ...record,
      feedback,
      mood: feeling,
      projects: record.projects.filter((pro) => Boolean(pro.log)), // Remove all projects and tasks with no log
      tasks: record.tasks.filter((task) => Boolean(task.log)),
      smeId: (user && match?.params?.employerId) || user.employers?.[0]?.id,
    };
    return createWorklog(rep, isOnline, user.id);
  }

  function onAddProject() {
    const id = uuid();
    const newState = { ...projectData, [id]: [] };
    setProjectData(newState);
  }

  function onAddRow() {
    const id = uuid();
    const newState = { ...data, [id]: [] };
    setData(newState);
  }

  const handleFeedbackFormatChange = (value) => {
    setFeedback(value);
  };

  const handleChange = ({ target }) => {
    if (target.name === "feedback") {
      return setRecord({
        ...record,
        feedback: target.value,
      });
    } else if (record.skills && record.skills.length) {
      let skills = record.skills;
      let { value, checked } = target;

      if (checked) {
        return setRecord({
          ...record,
          skills: [...skills, value],
        });
      } else {
        return setRecord({
          ...record,
          skills: skills.filter((skill) => skill !== value),
        });
      }
    } else {
      setRecord({
        ...record,
        skills: [target.value],
      });
    }
  };

  function onChange(id) {
    return function ({ target }) {
      setRecord({
        ...record,
        [target.name]: record[target.name].map((el, i) => {
          let found = i === id;
          if (found) {
            el = Object.assign(el, { ...found, log: target.value });
          }
          return el;
        }),
      });
    };
  }

  function onRemove(id) {
    // if (Object.values(data).length === 1) return;
    return function () {
      const newState = { ...data };
      delete newState[id];
      setData(newState);
    };
  }

  function onRemoveProject(id) {
    // if (Object.values(projectData).length === 1) return;
    return function () {
      const newState = { ...projectData };
      delete newState[id];
      setProjectData(newState);
    };
  }

  function handleSelect() {
    return function ({ id }, { name }) {
      if (name === "projects") {
        return setRecord({
          ...record,
          projects: [...record.projects, { projectId: id }],
        });
      }
      return setRecord({
        ...record,
        tasks: [...record.tasks, { taskId: id }],
      });
    };
  }

  function handleFeeling(feeling) {
    setFeeling(feeling);
  }

  function handleMultiSelect(value, { name }) {
    setValues({ ...values, [name]: value && value.map((val) => val.label) });
  }

  function onChangeTab(event, index) {
    setTab(index);
  }

  function onRowClick(data) {
    setSelected(data);
    setNotify({ worklog: true });
  }

  function openDrawer(type) {
    setData(initialState);
    setRecord({ projects: [], tasks: [] });
    setValues({});
    setAddWorklogDrawerOpen(true);
  }

  function onReview(e) {
    if (typeof e === "number") {
      return setValues({ ...values, rating: e });
    }
    setValues({ ...values, [e.target.name]: e.target.value });
  }

  async function submitReview() {
    const req = {
      ...values,
      worklogId: selected.id,
    };
    if (location.search) {
      history.push(location.pathname);
    }
    await reviewWorklog(req);
    await getWorklogs(employee.id, match.params.smeId);
    setNotify({});
    setValues({});
    setSelected(null);
  }

  function handleClose() {
    if (location.search) {
      history.push(location.pathname);
    }
    const params = qs.parse(location.search);
    if (location.search && params.type === "reviews") {
      setTab(1);
    } else if (location.search && params.type === "worklogs") {
      setTab(0);
    }
    setAddWorklogDrawerOpen(false);
    setSelected(null);
    setValues({});
    setNotify(null);
  }

  function filterReviews(worklogs) {
    return worklogs.filter((work = {}) => work.review && work.review.rating);
  }

  function filterWorklogs(worklogs) {
    return worklogs.filter((work = {}) => !work.review);
  }

  function addProject() {
    setDrawerValues({});
    setFormType("create");
    setOpenDrawer(true);
  }

  async function closeDialog() {
    setShow(true);
    setNotify(false);
  }

  function goBackToWorklog() {
    setShow(false);
    if (selected) {
      return setNotify({ worklog: true });
    }
  }

  const openAddWorklogDrawer = () => {
    setAddWorklogDrawerOpen(true);
  };

  let filteredWorklogs = reverse(filterWorklogs(worklogs));

  let filteredReviews = reverse(filterReviews(worklogs));

  //endregion

  if (loading) {
    return <Loader />;
  }

  let action = null;

  // && (filteredWorklogs?.length > 1)
  // if (tab === 0 && (filteredWorklogs?.length > 1)) {
  if (
    tab === 0 &&
    (isEmployee || isEmployeeAndManager) &&
    worklogs &&
    worklogs.length > 0
  ) {
    action = (
      <Button
        variant="secondary"
        onClick={() => {
          setAddWorklogDrawerOpen(true);
        }}
      >
        New worklog
      </Button>
    );
  }
  if (tab === 1 && isEmployee && employee?.tasks?.length > 0) {
    action = (
      <Button variant="secondary" onClick={addTask} varinat="secondary">
        Add Task
      </Button>
    );
  }
  if (tab === 2 && isEmployee && employee?.projects?.length > 0) {
    action = (
      <Button onClick={addProject} variant="secondary">
        Add Project
      </Button>
    );
  }

  // Project
  function onSubmitProject(type) {
    setOpenDrawer(false);
    switch (type) {
      case "create":
        const data = {
          ...drawerValues,
          smeId: user.employers && user.employers[0].id,
          members: [user.id],
        };
        return createProject(data, isEmployee);

      default:
        // setOpenDrawer(false);
        return;
    }
  }

  return (
    <Fragment>
      <div>
        <Header
          header={`Timeline for ${employee.firstname || user.firstname} ${
            employee.lastname || user.lastname
          }`}
          // subHeader={headerText["worklogs"].subText}
          headerHint={
            <Hint
              title={"Use your worklog to reflect on your progress."}
              messages={headerText["worklogs"].hint}
              direction={"right"}
              minWidth={280}
            />
          }
          action={action}
        />
      </div>
      {worklogs.length === 0 && (
        <EmptyWorklogsModal
          isEmployeeAndManager={isEmployeeAndManager}
          setIsEmpty={setIsEmpty}
          setAddWorklogDrawerOpen={setAddWorklogDrawerOpen}
          setTab={setTab}
          open={isEmpty}
        />
      )}
      <div>
        <WorklogTabs
          isShiftHappens={isShiftHappens}
          addTask={addTask}
          createTask={createTask}
          addProject={addProject}
          createProject={createProject}
          isEmployeeAndManager={isEmployeeAndManager}
          isEmployee={isEmployee}
          onChangeTab={onChangeTab}
          tab={tab}
          setTab={setTab}
          user={user}
          openAddWorklogDrawer={openAddWorklogDrawer}
          worklogs={filteredWorklogs}
          reviews={filteredReviews}
          onRowClick={onRowClick}
          openDrawer={openDrawer}
          behaviours={behaviours}
          tasks={employee.tasks}
          projects={employee.projects}
          openModal={openDrawer}
        />
      </div>
      <Drawer
        width="700px"
        formType={"create"}
        title={header}
        open={addWorklogDrawerOpen}
        onSubmit={onSubmit}
        onClose={() => setAddWorklogDrawerOpen(false)}
      >
        <AddWorklog
          isShiftHappens={isShiftHappens}
          onRemoveProject={onRemoveProject}
          projectData={projectData}
          onAddProject={onAddProject}
          worklogs={worklogs}
          employee={employee}
          tasks={employee.tasks || user.tasks}
          projects={employee.projects || user.projects}
          fields={data}
          feeling={feeling}
          record={record}
          handleSelect={handleSelect}
          handleFeeling={handleFeeling}
          onAddRow={onAddRow}
          onRemove={onRemove}
          handleChange={handleChange}
          behaviours={behaviours}
          onChange={onChange}
          feedback={feedback}
          onFeedbackFormatChange={handleFeedbackFormatChange}
        />
      </Drawer>
      <Drawer
        noFooter
        onClose={handleClose}
        open={notify && notify["project"]}
        title={"Project Assigned to you "}
      >
        <ProjectDetails {...selected} />
      </Drawer>

      <DrawerWrapper
        disableButton={someEmpty([drawerValues.title, description])}
        onSubmit={onSubmitTask}
        formType={formType}
        title={formType === "create" ? "Add Task" : "Update Task"}
        open={openTask}
        onClose={() => {
          setOpenTask(false);
          setDescription("");
        }}
      >
        <AddTask
          employees={[]}
          handleChange={drawerHandleChange}
          values={drawerValues}
          description={description}
          onDescriptionChange={onDescriptionChange}
          onDescriptionBlur={onDescriptionBlur}
        />
      </DrawerWrapper>

      <DrawerWrapper
        disableButton={someEmpty([
          drawerValues.title,
          description,
          drawerValues.startDate,
          drawerValues.endDate,
        ])}
        size="500px"
        formType={formType}
        onSubmit={onSubmitProject}
        title={formType === "create" ? "Add Project" : "Update Project"}
        open={open}
        onClose={() => {
          setOpenDrawer(false);
          setDescription("");
        }}
      >
        <AddProject
          employees={[]}
          handleChange={drawerHandleChange}
          values={drawerValues}
        />
      </DrawerWrapper>

      <ReviewBadge
        goBackToWorklog={goBackToWorklog}
        match={match}
        setShow={setShow}
        setOpen={setAddWorklogDrawerOpen}
        open={show}
        setIsEmpty={setIsEmpty}
      />
      <Drawer
        onSubmit={submitReview}
        noFooter={
          (selected && user.id === selected.userId) ||
          (selected && selected.review)
        }
        onClose={handleClose}
        open={notify && notify["worklog"]}
        title={`Worklog created on: ${new Date(
          selected?.createdAt
        ).toDateString()}`}
      >
        <Details
          handleClose={closeDialog}
          handleSelect={handleMultiSelect}
          setShow={setShow}
          user={user}
          values={values}
          isEmployee={isEmployee}
          handleChange={onReview}
          {...selected}
        />
      </Drawer>
    </Fragment>
  );
}

export default Worklog;
