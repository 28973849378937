import { combineReducers } from "redux";
import {
  CREATE_WORKLOG_SUCCESS,
  FETCH_WORKLOG_SUCCESS,
  FETCH_WORKLOG,
  CREATE_WORKLOG_FAILURE,
  FETCH_WORKLOG_FAILURE,
  FETCH_WORKLOG_ANALYSIS,
} from "./types";

function byId(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_WORKLOG_SUCCESS:
      return { ...state, [payload.id]: payload };
    case FETCH_WORKLOG_SUCCESS:
      return payload.entities.worklogs || {};
    default:
      return state;
  }
}

function allIds(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_WORKLOG_SUCCESS:
      return [payload.id, ...state];
    case FETCH_WORKLOG_SUCCESS:
      return (payload.result && payload.result.reverse()) || [];
    case FETCH_WORKLOG_FAILURE:
      return [];
    default:
      return state;
  }
}

function loading(state = false, action) {
  const { type } = action;
  switch (type) {
    case FETCH_WORKLOG:
      return true;
    case FETCH_WORKLOG_SUCCESS:
    case CREATE_WORKLOG_SUCCESS:
    case CREATE_WORKLOG_FAILURE:
    case FETCH_WORKLOG_FAILURE:
      return false;
    default:
      return state;
  }
}

function error(state = null, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_WORKLOG_FAILURE:
    case FETCH_WORKLOG_FAILURE:
      return payload;
    default:
      return state;
  }
}

function analysis(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_WORKLOG_ANALYSIS:
      return [...state, payload];
    default:
      return state;
  }
}

export default combineReducers({
  loading,
  byId,
  allIds,
  error,
  analysis,
});
