import React, { Fragment } from "react";
import PropTypes from "prop-types";
import TextInput from "../../components/TextInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { TextInputLabel } from "../../components/TextInput/TextInput.style";

function AddSkill({
  description,
  onDescriptionChange,
  onDescriptionBlur,
  onFormatChange,
  handleChange,
  values = {},
  isShiftHappens,
}) {
  return (
    <Fragment>
      <p>{`${
        isShiftHappens ? "Values" : "Behaviours"
      } are HOW you want workers to act on the job`}</p>
      <p style={{ paddingBottom: 20 }}>
        {`Type to add a new ${
          isShiftHappens ? "value" : "behaviour"
        } behaviour to the ${
          isShiftHappens ? "Value" : "Skill"
        } Library, so you can assign it
        to a team of workers`}
      </p>
      <TextInput
        type="text"
        fullWidth
        name="title"
        label="Title"
        onChange={handleChange}
        defaultValue={(values && values.title) || ""}
        placeholder="eg: Smiling to Customers"
      />
      <TextInput
        fullWidth
        name="customLevel"
        placeholder="eg: Senior"
        label="Level"
        onChange={handleChange}
        defaultValue={(values && values.customLevel) || ""}
      />
      <TextInput
        fullWidth
        name="customCategory"
        label="Custom Category"
        placeholder="eg: Team building"
        onChange={handleChange}
        defaultValue={(values && values.customCategory) || ""}
      />
      {/* <TextArea
        fullWidth
        name="description"
        label="Description"
        onChange={handleChange}
        defaultValue={(values && values.description) || ""}
        placeholder="eg:Tell us more about this "
      /> */}
      <TextInputLabel className={"mb-1 mt-2"}>Description</TextInputLabel>
      <ReactQuill
        theme="snow"
        name="description"
        onChange={onDescriptionChange}
        onBlur={onDescriptionBlur}
        value={description || ""}
      />
      <TextInput
        className={"mt-2"}
        label="Links"
        name="links"
        placeholder="Seperate multiple links with comma eg: https://google.com, https://yahoo.com"
        fullWidth
        onChange={handleChange}
        defaultValue={(values.links && values.links[0]) || ""}
      />
    </Fragment>
  );
}

AddSkill.propTypes = {
  handleChange: PropTypes.func,
  values: PropTypes.object,
};
export default AddSkill;
