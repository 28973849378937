import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { deepOrange } from "@material-ui/core/colors";
import { Badge, Button, Col, Modal, Row } from "react-bootstrap";
import { CheckCircle } from "@material-ui/icons";
import { FaBrain } from "react-icons/all";
import classes from "classnames";
import TimeAgo from "react-timeago";
import { ellipsify } from "../helpers/utils";
import { ViewReflectionsDrawer } from "../modules/UserProfile/ViewReflectionsDrawer";
import VerificationBadge from "./VerificationBadge";

/*const BhCard = styled.div`
  margin-top: 10px;
  box-sizing: border-box;
  height: auto;
  border: 2px solid #EEEEEE;
  border-radius: 5px;
  padding: 8px 16px;
  background-color: #FFFFFF;
  overflow: auto;
`*/
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {children}
    </div>
  );
}

/**
 * @description - This is the component for practiced behaviours
 * @param behaviour
 * @returns {JSX.Element}
 * @constructor
 */
function PracticedBehaviour(behaviour) {
  let {
    name,
    timesPracticed,
    reflections,
    lastPracticed,
    onClick,
    createdAt,
    onRepetitionsClick,
    onBadgeClick,
    badgeStatus = "NULL",
    badgeRequested = false,
    status = null,
  } = behaviour;

  return (
    <div
      className="behaviour-container rounded border py-3 px-3 mt-3"
      onClick={() => {}}
    >
      <Row className={""}>
        <Col
          className={"d-flex  cursor-pointer"}
          onClick={() => {
            typeof onClick === "function" && onClick(behaviour);
          }}
        >
          <h6 className={"my-auto text-normal font-weight-600"}>
            {ellipsify(name, 80)}
          </h6>
        </Col>

        <div
          className={"col-auto d-flex"}
          onClick={() => {
            onRepetitionsClick(behaviour);
          }}
        >
          <h6 className={"my-auto d-flex cursor-pointer"}>
            <span className={"font-weight-600 text-medium text-primary"}>
              {" "}
              {timesPracticed}{" "}
              <span
                className={"text-small font-weight-600 mt-auto mb-1 text-muted"}
              >
                repetition{timesPracticed !== 1 ? "s" : ""}
              </span>
            </span>
          </h6>
        </div>

        <div className={"col-auto d-flex justify-content-end flex-column"}>
          <p className={"m-0"}>
            <span className={"text-small text-muted"}>Last practiced</span>
          </p>
          <p className={"m-0"}>
            <span className={"text-small font-weight-600 text-muted"}>
              <TimeAgo date={lastPracticed} />
            </span>
          </p>
        </div>
        {
          <div
            className={classes(
              "col-auto d-flex justify-content-end flex-column",
              { "cursor-not-allowed": badgeStatus === "NOT_ALLOWED" }
            )}
          >
            {badgeStatus === null && timesPracticed > 1 ? (
              <Button onClick={onBadgeClick}>Request Badge </Button>
            ) : (
              <span onClick={onBadgeClick}>
                <VerificationBadge status={badgeStatus} />
              </span>
            )}
          </div>
        }
      </Row>
    </div>
  );
}

export default function ProfileTab({
  //region Props
  onChangeTab,
  isEmployeeAndManager,
  activeTab,
  tasks = [],
  projects = [],
  behaviours = [],
  user,
  practicedBehaviours = [],
  requestBadge,
  practicedBehavioursError = false,
  practicedBehavioursLoading = true,
  mutatePracticedBehaviors,
  masteredBehaviours = [],
  masteredBehavioursError = false,
  masteredBehavioursLoading = true,
  mutateMasteredBehaviors,
  masteredBehavioursLength = 0,
  practicedBehavioursLength = 0,
  //endregion
}) {
  const classes = useStyles();
  const theme = useTheme();

  //region State
  const [skills, setSkills] = React.useState([]);

  const [selectedBehaviour, setSelectedBehaviour] = React.useState(null);
  const [selectedBehaviourModalOpen, setSelectedBehaviourModalOpen] =
    React.useState(false);

  const [sortBy, setSortBy] = React.useState("most practiced");

  const [viewReflectionsDrawerOpen, setViewReflectionsDrawerOpen] =
    useState(false);
  const [reflections, setReflections] = useState(null);
  //endregion

  const handleClose = () => {
    setSelectedBehaviourModalOpen(false);
    setSelectedBehaviour(null);
  };

  const sortBehaviours = () => {
    return practicedBehaviours.sort((a, b) => {
      if (sortBy === "most practiced") {
        return a.timesPracticed - b.timesPracticed;
      } else if (sortBy === "least practiced") {
        return b.timesPracticed - a.timesPracticed;
      } else if (sortBy === "created") {
        return b.updatedAt > a.updatedAt;
      }
    });
  };

  const handleSortBy = (e) => {
    let value = e.target.value;
    setSortBy(value);

    setSkills(sortBehaviours());
  };

  const handleBadgeRequest = (behaviour) => {
    console.log({ behaviour });
    let { status, timesPracticed, updatedAt } = behaviour;
    if (behaviour.timesPracticed >= 3) {
      const CANT_REQUEST_BADGE_ENUM = ["APPROVED", "PENDING", "NOT_ALLOWED"];

      if (!CANT_REQUEST_BADGE_ENUM.includes(status)) {
        if (status === "REJECTED") {
          if (timesPracticed % 5 === 0) {
            requestBadge(user.id, behaviour.id, user?.employers?.[0]?.id);
          }
        } else {
          requestBadge(user.id, behaviour.id, user?.employers?.[0]?.id);
        }
      }
    }
  };

  const handleRepetitionsClick = (behaviour) => {
    setViewReflectionsDrawerOpen(true);
    setReflections(behaviour.reflections);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" elevation={0}>
        <Tabs
          className={"bg-white border-bottom"}
          value={activeTab}
          onChange={onChangeTab}
          indicatorColor="primary"
          variant="fullWidth"
        >
          <Tab label="Your behaviours" />
          {/*<Tab label="Your progress"/>
          <Tab label="Your Worklogs"/>
          <Tab label="Your prospects" />
          <Tab label="Your badges" />*/}
        </Tabs>
      </AppBar>

      <TabPanel value={activeTab} index={0} dir={theme.direction}>
        <Row className={"my-3 mx-0"}>
          <Col md={12} className={""}>
            <Row
              className={"my-3 px-3 py-3 bg-yellow rounded "}
              style={{ backgroundColor: "rgba(255,196,0,0.19)" }}
            >
              <div
                className={" d-flex p-1"}
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  backgroundColor: "rgba(255,196,0,0.5)",
                }}
              >
                <FaBrain
                  className={"m-auto"}
                  style={{ width: 25, height: 25, color: "#eeb703" }}
                />
              </div>
              <Col className={"d-flex"}>
                <h5 className={"my-auto"}>Behaviours practiced</h5>
              </Col>
            </Row>

            <Row className={"mt-3 px-0 pt-2 bg-yellow rounded "}>
              <Col className={"d-flex px-0"}>
                <h6 className={"my-auto text-small d-flex"}>
                  <span className={"font-weight-600 col-auto my-auto"}>
                    Sort by:
                  </span>
                  <select
                    className={"border-0 select form-control"}
                    id="sortBy"
                    name="sortBy"
                    value={sortBy}
                    onChange={handleSortBy}
                  >
                    <option value="most practiced">Most practiced</option>
                    <option value="least practiced">Least practiced</option>
                    {/*<option value="recently practiced">Recently practiced</option>*/}
                  </select>
                  {/*<span className={"font-weight-600 col-auto my-auto"}><BiChevronDown/></span>*/}
                </h6>
              </Col>
            </Row>

            <Row>
              <Col className={" px-0"}>
                {(Array.isArray(practicedBehaviours)
                  ? practicedBehaviours
                  : []
                ).map((bh) => {
                  let {
                    title,
                    timesPracticed,
                    reflections,
                    SkillUser: { isMastered, status, updatedAt },
                  } = bh;
                  return (
                    <PracticedBehaviour
                      onRepetitionsClick={handleRepetitionsClick}
                      name={title}
                      badgeStatus={status}
                      reflections={reflections}
                      timesPracticed={timesPracticed}
                      lastPracticed={updatedAt}
                      mastered={isMastered}
                      onBadgeClick={() => {
                        handleBadgeRequest(bh);
                      }}
                      onClick={() => {
                        setSelectedBehaviour(bh);
                        setSelectedBehaviourModalOpen(true);
                      }}
                    />
                  );
                })}
              </Col>
            </Row>
          </Col>

          <Col className={""}>
            {masteredBehavioursLength > 0 && (
              <Row className={"my-3 px-3"}>
                <Col className={"border-bottom"}>
                  <div className={"rounded p-3 w-auto"}>
                    <h5>Skills Mastered</h5>
                    {masteredBehavioursLength > 1 ? (
                      <p className={"text-small-normal"}>
                        You've mastered{" "}
                        <span className={"font-weight-600"}>
                          {masteredBehavioursLength}
                        </span>{" "}
                        of{" "}
                        <span className={"font-weight-600"}>
                          {practicedBehavioursLength}
                        </span>{" "}
                        skills
                      </p>
                    ) : (
                      <p>None</p>
                    )}
                  </div>
                </Col>
              </Row>
            )}
            {masteredBehavioursLength > 0 ? (
              <Row className={"my-3 px-3"}>
                <Col className={"border-bottom"}>
                  <div className={"rounded p-3 w-auto"}>
                    {practicedBehaviours.map(({ mastered, name, times }, s) => (
                      <Badge
                        className={"pl-3 pr-1 py-1 mr-2"}
                        key={`skill-${s}`}
                        pill
                        variant={mastered ? "secondary" : "light"}
                      >
                        {name} {times && <span>({times}&times;)</span>}{" "}
                        {mastered ? (
                          <CheckCircle className={"ml-2"} fontSize={"small"} />
                        ) : (
                          ""
                        )}
                      </Badge>
                    ))}
                  </div>
                </Col>
              </Row>
            ) : null}
          </Col>
        </Row>

        {selectedBehaviour && (
          <Modal
            size="sm"
            centered
            show={selectedBehaviourModalOpen}
            onHide={handleClose}
          >
            <Modal.Header className={"justify-content-center px-4"} closeButton>
              <Modal.Title className="">{selectedBehaviour.title}</Modal.Title>
            </Modal.Header>

            <Modal.Body className={"px-4 pb-4"}>
              <p>{selectedBehaviour.description}</p>

              {/*<RatingsChart behaviourName={selectedBehaviour.name}/>*/}
            </Modal.Body>

            <Modal.Footer className="p-4">
              <Row className={"justify-content-center mx-0 w-100"}>
                <Col sm={12} className={"text-center d-grid gap-2"}>
                  <Button
                    variant={"primary"}
                    size={"lg"}
                    className={"w-100"}
                    onClick={handleClose}
                  >
                    Ok
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>
        )}
      </TabPanel>

      {/*<TabPanel value={activeTab} index={1} dir={theme.direction}>*/}

      {/*{
        Boolean(data.length) ? <Graph data={data}/> :
        <div style={{ textAlign : 'center', marginTop : '10px' }}>No data Available </div>
      }*/}

      {/*</TabPanel>*/}
      {/*<TabPanel value={activeTab} index={2} dir={theme.direction}>
        <Row>
          <Col className={"d-flex justify-content-center p-3"}>
            <Button variant="secondary" color="secondary" className={" mr-3"}>
              Add Worklog
            </Button>
            <Button variant="secondary" color="primary" className={""}>
              Review Worklogs
            </Button>
          </Col>
        </Row>
      </TabPanel>*/}
      {/*<TabPanel value={activeTab}  index={3} dir={theme.direction}>Your badges</TabPanel>*/}

      <ViewReflectionsDrawer
        reflections={reflections}
        open={viewReflectionsDrawerOpen}
        onClose={() => {
          setViewReflectionsDrawerOpen(false);
          setReflections(null);
        }}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  tab: { backgroundColor: "#fff" },
  bhWrapper: { padding: "8px 16px" },
  practice: {
    backgroundColor: "#E1F7E1",
    color: "#3AC537",
    fontSize: "10px",
    fontWeight: "bold",
    paddingLeft: "5px",
    borderRadius: "2%",
    width: 120,
  },
  button: {
    float: "right",
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  headline: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: "bold",
    letterSpacing: 0,
    lineHeight: "23px",
  },
  subText: {
    color: "#6D7278",
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "18px",
    textAlign: "justify",
    paddingBottom: 14,
    overflow: "hidden",
    textOverflow: "ellipsis",
    // white-space: wrap;
  },
  container: {
    height: "50vh",
    overflow: "auto",
  },
}));
