export const FETCH_WORKLOG = 'FETCH_WORKLOG';
export const CREATE_WORKLOG_SUCCESS = 'CREATE_WORKLOG_SUCCESS';
export const CREATE_WORKLOG_FAILURE = 'CREATE_WORKLOG_FAILURE';

export const FETCH_WORKLOG_SUCCESS = 'FETCH_WORKLOG_SUCCESS';
export const FETCH_WORKLOG_FAILURE = 'FETCH_WORKLOG_FAILURE';

export const FETCH_REVIEWS_SUCCESS = 'FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_FAILURE = 'FETCH_REVIEWS_FAILURE';

export const FETCH_WORKLOG_ANALYSIS = 'FETCH_WORKLOG_ANALYSIS';
