import React, { useState, useEffect } from "react";
import Table from "mpharma-react-table";
import Header from "../../components/Header";
import Drawer from "../../components/DrawerWrapper";
import { taskHeaders } from "../../helpers/constants";
import useForm from "../../hooks/useForm";
import AddTask from "./AddTasks";
import Ellipsis from "../../components/Ellipsis";
import { headerText } from "../../helpers/text";
import NavigateTo from "../../components/NavigateTo";
import { Button } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import Loading from "../../components/Loading";

function Task({
  user,
  match,
  getTasks,
  tasks,
  getEmployees,
  createTask,
  employees,
  editTask,
  loading,
  deleteTask,
  isShiftHappens,
}) {
  const [openDrawer, setopenDrawer] = useState(false);
  const { values, setValues, handleChange } = useForm();
  const [formType, setFormType] = useState("create");

  const [description, setDescription] = useState("");

  const moreActions = [
    { name: "Edit", action: "edit" },
    { name: "Delete", action: "delete", className: "danger" },
  ];

  useEffect(() => {
    getTasks(match.params.id);
    getEmployees(match.params.id);
  }, [getEmployees, getTasks, match.params.id]);

  function onSubmit(type) {
    setopenDrawer(false);
    switch (type) {
      case "create":
        const data = {
          ...values,
          description,
          smeId: Number(match.params.id),
          members: values.members && values.members.map((mem) => mem.id),
        };
        createTask(data);
        return setopenDrawer(false);
      case "edit":
        const editedTask = {
          title: values.title,
          description: description,
          members:
            (values.members && values.members.map((mem) => mem.id)) || [],
        };
        return editTask(values.id, editedTask);
      default:
        return;
    }
  }

  function onSelect(action, data) {
    switch (action) {
      case "edit":
        setopenDrawer(true);
        setFormType("edit");
        return setValues(data);
      case "delete":
        let confirm = window.confirm(
          "Are you sure you want to delete this task?"
        );
        return confirm ? deleteTask(data.id) : null;
      default:
        return;
    }
  }

  function addTask() {
    setValues({});
    setFormType("create");
    setopenDrawer(true);
  }

  function handleSelect(name) {
    setValues({ ...values, members: name });
  }

  const onDescriptionChange = (value) => {
    setDescription(value);

    // handleChange({ target : { name : "description", value } })
  };
  const onDescriptionBlur = (previousRange, source, editor) => {
    console.log("from on tasks desc", {editor, val : editor.getContents()});
    handleChange({ target : { name : "description", value : editor.getContents() } })
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid">
      <Header
        header={
          isShiftHappens
            ? "Your Team's Helpers and Detractors"
            : headerText["task"].header
        }
        subHeader={headerText["task"].subText}
        navigate={
          <div style={{ display: "flex", width: "30vw" }}>
            <NavigateTo name="Go back to People" link={`/`} />
            <NavigateTo
              name="Next: Assign Projects"
              link={`/teams/${match.params.id}/projects`}
              left={"9%"}
            />
          </div>
        }
        action={
          <Button
            variant="contained"
            style={{ backgroundColor: deepOrange[500], color: "white" }}
            onClick={addTask}
          >
            {isShiftHappens ? "Add Helper / Detractors" : "Add Task"}
          </Button>
        }
      />
      <div className="row">
        <div className="col-md-12">
          <Table
            headers={taskHeaders}
            rowsPerPageOptions={[25]}
            emptyMessageStyle={{
              left: "45%",
              position: "absolute",
              fontSize: 25,
              width: "80%",
              // paddingTop: 20,
            }}
            emptyMessage={!tasks.length ? "No Tasks  available" : ""}
            data={tasks}
            tableBodyStyle={{ maxHeight: "50vh" }}
            tableStyle={{
              width: "100%",
              backgroundColor: "#fff",
              // marginTop: 20,
            }}
            hover
            renderIcon={(data) => (
              <Ellipsis
                as="td"
                onSelect={onSelect}
                data={data}
                moreActions={moreActions}
              />
            )}
          />
        </div>
      </div>

      <Drawer
        disableButton={!values.title || !values.description} onSubmit={onSubmit} formType={formType}
        title={formType === "create" ? "Add Task" : "Update Task"} open={openDrawer}
        onClose={() => setopenDrawer(false)}>
        <AddTask
          employees={employees}
          handleSelect={handleSelect}
          handleChange={handleChange}
          values={values}
          description={description}
          onDescriptionChange={onDescriptionChange}
          onDescriptionBlur={onDescriptionBlur}
        />
      </Drawer>
    </div>
  );
}

export default Task;
