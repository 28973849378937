import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Wrapper, IconContainer } from './Alert.style';
import SuccessIcon from '../../images/success';
import ErrorIcon from '../../images/error';
import CloseIcon from '../../images/close';

function Alert({ success, open, warning, handleClose, error, message, duration, action, position, ...rest }) {
  useEffect(() => {
    let timer;
    if (open) {
      timer = setTimeout(() => handleClose(), duration);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [duration, handleClose, open]);

  return (
    <Wrapper open={open} position={position} {...rest}>
      <Container>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconContainer data-testid="alert" success={success} warning={warning} error={error}>
            {success ? <SuccessIcon /> : <ErrorIcon />}
          </IconContainer>

          <div style={{ paddingLeft: '8px' }}>{message}</div>
        </div>
        {!action && <CloseIcon data-testid="closeIcon" style={{ cursor: 'pointer' }} onClick={handleClose} />}
        {action}
      </Container>
    </Wrapper>
  );
}

Alert.defaultProps = {
  open: false,
  position: 'topRight',
  duration: 5000
};

Alert.propTypes = {
  open: PropTypes.bool.isRequired,
  position: PropTypes.oneOf(['topLeft', 'topRight', 'bottomLeft', 'bottomRight']),
  success: PropTypes.bool,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  message: PropTypes.string.isRequired,
  action: PropTypes.element,
  duration: PropTypes.number,
  handleClose: PropTypes.func.isRequired
};

export default Alert;
