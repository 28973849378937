import { combineReducers } from "redux";
import {
  ADD_SKILL_SUCCESS,
  ADD_SKILL_FAILURE,
  FETCH_SKILLS_SUCCESS,
  FETCH_SKILLS_FAILURE,
  DELETE_SKILL_SUCCESS,
  FETCH_SKILLS,
  UPDATE_SKILL_SUCCESSFUL,
  FILTER_BEHAVIOURS,
  RESET_FILTER,
} from "./types";

function byId(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_SKILL_SUCCESS:
      return { ...state, [payload.id]: payload };
    case FETCH_SKILLS_SUCCESS:
      return payload.entities.behaviours || {};
    case UPDATE_SKILL_SUCCESSFUL:
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          ...payload.data,
        },
      };
    default:
      return state;
  }
}

function allIds(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_SKILL_SUCCESS:
      return [payload.id, ...state];
    case FETCH_SKILLS_SUCCESS:
      return payload.result.reverse() || [];
    case DELETE_SKILL_SUCCESS:
      return state.filter((id) => payload !== id);
    default:
      return state;
  }
}

function loading(state = false, action) {
  const { type } = action;
  switch (type) {
    case FETCH_SKILLS:
      return true;
    case DELETE_SKILL_SUCCESS:
    case FETCH_SKILLS_SUCCESS:
    case ADD_SKILL_SUCCESS:
    case FETCH_SKILLS_FAILURE:
    case ADD_SKILL_FAILURE:
      return false;
    default:
      return state;
  }
}

function error(state = null, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_SKILL_FAILURE:
    case FETCH_SKILLS_FAILURE:
      return payload;
    default:
      return state;
  }
}

function filters(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case FILTER_BEHAVIOURS:
      return payload;
    case RESET_FILTER:
      return {};
    default:
      return state;
  }
}

export default combineReducers({
  loading,
  byId,
  allIds,
  error,
  filters,
});
