import React from "react";
import Table from "mpharma-react-table";
import Rating from "@material-ui/lab/Rating";
import { deepOrange } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core";

function ReviewTable({ onRowClick, headers = [], data = [] }) {
  const classes = useStyles();
  headers = [
    {
      title: "Date Reviewed",
      align: "left",
      dataIndex: "reviewed",
      render: ({ review }) => new Date(review?.createdAt).toDateString(),
    },
    {
      title: "What went well",
      dataIndex: "reviewed",
      render: (item) =>
        item &&
        item.review && (
          <ul>
            {item.review.feedback &&
              item.review.feedback.map((imp, i) => <li key={i}>{imp}</li>)}
          </ul>
        ),
    },
    {
      title: "What did not go well",
      dataIndex: "improvement",
      render: (item) =>
        item &&
        item.review && (
          <ul>
            {item.review.improvement &&
              item.review.improvement.map((imp, i) => <li key={i}>{imp}</li>)}
          </ul>
        ),
    },

    {
      title: "Rating",
      dataIndex: "rating",
      render: (item) =>
        item &&
        item.review && (
          <Rating name="rating" disabled defaultValue={item.review.rating} />
        ),
    },
  ];
  return (
    <div className={classes.root}>
      <Table
        headers={headers}
        handleRowClick={onRowClick}
        rowsPerPageOptions={[25]}
        data={data}
        emptyMessageStyle={{
          left: "45%",
          position: "absolute",
          fontSize: 25,
          width: "80%",
          // paddingTop: 20,
        }}
        emptyMessage={` is not in any of the worklogs`}
        tableStyle={{
          width: "100%",
          backgroundColor: "#fff",
          marginTop: 0,
        }}
        tableBodyStyle={{ maxHeight: "50vh" }}
        hover
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    // paddingTop: "1%",
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  button: {
    float: "right",
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));

export default ReviewTable;
