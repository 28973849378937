import React, { useMemo, useState } from "react";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import qs from "query-string";
import Switch from "@material-ui/core/Switch";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { subscribeEmail } from "../../modules/employees/duck/actions";
import { Col, Modal, Row } from "react-bootstrap";
import TimePicker from "react-time-picker/dist/entry.nostyle";
import * as classes from "classnames";
import { isEmpty } from "../../helpers/utils";
import { Checkbox, FormControlLabel, styled } from "@material-ui/core";

import subscriptionStyles from "./subscribe-mail.scss";
import timePickerStyles from "./time-picker.scss";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 0,
  height: 20,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: ``,
      content: "'Yes'",
      left: 12,
    },
    "&:after": {
      backgroundImage: ``,
      content: "'No'",
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
  "& .PrivateSwitchBase-root-9": {
    padding: 0,
  },
}));

export default function SubscribeMailModal({
  isEmployee,
  isEmployeeAndManager,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const team = useSelector((state) => {
    return Object.values(state.smes.byId);
  })[0];
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [day, setDay] = useState("mon");
  const { email } = qs.parse(location.search);
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });

  const [time, setTime] = useState("12:00");

  const [everyday, setEveryday] = useState(false);
  const [weekends, setWeekends] = useState(false);
  const [allWeekdays, setAllWeekdays] = useState(false);

  // let [ previouslySelected, setPreviouslySelected ] = useState(null);

  const [selectedDays, setSelectedDays] = useState({
    sun: {
      value: "sun",
      label: "Sunday",
      shortLabel: "S",
      weekend: true,
      selected: false,
    },
    mon: { value: "mon", label: "Monday", shortLabel: "M", selected: false },
    tues: { value: "tues", label: "Tuesday", shortLabel: "T", selected: false },
    wed: { value: "wed", label: "Wednesday", shortLabel: "W", selected: false },
    thur: {
      value: "thur",
      label: "Thursday",
      shortLabel: "T",
      selected: false,
    },
    fri: { value: "fri", label: "Friday", shortLabel: "F", selected: false },
    sat: {
      value: "sat",
      label: "Saturday",
      shortLabel: "S",
      weekend: true,
      selected: false,
    },
  });

  const handleDaysSelect = (day, selected) => {
    let newSelectedDays = Object.assign(
      {},
      {
        ...selectedDays,
        [day]: { ...selectedDays[day], selected: !selected },
      }
    );

    setSelectedDays(newSelectedDays);
    // setPreviouslySelected(newSelectedDays);
  };

  const handleEverydaySelectChange = ({ target: { checked } }) => {
    setEveryday(checked);
    setWeekends(checked);
    setAllWeekdays(checked);
    let newSelectedDays = Object.assign({}, selectedDays);

    for (let key in selectedDays) {
      if (selectedDays.hasOwnProperty(key)) {
        newSelectedDays[key].selected = checked;
      }
    }
    console.log({ checked, newSelectedDays });
    // setSelectedDays(newSelectedDays);
  };

  const handleWeekdaysSelectChange = ({ target: { checked } }) => {
    setAllWeekdays(checked);
    let newSelectedDays = Object.assign({}, selectedDays);

    for (let key in selectedDays) {
      if (selectedDays.hasOwnProperty(key) && !selectedDays[key].weekend) {
        newSelectedDays[key].selected = checked;
      }
    }

    if (checked === false) {
      setEveryday(checked);
    }

    // setSelectedDays(newSelectedDays);

    // if ( checked ) { // TODO implement history pop and push
    //   setPreviouslySelected(newSelectedDays);
    // } else {
    //   setSelectedDays(newSelectedDays);
    //   setPreviouslySelected(newSelectedDays);
    // }
  };

  const handleWeekendsSelectChange = ({ target: { checked } }) => {
    setWeekends(checked);
    let newSelectedDays = Object.assign({}, selectedDays);

    for (let key in selectedDays) {
      if (selectedDays.hasOwnProperty(key) && selectedDays[key].weekend) {
        newSelectedDays[key].selected = checked;
      }
    }
    if (checked === false) {
      setEveryday(checked);
    }

    setSelectedDays(newSelectedDays);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleClose = () => {
    history.push({ search: "" });
  };

  const onSubscribe = () => {
    subscribeEmail(
      {
        email: user.email,
        userId: user.id,
        status: "subscribed",
        day,
      },
      history
    );
  };

  const [
    someDaysSelected,
    everydaySelected,
    allWeekdaysSelected,
    weekendsSelected,
  ] = useMemo(() => {
    return [
      Object.values(selectedDays).some((day) => day.selected),
      Object.values(selectedDays).every((day) => day.selected),
      Object.values(selectedDays).every((day) => !day.weekend && day.selected),
      Object.values(selectedDays).every((day) => day.weekend && day.selected),
    ];
  }, [selectedDays]);

  let canSubscribe = !isEmpty(time) && someDaysSelected;

  return (
    <div>
      <Modal centered show={Boolean(email)} onHide={handleClose}>
        <Modal.Header className={"justify-content-center px-4"} closeButton>
          <Modal.Title className="">Subscribe to mails </Modal.Title>
        </Modal.Header>

        <Modal.Body className={"px-4"}>
          {isEmployeeAndManager && (
            <>
              <Row>
                <Col className={"d-flex"}>
                  <div style={{ display: "flex", paddingTop: 10 }}>
                    <p>
                      As a MANAGER of the {team?.name} team you get WEEKLY
                      WORKLOG SUMMARIES on Fridays at 9 am local time
                    </p>

                    <Switch
                      checked={state.checkedA}
                      onChange={handleChange}
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className={"d-flex"}>
                  <div style={{ display: "flex", paddingTop: 15 }}>
                    <p>
                      As a MANAGER of the {team?.name} team you get DAILY
                      WORKLOG REPORTS on weekdays at 6pm local time
                    </p>
                    <Switch
                      checked={state.checkedB}
                      onChange={handleChange}
                      name="checkedB"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
          {isEmployee && (
            <Row>
              <Col className={"d-flex"}>
                <div style={{ display: "flex", paddingTop: 10 }}>
                  <p>
                    Get daily reminder emails to log your work as a member of{" "}
                    {team?.name}
                  </p>

                  <Android12Switch
                    name="checkedA"
                    checked={state.checkedA}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col className={"pr-1"}>
              <p style={{ paddingTop: 15 }}>Select a day</p>
              {/*<select
                onChange={handleSelect}
                defaultValue={day}
                style={{
                  height : 40,
                  marginTop : 10,
                  marginRight : 10,
                }}
              >
                {days.map((day) => (
                  <option value={day.value} style={{ margin : 10 }}>
                    {day.label}
                  </option>
                ))}
              </select>*/}

              <div className="days-picker border rounded">
                <table className={"p-2 mb-0"}>
                  <tbody>
                    <tr className="rdp-row border-bottom-0">
                      {Object.entries(selectedDays).map(
                        ([day, { shortLabel, weekend, selected }], index) => (
                          <td
                            key={`${day}_${index}`}
                            className={classes("dp-cell", {
                              weekend,
                              selected,
                            })}
                            onClick={(e) => {
                              handleDaysSelect(day, selected);
                            }}
                          >
                            <div
                              className={classes(
                                "rdp-button rdp-day text-center p-1",
                                { weekend, selected }
                              )}
                              tabIndex="0"
                            >
                              <span aria-hidden="true">{shortLabel}</span>
                            </div>
                          </td>
                        )
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
            <Col className={"pl-1"}>
              <p style={{ paddingTop: 15 }}>Set a time</p>
              <TimePicker
                value={time}
                onChange={setTime}
                clockIcon={null}
                disableClock
              />
            </Col>
          </Row>

          <Row>
            <Col className={""}>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={everyday || everydaySelected}
                      onChange={handleEverydaySelectChange}
                    />
                  }
                  label="Everyday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allWeekdays || allWeekdaysSelected}
                      onChange={handleWeekdaysSelectChange}
                    />
                  }
                  label="All Weekdays"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={weekends || weekendsSelected}
                      onChange={handleWeekendsSelectChange}
                    />
                  }
                  label="Weekends"
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="p-4">
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={onSubscribe}
            color="primary"
            autoFocus
            disabled={!canSubscribe}
          >
            Subscribe
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
