import React, { Fragment, useRef, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/all";
import Select from "../../components/BaseSelect";
import { Checkbox } from "../../components/Checkbox";
import { withStyles } from "@material-ui/core";
import TextInput from "../../components/TextInput";
import { Button, Col, Row } from "react-bootstrap";
import { groupBy } from "lodash/fp";
import * as classnames from "classnames";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ReactQuill from "react-quill";
import "../../assets/styles/scss/quill-snow.scss";
import "./add-wroklog-styles.scss";

/**
 *
 * @param feedback
 * @param onFeedbackFormatChange
 * @param handleChange
 * @param tasks
 * @param projects
 * @param onChange
 * @param fields
 * @param onRemove
 * @param onAddRow
 * @param behaviours
 * @param handleSelect
 * @param values
 * @param classes
 * @param onAddProject
 * @param projectData
 * @param onRemoveProject
 * @param isShiftHappens
 * @returns {JSX.Element}
 * @constructor
 */
function AddTask({
  feedback,
  feeling,
  employee,
  onFeedbackFormatChange,
  handleChange,
  tasks = [],
  projects = [],
  onChange,
  record,
  fields,
  onRemove,
  onAddRow,
  behaviours = [],
  handleSelect,
  handleFeeling,
  values = {},
  classes,
  onAddProject,
  projectData,
  onRemoveProject,
  isShiftHappens,
}) {
  const progressInput = useRef();
  const groupBehaviours = groupBy("customCategory", behaviours);

  const sorted = Object.entries(groupBehaviours).sort();

  let fieldRows = Object.keys(fields);
  let fieldRowsLen = fieldRows.length; // memoizing so we don't recompute it every run

  let projectRows = Object.keys(projectData);
  let projectRowsLen = projectRows.length;

  const [showTaskModal, setShowTaskModal] = useState(true);
  const [showProjectModal, setShowProjectModal] = useState(false);

  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);

  const handleClose = () => {
    setShowTaskModal(false);
    setShowProjectModal(false);

    setSelectedProject(null);
    setSelectedTask(null);
  };

  const handleSelectTaskChange = (id, index) => {
    setSelectedTask([id, index]);

    let inputEl = document.querySelector("#task_progress");
    handleSelect(index);
  };

  return (
    <Fragment>
      <div className={"p-3 pb-0 mb-3 l-grouping"} style={{ padding: 5 }}>
        <Row>
          <Col>
            <h4 className="text-primary mb-2 text-center">
              <span className="font-weight-600">Hi {employee.firstname}</span>!
              How was your day?
            </h4>
            <div className="d-flex m-0-auto justify-content-between align-items-center emojis-container">
              <div
                className={classnames(
                  "d-flex flex-column align-items-center emoji-container",
                  { selected: feeling === "happy" }
                )}
                onClick={() => {
                  handleFeeling("happy");
                }}
              >
                <img src="/img/emojis/happy.png" alt="Happy Emoji" />
                <p className="emoji-text text-small mt-1 text-muted">Happy</p>
              </div>

              <div
                className={classnames(
                  "d-flex flex-column align-items-center emoji-container",
                  { selected: feeling === "apathy" }
                )}
                onClick={() => {
                  handleFeeling("apathy");
                }}
              >
                <img src="/img/emojis/apathy.png" alt="Apathy Emoji" />
                <p className="emoji-text text-small mt-1 text-muted">Apathy</p>
              </div>

              <div
                className={classnames(
                  "d-flex flex-column align-items-center emoji-container",
                  { selected: feeling === "sad" }
                )}
                onClick={() => {
                  handleFeeling("sad");
                }}
              >
                <img src="/img/emojis/sad.png" alt="Sad Emoji" />
                <p className="emoji-text text-small mt-1 text-muted">Sad</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div style={{ padding: 5 }}>
        <div style={{ paddingBottom: 10 }}>
          <h5 className={"font-weight-600 text-primary"}>
            What were your 1-3 biggest accomplishments today?{" "}
          </h5>
          <p className={""}>
            {isShiftHappens
              ? "Note your progress"
              : "Note which tasks you made progress on"}
          </p>
        </div>

        <div
          className={"mb-3 px-2 rounded"}
          style={{ backgroundColor: "rgba(206, 212, 218, 0.2)" }}
        >
          {fieldRows.map((id, index) => {
            return (
              <Row
                className={classnames("mx-0 py-2 ", {
                  "border-top": index > 0,
                })}
                key={`frs_${index}`}
              >
                <Col className={"pl-2 pr-1"}>
                  <Row className={"m-0"}>
                    <Col sm={12} md={6} className={"p-0 pr-1"}>
                      <Select
                        getOptionLabel={(option) => `${option.title}`}
                        getOptionValue={(option) => option.id}
                        options={tasks}
                        name="tasks"
                        isMulti={false}
                        onChange={handleSelect(index)}
                        label={
                          isShiftHappens ? "Select a helper" : "Select a task"
                        }
                      />
                    </Col>
                    <Col sm={12} md={6} className={"p-0 pl-1"}>
                      <TextInput
                        fullWidth
                        label={!isShiftHappens && "What progress did you make?"}
                        placeholder={
                          !isShiftHappens && "Progress on selected task"
                        }
                        name="tasks"
                        type="textarea"
                        onChange={onChange(index)}
                      />
                    </Col>
                  </Row>
                </Col>

                {fieldRowsLen > 1 && (
                  <div
                    className={classnames(
                      "col-auto pl-1 pr-1 pt-3 my-auto cursor-pointer"
                    )}
                    onClick={onRemove(id)}
                    style={{ cursor: "pointer" }}
                  >
                    <AiOutlineCloseCircle size={20} />
                  </div>
                )}
              </Row>
            );
          })}
          <Row className={classnames("mx-0 pb-3 ")}>
            <Col className={"px-2"}>
              <Button
                variant="secondary"
                className={"px-2"}
                size={"sm"}
                onClick={onAddRow}
              >
                {isShiftHappens ? "Add helper" : "Add task"}
              </Button>
            </Col>
          </Row>
        </div>

        <div
          className={"mb-3 px-2 rounded"}
          style={{ backgroundColor: "rgba(206, 212, 218, 0.2)" }}
        >
          {projectRows.map((id, index) => {
            return (
              <Row
                className={classnames("mx-0 py-2 ", {
                  "border-top": index > 0,
                })}
                key={`prs_${index}`}
              >
                <Col className={"pl-2 pr-1"}>
                  <Row className={"m-0"}>
                    <Col sm={12} md={6} className={"p-0 pr-1"}>
                      <Select
                        getOptionLabel={(option) => `${option.title}`}
                        getOptionValue={(option) => option.id}
                        isMulti={false}
                        name="projects"
                        options={projects}
                        onChange={handleSelect(index)}
                        label="Select a project"
                      />
                    </Col>
                    <Col sm={12} md={6} className={"p-0 pl-1"}>
                      <TextInput
                        fullWidth
                        groupStyle={{}}
                        label="What progress did you make?"
                        placeholder="Progress on selected project"
                        name="projects"
                        onChange={onChange(index)}
                      />
                    </Col>
                  </Row>
                </Col>
                {projectRowsLen > 1 && (
                  <div
                    className={classnames(
                      "col-auto pl-1 pr-1 pt-3 my-auto cursor-pointer"
                    )}
                    onClick={onRemoveProject(id)}
                    style={{ cursor: "pointer" }}
                  >
                    <AiOutlineCloseCircle size={20} />
                  </div>
                )}
              </Row>
            );
          })}
          <Row className={classnames("mx-0 pb-3 ")}>
            <Col className={"px-2"}>
              <Button
                variant="orange"
                className={"px-2"}
                size={"sm"}
                onClick={onAddProject}
              >
                Add Project
              </Button>
            </Col>
          </Row>
        </div>
      </div>

      <div className={"p-3 pb-0 mb-3 l-grouping"} style={{ padding: 5 }}>
        <div style={{ paddingLeft: 5 }}>
          <h5 className={"font-weight-600 text-primary"}>
            When today did you face a challenge or make a breakthrough with the
            skills you're practicing?
          </h5>
          <p className={"mb-3"}>
            {/*{
              `Share what it was like practicing your ${isShiftHappens ? "values" : "behaviours"} today.
            The more you share, the faster your development and the better we can help coach you.`
            }*/}
            Reflect: What happened? What did you do?
          </p>
          {/*<TextInput fullWidth name="feedback" defaultValue={values.feedback} placeholder="Feedback" onChange={handleChange}/>*/}
          <ReactQuill
            className={"rounded"}
            theme="snow"
            name="feedback"
            placeholder={
              "Reflect on what it was like practicing your skills today"
            }
            onChange={onFeedbackFormatChange}
            value={feedback}
          />
        </div>

        <div style={{ padding: 5, marginTop: 20 }}>
          <div>
            <h5 className={"font-weight-600 text-primary"}>
              {isShiftHappens
                ? "What values did you practice?"
                : "Which skills did you practice in the reflection above?"}
            </h5>
            <p>
              {/*{`Note the ${isShiftHappens ? "values" : "behaviours"} you actively, consciously practiced on the job`}*/}
              Select the top 1&mdash;3 skills you focused on.
            </p>
          </div>

          <Row style={{ marginTop: 25 }}>
            {sorted?.map((behaviourArray, index) => {
              return (
                <Col sm={6} md={4} className={"mb-3"} key={`bhs_${index}`}>
                  <p className={"font-weight-500"}>{behaviourArray[0]}</p>
                  {behaviourArray[1].map((bh, i) => {
                    let disabled = false;

                    if (Array.isArray(record.skills)) {
                      disabled =
                        record.skills.length > 2 &&
                        !record.skills.includes(bh.id.toString());
                    }

                    return (
                      <div key={`bh_${i}`} style={{ marginTop: 10 }}>
                        <Checkbox
                          key={i}
                          name={bh.id}
                          value={bh.id}
                          disabled={disabled}
                          onChange={handleChange}
                          label={bh.title}
                        />
                      </div>
                    );
                  })}
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </Fragment>
  );
}

const styles = {
  emojiContainer: {
    img: {
      height: "35px",
      objectFit: "contain",
    },
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "2fr  2fr .1fr",
    gridGap: 10,
    marginTop: 4,
  },
  button: {
    padding: 3,
    border: "none",
    borderRadius: 5,
    cursor: "pointer",
    color: "white",
    fontSize: 12,
  },
  paper: {
    width: 600,
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
};

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: 600,
      "@media (max-width: 600px)": {
        width: "100%",
      },
    },
  })
);

export default withStyles(styles)(AddTask);
