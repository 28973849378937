import { connect } from "react-redux";
import AuthComponent from "./LoginComponent";
import { login } from "../../routes/ducks/reducer";

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data, type) => dispatch(login(data, type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthComponent);
