import AuthContainer from "../modules/auth/LoginContainer";
import SmeContainer from "../modules/teams/SmeContainer";
import EmployeesContainer from "../modules/employees/EmployeeContainer";
import BehaviourContainer from "../modules/behaviors/BehavioursContainer";
import TaskContainer from "../modules/tasks/TaskContainer";
import ProjectContainer from "../modules/projects/ProjectContainer";
import WorklogContainer from "../modules/worklogs/WorklogContainer";
import Dashboard from "../components/Dashboard";
import SkillContainer from "../modules/skills/SkillContainer";
import NotificationContainer from "../modules/notifications/NotificationContainer";
import SignupContainer from "../modules/auth/SignupContainer";
import EmployeeMetrics from "../modules/metrics/EmployeeMetrics";
import ResetPassword from "../modules/auth/ResetPassword";
import NewPassword from "../modules/auth/NewPassword";
import VerifyEmail from "../modules/auth/VerifyEmail";
import ConfirmEmail from "../modules/auth/ConfirmEmail";
import ProfileContainer from "../modules/profile/ProfileContainer"; // Temporal
import ResponseBadge from "../modules/auth/ResponseBadge";
import FullWidthTabs from "../components/WorklogTabs";
import RateContainer from "../modules/Rate/RateContainer";
import UserProfileComponent from "../modules/UserProfile/UserProfileComponent";
import AddWorklog from "../modules/worklogs/AddWorklog";
import WorklogOutside from "../modules/WorklogOutside";
import BadgeRequestOutside from "../modules/BadgesOutside";

export const authorized = [
  { path: "/", exact: true, component: Dashboard },
  // { path: "/", exact: true, component: UserProfileComponent },
  { path: "/teams", exact: true, component: SmeContainer, },
  { path: "/teams/:id/employees", exact: true, component: EmployeesContainer },
  { path: "/teams/:id/behaviours", exact: true, component: BehaviourContainer },
  { path: "/teams/:id/tasks", exact: true, component: TaskContainer },
  { path: "/teams/:id/projects", exact: true, component: ProjectContainer },
  { path: "/teams/:smeId/employees/:employeeId", component: WorklogContainer },
  { path: "/worklogs", exact: true, component: WorklogContainer },
  { path: "/employers/:employerId/worklogs", exact: true, component: WorklogContainer, },
  { path: "/progress", exact: true, component: EmployeeMetrics },
  { path: "/skills", exact: true, component: SkillContainer },
  { path: "/notifications", exact: true, component: NotificationContainer },
  { path: "/smes/:smeId/profile/:id", exact: true, component: UserProfileComponent, },
  { path: "/users/:userId/skills/:skillId/requestBadge", exact: true, component: ResponseBadge, },
  { path: "/rating", exact: true, component: RateContainer },
  { path: "/tabs", exact: true, component: FullWidthTabs },
  { path: "/admin/:id/teams", exact: true, component: SmeContainer },
  { path: "/admin/:id/teams/:id/employees", exact: true, component: EmployeesContainer, },
  { path: "/admin/:id/teams/:id/behaviours", exact: true, component: BehaviourContainer, },
  { path: "/admin/:id/teams/:id/tasks", exact: true, component: TaskContainer },
  { path: "/admin/:id/teams/:id/projects", exact: true, component: ProjectContainer, },
  { path: "/profile/:userId", exact: true, component: UserProfileComponent, },
];

export const unauthorized = [
  { path: "/signup", exact: true, component: SignupContainer },
  { path: "/login", exact: true, component: AuthContainer },
  { path: "/reset-password", exact: true, component: ResetPassword },
  { path: "/change-password/:token", exact: true, component: NewPassword },
  { path: "/verify/:token", exact: true, component: VerifyEmail },
  { path: "/confirm", exact: true, component: ConfirmEmail },
  { path: "/rating", exact: true, component: RateContainer },
  { path: "/worklogs/:employeeId/:smeId/:token", exact: true,
    component: WorklogOutside,
  },
  { path: "/badge-request", exact: true,
    component: BadgeRequestOutside,
  },
];
