import axios from "axios";
import {
  CREATE_PROJECT,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECT_FAILURE,
  DELETE_PROJECT_FAILURE,
  DELETE_PROJECT_SUCCESS,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
} from "./types";
import { API_ROOT } from "../../../helpers/constants";
import { normaliz } from "./normalizr";
import { showSnackbarSuccess, showSnackbarError } from "../../Snackbar/reducer";
import { getEmployee } from "../../employees/duck/actions";

export const createProject = (data, isEmployee) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_PROJECT });
    const { data: resp } = await axios.post(`${API_ROOT}/projects`, data);
    if (isEmployee) {
      dispatch(getEmployee(data.smeId, data.members[0]));
      return dispatch(
        showSnackbarSuccess({ message: "Project created successfully" })
      );
    }
    dispatch({ type: CREATE_PROJECT_SUCCESS, payload: resp });
    dispatch(showSnackbarSuccess({ message: "Project created successfully" }));
  } catch (e) {
    dispatch(
      showSnackbarError({ message: "Sorry something happened, try again" })
    );
    dispatch({ type: CREATE_PROJECT_FAILURE, payload: e });
  }
};

export const getProjects = (smeId) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_PROJECT });
    const { data: resp } = await axios.get(
      `${API_ROOT}/smes/${smeId}/projects`
    );
    dispatch({ type: FETCH_PROJECT_SUCCESS, payload: normaliz(resp) });
  } catch (e) {
    dispatch({ type: FETCH_PROJECT_FAILURE, payload: "Sorry error occurred" });
  }
};

export const deleteProject = (id) => async (dispatch) => {
  try {
    await axios.delete(`${API_ROOT}/projects/${id}`);
    dispatch({ type: DELETE_PROJECT_SUCCESS, payload: id });
    dispatch(showSnackbarSuccess({ message: "Project deleted successfully" }));
  } catch (e) {
    dispatch(showSnackbarError({ message: "Sorry error occurred, try again" }));
    dispatch({
      type: DELETE_PROJECT_FAILURE,
      payload: "Sorry error occurred, try again",
    });
  }
};

export const completeProject = (id) => async (dispatch) => {
  try {
    const { data } = await axios.patch(`${API_ROOT}/projects/${id}`, {
      isCompleted: true,
    });
    dispatch({ type: UPDATE_PROJECT_SUCCESS, payload: data });
    dispatch(
      showSnackbarSuccess({
        message: "Project updated to completed successfully",
      })
    );
  } catch (e) {
    dispatch(showSnackbarError({ message: "Sorry error occurred, try again" }));
    dispatch({
      type: UPDATE_PROJECT_FAILURE,
      payload: "Sorry error occurred,try again",
    });
  }
};

export const editProject = (id, data) => async (dispatch) => {
  try {
    const { data: resp } = await axios.patch(
      `${API_ROOT}/projects/${id}`,
      data
    );
    dispatch({ type: UPDATE_PROJECT_SUCCESS, payload: resp.data });
    dispatch(showSnackbarSuccess({ message: "Project updated  successfully" }));
  } catch (e) {
    dispatch(showSnackbarError({ message: "Sorry error occurred, try again" }));
    dispatch({ type: UPDATE_PROJECT_FAILURE, payload: e });
  }
};
