import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { getUser } from "./routes/ducks/reducer";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Routes from "./routes";
import store, { persistor } from "./store";
import "./assets/styles/scss/bootstrap/bootstrap.scss";
import "./App.scss";
import "./assets/styles/scss/style.scss";

import { showSnackbarSuccess } from "./modules/Snackbar/reducer";

const user = store.getState().auth.user;
// const bc = new BroadcastChannel("sync"); // Disable offline sync for safari

function App() {
  // bc.onmessage = ({ data }) =>
  //   store.dispatch(showSnackbarSuccess({ message: data }));

  useEffect(() => {
    !user && store.dispatch(getUser());
  }, []);
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <Routes />
        </Router>
        <Toaster />
      </PersistGate>
    </Provider>
  );
}

export default App;
