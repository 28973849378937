import styled, { css } from 'styled-components';

export const colors = {
  error: '#FF647C',
  success: '#3FDA98',
  warning: '#FDC300;',
  info: '#35CAE2;'
};

const types = {
  success: css`
    border: 1px solid ${colors['success']};
    background-color: ${colors['success']};
  `,
  warning: css`
    border: 1px solid ${colors['warning']};
    background-color: ${colors['warning']};
  `,
  error: css`
    border: 1px solid ${colors['error']};
    background-color: ${colors['error']};
  `,
  info: css`
    border: 1px solid ${colors['info']};
    background-color: ${colors['info']};
  `
};

const position = {
  topLeft: css`
    top: 30px;
    left: 30px;
  `,
  topRight: css`
    top: 30px;
    right: 30px;
  `,
  bottomLeft: css`
    bottom: 30px;
    left: 30px;
  `,
  bottomRight: css`
    bottom: 30px;
    right: 30px;
  `
};

export const Container = styled.div`
  height: 50px;
  min-width: 443px;
  font-family: Sofia Pro;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
`;

export const Wrapper = styled.div`
  padding-top: 3%;
  z-index: 2000;
  position: fixed;
  display: ${props => (props.open ? 'flex' : 'none')};
  ${props => props.position && position[props.position]};
  @media print {
    z-index: -2000;
    display: none !important;
  }
`;

export const IconContainer = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  ${props => props.success && types['success']};
  ${props => props.error && types['error']};
  ${props => props.warning && types['warning']};
  ${props => props.info && types['info']};
`;
