import axios from "axios";
import {
  FETCH_WORKLOG,
  FETCH_WORKLOG_SUCCESS,
  FETCH_WORKLOG_FAILURE,
  CREATE_WORKLOG_FAILURE,
  // CREATE_WORKLOG_SUCCESS,
  FETCH_REVIEWS_SUCCESS,
  FETCH_REVIEWS_FAILURE,
  FETCH_WORKLOG_ANALYSIS,
} from "./types";
import { API_ROOT } from "../../../helpers/constants";
import { normaliz, normalizReviews } from "./normalizr";
import {
  showSnackbarWarning,
  showSnackbarSuccess,
  showSnackbarError,
} from "../../Snackbar/reducer";
import db from "../../../DB/setup";
import { isEmpty } from "../../../helpers/utils";

export const createWorklog = (data, isOnline, userId) => async (dispatch) => {
  try {
    if (!isEmpty(data?.mood)) {
      if (!isOnline) {
        const record = {
          data,
          url: `${API_ROOT}/worklogs`,
        };
        await db.table("outBox").add(record);
        return dispatch(
          showSnackbarWarning({
            message: "Your worklog has been saved for offline use",
          })
        );
      }

      dispatch({ type: FETCH_WORKLOG });
      await axios.post(`${API_ROOT}/worklogs`, data);
      dispatch(getWorklogs(userId, data.smeId));
      // dispatch({ type: CREATE_WORKLOG_SUCCESS, payload: resp.data });
      dispatch(
        showSnackbarSuccess({
          message: "Your worklog has be created successfully",
        })
      );
    } else {
      dispatch(
        showSnackbarError({
          message: "Please indicate how you feeling",
        })
      );
    }
  } catch (e) {
    dispatch({ type: CREATE_WORKLOG_FAILURE, payload: e });
    dispatch(
      showSnackbarError({ message: "Sorry error occurred, Please try again" })
    );
  }
};

export const getWorklogs = (employeeId, smeId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_WORKLOG });
    const { data: resp } = await axios.get(
      `${API_ROOT}/smes/${smeId}/employees/${employeeId}/worklogs`
    );
    dispatch({ type: FETCH_WORKLOG_SUCCESS, payload: normaliz(resp) });
  } catch (e) {
    dispatch(
      showSnackbarError({
        message: "Sorry error occurred when fetching records, Please try again",
      })
    );
    dispatch({ type: FETCH_WORKLOG_FAILURE, payload: e });
  }
};

export const fetchWorklogsAnalysis =
  (employeeId, smeId) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_WORKLOG });
      const { data: resp } = await axios.get(
        `${API_ROOT}/smes/${smeId}/employees/${employeeId}/worklogs`
      );
      dispatch({ type: FETCH_WORKLOG_ANALYSIS, payload: resp.data });
    } catch (e) {
      dispatch({ type: FETCH_WORKLOG_FAILURE, payload: e });
    }
  };

export const reviewWorklog = (data) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_WORKLOG });
    await axios.post(`${API_ROOT}/reviews`, data);
    dispatch(
      showSnackbarSuccess({
        message: "Your worklog has been reviewed  successfully",
      })
    );
  } catch (e) {
    dispatch(
      showSnackbarError({ message: "Sorry error occurred, Please try again" })
    );
    dispatch({ type: FETCH_WORKLOG_FAILURE, payload: e });
  }
};

export const getReview = (id) => async (dispatch) => {
  try {
    const { data: resp } = await axios.get(`${API_ROOT}/reviews/${id}`);
    dispatch({
      type: FETCH_REVIEWS_SUCCESS,
      payload: normalizReviews([resp.data]),
    });
  } catch (e) {
    dispatch({ type: FETCH_REVIEWS_FAILURE, payload: e });
  }
};

export const sendToSkillnav = (message) => async (dispatch) => {
  try {
    await axios.post(`${API_ROOT}/skillnav-feedback`, message);
  } catch (e) {
    dispatch({ type: FETCH_REVIEWS_FAILURE, payload: e });
  }
};
