import axios from 'axios';
import { API_ROOT } from '../../../helpers/constants';
import { normaliz } from './normalizr';

export const FETCH_NOTIFICATION_SUCCESS = 'FETCH_NOTIFICATION_SUCCESS';
export const FETCH_NOTIFICATION_FAILURE = 'FETCH_NOTIFICATION_FAILURE';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE';

export const getNotification = userId => async dispatch => {
  try {
    const { data } = await axios.get(`${API_ROOT}/users/${userId}/notifications`);
    dispatch({ type: FETCH_NOTIFICATION_SUCCESS, payload: normaliz(data.data) });
  } catch (e) {
    dispatch({ type: FETCH_NOTIFICATION_FAILURE, payload: e });
  }
};

export const updateNotification = id => async dispatch => {
  try {
    const { data } = await axios.patch(`${API_ROOT}/notifications/${id}`, { isRead: true });
    dispatch({ type: UPDATE_NOTIFICATION_SUCCESS, payload: data.data });
  } catch (e) {
    dispatch({ type: UPDATE_NOTIFICATION_FAILURE, payload: e });
  }
};
