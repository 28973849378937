import React, { Fragment, useEffect, useState } from "react";
import * as classes from "classnames";

import {
  useExpanded,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
  useRowSelect,
} from "react-table";
import { DefaultColumnFilter } from "./filters";

import {
  Button,
  Col,
  CustomInput,
  Row,
  Table,
  FormControl,
} from "react-bootstrap";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { BiTrash } from "react-icons/all";
// Create a default prop getter
const defaultPropGetter = () => ({});

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, onChange, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          type="checkbox"
          className="row-selector"
          ref={resolvedRef}
          {...{ ...rest, onChange }}
        />
      </>
    );
  }
);

/**
 * DataTable
 * @param columns
 * @param data
 * @param [className]
 * @param [defaultFilter]
 * @param getHeaderProps
 * @param getColumnProps
 * @param getRowProps
 * @param getCellProps
 * @param renderRowSubComponent
 * @param [rowSelectable]
 * @param updateMyData
 * @param skipPageReset
 * @param [showHead]
 * @param [size]
 * @param [pagination]
 * @param [searchable]
 * @param [onRowClick]
 * @returns {JSX.Element}
 * @constructor
 */
const DataTable = ({
  columns,
  data,
  className = "",
  defaultFilter = "",
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  subRows = false,
  renderRowSubComponent,
  rowSelectable = false,
  updateMyData,
  skipPageReset,
  showHead = true,
  size = 10,
  pagination = false,
  searchable = false,
  cellPlaceholder = null,
  setSelectedRows,
  selectedRows,
  onRowClick = () => {},
}) => {
  // Create an editable cell renderer
  /**
   *
   * @param initialValue
   * @param index
   * @param id
   * @param updateMyData // This is a custom function that we supplied to our table instance
   * @returns {JSX.Element}
   * @constructor
   */
  const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData,
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    const onChange = (e) => {
      setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateMyData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return <input value={value} onChange={onChange} onBlur={onBlur} />;
  };

  // Set our editable cell renderer as the default Cell renderer
  const defaultColumn = {
    Filter: DefaultColumnFilter,
    // Cell: EditableCell,
  };

  const {
    allColumns,

    rows,
    state,

    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    getToggleHideAllColumnsProps,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0, pageSize: size },
      // defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({
            getToggleAllRowsSelectedProps,
            getToggleAllPageRowsSelectedProps,
          }) => {
            console.log({ ...getToggleAllPageRowsSelectedProps() });
            return (
              <div className="text-center">
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                />
              </div>
            );
          },
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div className="text-center">
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  // useEffect(() => {
  //   if (selectedFlatRows !== selectedRows) {
  //     // setSelectedRows(selectedFlatRows)
  //
  //     selectedRows = selectedFlatRows
  //   }
  // }, [ selectedFlatRows ])

  let pagesLength = pageOptions.length;
  let dataPresent = data.length > 1;
  let rowSelected = selectedFlatRows.length > 0;

  const [filterInput, setFilterInput] = useState("");
  const [filter, setFilter] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [filterByHeader, setFilterByHeader] = useState("");

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <ArrowDownward fontSize={6} className={"ml-3 text-primary"} />
      ) : (
        <ArrowUpward className={"ml-3 text-primary"} />
      )
    ) : (
      ""
    );
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  // Update the state when input changes
  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("name.title", value); // Update the show.name filter. Now our table will filter and show only the rows which have a matching value
    setFilterInput(value);
  };

  const handleFilterByChange = (e) => {
    const value = e.target.value;
    // setFilterBy(value);
    // columns.filter(({ accessor }, c) => accessor === value)
    // setFilterByHeader(columns[0]['Header'])
  };

  let paginationLinks = [];
  // console.log(columns);
  if (pagesLength > 1 && pagesLength <= 5) {
    for (let p = 0; p < pagesLength; p++) {
      paginationLinks.push(
        <Button
          size="sm"
          primary
          className={classes("round primary px-2 mx-1", {
            active: p === pageIndex,
          })}
          color=""
          onClick={() => gotoPage(p)}
        >
          {p + 1}
        </Button>
      );
    }
  }

  let cellProps = (cell) => {
    // Return an array of prop objects and react-table will merge them appropriately

    return cell.getCellProps([
      {
        className: cell.column.className,
        style: cell.column.style,
      },
      getColumnProps(cell.column),
      getCellProps(cell),
    ]);
  };

  const renderCell = (cell) => {
    // console.log({ cell, cc : cell.column, cv : cell.value });
    if (cell.column.id !== "actions" && cell.column.id !== "selection") {
      if (cell.value !== undefined && cell.value !== null) {
        return cell.render("Cell");
      } else if (cellPlaceholder) {
        return cellPlaceholder;
      }
      return null;
    }
    return cell.render("Cell");
  };

  const handleRowClick = ({ target }, row) => {
    if (!target.classList.contains("row-selector")) {
      onRowClick(row);
    } else {
      if (setSelectedRows) {
        setSelectedRows(selectedFlatRows);
      }
    }
  };

  return (
    <Fragment>
      {/*<Row className={'justify-content-start w-100'} style={{ textAlign : 'center' }}>
        <div className='col-auto'>
          <input
            value={filterInput}
            onChange={handleFilterChange}
            placeholder={`Search name`}
          />
        </div>
        <div className={'col-auto'}>
          <select name="filterBy" id="filterBy" onChange={handleFilterByChange}>
            {
              allColumns.filter(({ accessor }, ac) => {
                console.log(allColumns[ac]);
                return typeof accessor === 'string'
              })
                .map(({ Header, accessor, id }, c) => {
                  return (
                    <option value={id}>{Header}</option>
                  )
                })
            }
          </select>
        </div>
        <Col sm={3} className={'position-relative'}>
          <div className={'position-absolute d-none'}>
            <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All

            {allColumns.map(column => {
              return (
                <div key={column.id}>
                  <label>
                    <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                    {column.render('Header')}
                  </label>
                </div>
              )
            })}
          </div>
        </Col>
      </Row>*/}
      <Table hover className={className} {...getTableProps()}>
        {(dataPresent || (!dataPresent && showHead)) && (
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: column.className,
                        style: column.style,
                      },
                      getColumnProps(column),
                      getHeaderProps(column),
                    ])}
                  >
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    {/*<Filter column={column}/>*/}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <tr
                  className={"cursor-pointer"}
                  onClick={(e) => {
                    handleRowClick(e, row);
                  }}
                >
                  {row.cells.map((cell) => {
                    return <td {...cellProps(cell)}>{renderCell(cell)}</td>;
                  })}
                </tr>
                {subRows && row.isExpanded && (
                  <tr className={"expanded"}>
                    <td
                      className={classes({
                        "border-bottom-0 border-top-0": row.isExpanded,
                      })}
                      colSpan={visibleColumns.length}
                    >
                      {renderRowSubComponent(row)}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </Table>

      {pagination === true && dataPresent && (
        <Row
          className={"justify-content-end  mx-0 w-100 bg-white"}
          style={{ textAlign: "center" }}
        >
          <Col sm={6}>
            <Row>
              {rowSelected && (
                <>
                  <div className="col-auto d-flex border-right">
                    <p className={"my-auto"}>
                      {Object.keys(selectedRowIds).length} Selected{" "}
                    </p>
                    <Button
                      className={""}
                      size="sm"
                      color="primary"
                      variant={"link-primary"}
                      onClick={() => {}}
                    >
                      <BiTrash />
                    </Button>
                  </div>
                </>
              )}
              <div className={"col-auto border-right"}>
                <p className={"m-0 text-left"}>
                  Page <strong>{pagesLength > 0 ? pageIndex + 1 : 0}</strong> of{" "}
                  <strong>{pagesLength}</strong>
                </p>
              </div>
              <div className={"col-auto"}>
                <select
                  className={"form-control form-control-sm"}
                  id={"page-size-select"}
                  value={pageSize}
                  onChange={onChangeInSelect}
                >
                  {">"}
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </Row>
          </Col>
          <Col sm={6}>
            <div className={"row pagination-controls justify-content-end"}>
              {pagesLength > 1 && (
                <Col md={2} className={""}>
                  <input
                    className={"form-control form-control-sm"}
                    type="number"
                    min={1}
                    style={{ width: 70 }}
                    max={pageOptions.length}
                    value={pageIndex + 1}
                    defaultValue={pageIndex + 1}
                    onChange={onChangeInInput}
                  />
                </Col>
              )}

              <div className={"col-auto px-1"}>
                {pagesLength > 5 && (
                  <Button
                    size="sm"
                    color=""
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                  >
                    First
                  </Button>
                )}
                <Button
                  size="sm"
                  color=""
                  onClick={previousPage}
                  disabled={!canPreviousPage}
                >
                  <span className={"text-primary"}>{"<"}</span> Prev
                </Button>
              </div>

              <div className={"col-auto px-1"}>{paginationLinks}</div>

              <div className={"col-auto px-1"}>
                <Button
                  size="sm"
                  color=""
                  onClick={nextPage}
                  disabled={!canNextPage}
                >
                  Next <span className={"text-primary"}>{">"}</span>
                </Button>
                {pagesLength > 5 && (
                  <Button
                    size="sm"
                    color=""
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                  >
                    Last
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      )}
      <style global jsx>
        {`
          .pagination-controls {
            button {
              &,
              &.disabled,
              &[disabled] {
                margin-left: 2px;
                margin-right: 2px;
                background: #fff !important;
                border: none !important;
                padding: 5px;
                color: #000;
                transition: all 0.2s ease-in-out;
              }

              .chevron {
                color: #bf9050;
              }

              &.round {
                width: 25px;
                height: 25px;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;

                //&.active {
                //  width: 33px;
                //  height: 33px;
                //  padding: 8px;
                //}

                &:hover,
                &.active {
                  color: #fff;
                }

                &:hover {
                  background-color: #8b6518 !important;
                }

                &.active {
                  background: #bf9050 !important;
                }
              }
            }
          }
        `}
      </style>
    </Fragment>
  );
};

DataTable.propTypes = {};

export default DataTable;
