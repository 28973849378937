import { useEffect, useState } from "react";

function useNetwork() {
  const [isOnline, setNetwork] = useState(window.navigator.onLine);
  const [message, setMessage] = useState("");

  const handleOnline = (online) => {
    setNetwork(window.navigator.onLine);
    fakeReconnection();
  };

  const handleOffline = () => {
    setNetwork(window.navigator.onLine);
    setMessage("Connection lost");
    resetAnimation();
  };

  const fakeReconnection = () => {
    setMessage("Attempting to reconnect");

    setTimeout(() => {
      setMessage("Reconnected");
      resetAnimation();
    }, 2000);
  };

  const resetAnimation = () => {
    setTimeout(() => {
      setMessage("");
    }, 2000);
  };

  useEffect(() => {
    window.addEventListener("offline", handleOffline);

    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);

      window.removeEventListener("online", handleOnline);
    };
  });
  return {
    isOnline,
    message,
  };
}

export default useNetwork;
