import React from "react";
import { Button, Drawer } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ReactComponent as LoadingIcon } from "../images/spinner_icon.svg";

function DrawerWrapper ({
                          open,
                          onClose,
                          disableButton = false,
                          children,
                          formType,
                          loading,
                          title,
                          noFooter,
                          onSubmit,
                        }) {
  const classes = useStyles();
  return (
    <Drawer
      classes={{ paper : classes.paper }}
      open={open}
      anchor="right"
      onClose={onClose}>
      <h3 className={classes.title}>{title}</h3>
      <div className={classes.root}>{children}</div>
      {
        !noFooter && (
          <Row className={"justify-content-end my-3 mx-0"} style={{paddingLeft : 32, paddingRight : 32, }}>
            <Col className={"d-flex px-0 justify-content-end"}>
              <Button className={"mr-3"} variant="contained" onClick={onClose}>Cancel</Button>
              <Button
                color="secondary"
                variant="contained"
                disabled={loading || disableButton}
                onClick={() =>
                  onSubmit(formType === "create" ? "create" : "edit")
                }
              >
                {formType === "create" ? "Confirm" : "Update"}
                {loading && <LoadingIcon style={{ height : 20 }}/>}
              </Button>
            </Col>
          </Row>
        )
      }
    </Drawer>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: 600,

      "@media (max-width: 600px)": {
        width: "80%",
      },
    },
    root: {
      paddingLeft: 32,
      paddingRight: 32,
    },
    title: {
      paddingTop: 10,
      paddingLeft: 32,
      paddingBottom: 10,
      font: "Glacial",
      color: "#013271",
    },
  })
);

export default DrawerWrapper;
