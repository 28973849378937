import { connect } from 'react-redux';
import ProfileComponent from './ProfileComponent';
import { getMasteredBehaviors, requestBadge, getPracticedBehaviors } from './duck/actions';

const mapStateToProps = ({ profile: { masteredBehaviors = [], practiceBehaviors = [], loading } }) => {
  return {
    masteredBehaviors,
    practiceBehaviors,
    loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPracticedBehaviors: smeId => dispatch(getPracticedBehaviors(smeId)),
    getMasteredBehaviors: id => dispatch(getMasteredBehaviors(id)),
    requestBadge: (userId, skillId, managerId) => dispatch(requestBadge(userId, skillId, managerId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileComponent);
