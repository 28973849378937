import { connect } from 'react-redux';
import EmployeeComponent from '.';
import { getProjects, createProject, deleteProject, completeProject, editProject } from './duck/actions';
import { getEmployees } from '../employees/duck/actions';
import { getSme } from '../teams/duck/actions';

const mapStateToProps = ({ projects: { allIds, byId }, employees, smes }) => {
  return {
    projects: allIds.map(id => byId[id]),
    sme: smes.allIds.map(id => smes.byId[id])[0],
    employees: employees.allIds.map(id => employees.byId[id])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getProjects: data => dispatch(getProjects(data)),
    createProject: smeId => dispatch(createProject(smeId)),
    getEmployees: smeId => dispatch(getEmployees(smeId)),
    getSme: smeId => dispatch(getSme(smeId)),
    deleteProject: id => dispatch(deleteProject(id)),
    completeProject: id => dispatch(completeProject(id)),
    editProject: (id, data) => dispatch(editProject(id, data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeComponent);
