import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    paddingBottom: 10,
  },
  pos: {
    marginBottom: 12,
    paddingBottom: 10,
  },
});

export default function ContactInfo({ data = {} }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          variant="h5"
          component="h2"
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Contact Information
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Full name: {data && data.firstname} {data && data.lastname}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Job title: {data && data.jobTitle}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Email :{data && data.email}
        </Typography>

        <Typography className={classes.pos} color="textSecondary">
          Phone number : {data && data.contact}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Address : {data && data.address}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Date Started :{" "}
          {data && data.startDate && new Date(data.startDate).toDateString()}
        </Typography>
      </CardContent>
    </Card>
  );
}
