import React, { useState, useEffect } from "react";
import uuid from "uuid/v4";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import useOfflineOnline from "../../hooks/useOfflineStatus";
import AddWorklog from "../worklogs/AddWorklog";
import { getUserBehaviours } from "../skills/duck/actions";
import { getEmployee } from "../employees/duck/actions";
import { createWorklog } from "../worklogs/duck/actions";
import { Button } from "@material-ui/core";
import { ReactComponent as LoadingIcon } from "../../images/spinner_icon.svg";
import Axios from "axios";
import { showSnackbarSuccess } from "../Snackbar/reducer";
import { Col, Row } from "react-bootstrap";

const taskFieldId = uuid();
const initialState = { [taskFieldId]: {} };
const projectFieldId = uuid();
const initialProject = { [projectFieldId]: {} };

function WorklogOutside({ match = {}, isShiftHappens }) {
  const { smeId, employeeId, token } = match.params;

  Axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const [record, setRecord] = useState({ projects: [], tasks: [] });

  const [data, setData] = useState(initialState);
  const [projectData, setProjectData] = useState(initialProject);

  const [feedback, setFeedback] = useState("");

  const { isOnline } = useOfflineOnline();
  const behaviours = useSelector((state) =>
    state.behaviours.allIds.map((id) => state.behaviours.byId[id])
  );

  const loading = useSelector((state) => state.employees.loading);
  const isLoading = useSelector((state) => state.worklogs.loading);

  const user = useSelector((state) =>
      state.employees.allIds.map((id) => state.employees.byId[id])
    )[0] || {};

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserBehaviours(employeeId));
  }, [dispatch, employeeId]);

  useEffect(() => {
    dispatch(getEmployee(smeId, employeeId));
  }, [dispatch, employeeId, smeId]);

  function onSubmit(type) {
    const rep = {
      ...record,
      feedback,
      projects: record.projects.filter((pro) => Boolean(pro.log)), // Remove all projects and tasks with no log
      tasks: record.tasks.filter((task) => Boolean(task.log)),
      smeId: smeId,
    };
    dispatch(showSnackbarSuccess({
      message : "Great job! We received your worklog."
    }))
    return dispatch(createWorklog(rep, isOnline, smeId));
  }

  function onAddProject() {
    const id = uuid();
    const newState = { ...projectData, [id]: [] };
    setProjectData(newState);
  }

  function onAddRow() {
    const id = uuid();
    const newState = { ...data, [id]: [] };
    setData(newState);
  }

  const handleFeedbackFormatChange = (value) => {
    setFeedback(value)
  }

  const handleChange = ({ target }) => {
    if (target.name === "feedback") {
      return setRecord({
        ...record,
        feedback: target.value,
      });
    } else if (record.skills && record.skills.length) {
      return setRecord({
        ...record,
        skills: record.skills.concat(target.value),
      });
    } else {
      setRecord({
        ...record,
        skills: [target.value],
      });
    }
  };

  function onChange(id) {
    return function ({ target }) {
      setRecord({
        ...record,
        [target.name]: record[target.name].map((el, i) => {
          let found = i === id;
          if (found) {
            el = Object.assign(el, { ...found, log: target.value });
          }
          return el;
        }),
      });
    };
  }

  function onRemove(id) {
    // if (Object.values(data).length === 1) return;
    return function () {
      const newState = { ...data };
      delete newState[id];
      setData(newState);
    };
  }

  function onRemoveProject(id) {
    // if (Object.values(projectData).length === 1) return;
    return function () {
      const newState = { ...projectData };
      delete newState[id];
      setProjectData(newState);
    };
  }

  function handleSelect() {
    return function ({ id }, { name }) {
      if (name === "projects") {
        return setRecord({
          ...record,
          projects: [...record.projects, { projectId: id }],
        });
      }
      return setRecord({
        ...record,
        tasks: [...record.tasks, { taskId: id }],
      });
    };
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div style={{ margin: "0 auto", width: 600, paddingTop: 20 }}>
      <AddWorklog
        isShiftHappens={isShiftHappens}
        onRemoveProject={onRemoveProject}
        projectData={projectData}
        onAddProject={onAddProject}
        tasks={user?.tasks}
        projects={user?.projects}
        fields={data}
        handleSelect={handleSelect}
        feedback={feedback}
        onFeedbackFormatChange={handleFeedbackFormatChange}
        onAddRow={onAddRow}
        onRemove={onRemove}
        handleChange={handleChange}
        behaviours={behaviours}
        onChange={onChange}
      />
      <Row className=" justify-content-end">
        <Col>
          <Button style={{ marginRight: 10 }} variant="contained">Cancel</Button>
        </Col>
        <Col>
          <Button color="secondary" variant="contained" disabled={loading} onClick={() => onSubmit()}>
            Confirm {isLoading && <LoadingIcon style={{ height: 20 }} />}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default WorklogOutside;
