import axios from "axios";
import {
  CREATE_TASK,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_FAILURE,
  FETCH_TASK_SUCCESS,
  FETCH_TASK_FAILURE,
  EDIT_TASK_FAILURE,
  EDIT_TASK_SUCCESS,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAILURE,
} from "./types";
import { API_ROOT } from "../../../helpers/constants";
import { normaliz } from "./normalizr";
import { showSnackbarSuccess, showSnackbarError } from "../../Snackbar/reducer";
import { getEmployee } from "../../employees/duck/actions";

export const createTask = (data, isEmployee) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_TASK });
    const { data: resp } = await axios.post(`${API_ROOT}/tasks`, data);
    if (isEmployee) {
      dispatch(getEmployee(data.smeId, data.members[0]));
      return dispatch(
        showSnackbarSuccess({ message: "Task created successfully" })
      );
    }
    dispatch({ type: CREATE_TASK_SUCCESS, payload: resp.data });
    dispatch(showSnackbarSuccess({ message: "Task created successfully" }));
  } catch (e) {
    dispatch(
      showSnackbarError({ message: "Sorry error occurred, Please try again" })
    );
    dispatch({ type: CREATE_TASK_FAILURE, payload: e });
  }
};

export const getTasks = (smeId) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_TASK });
    const { data: resp } = await axios.get(`${API_ROOT}/smes/${smeId}/tasks`);
    dispatch({ type: FETCH_TASK_SUCCESS, payload: normaliz(resp) });
  } catch (e) {
    dispatch({ type: FETCH_TASK_FAILURE });
  }
};

export const editTask = (id, data) => async (dispatch) => {
  try {
    const { data: resp } = await axios.patch(`${API_ROOT}/tasks/${id}`, data);
    dispatch({ type: EDIT_TASK_SUCCESS, payload: resp.data });
    dispatch(showSnackbarSuccess({ message: "Task updated successfully" }));
  } catch (e) {
    dispatch(
      showSnackbarError({ message: "Sorry error occurred, Please try again" })
    );
    dispatch({ type: EDIT_TASK_FAILURE, payload: e });
  }
};

export const deleteTask = (id) => async (dispatch) => {
  try {
    await axios.delete(`${API_ROOT}/tasks/${id}`);
    dispatch({ type: DELETE_TASK_SUCCESS, payload: id });
    dispatch(showSnackbarSuccess({ message: "Task created successfully" }));
  } catch (e) {
    dispatch(
      showSnackbarError({ message: "Sorry error occurred, Please try again" })
    );
    dispatch({ type: DELETE_TASK_FAILURE, payload: e });
  }
};
