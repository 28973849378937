import React from "react";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

export function SkillLibraryDialog({
  onCopyBehaviours,
  loading,
  setOpen,
  open,
  history,
}) {
  return (
    <Dialog open={open}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle>Assign Behaviours</DialogTitle>
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => setOpen(false)}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent>
        <DialogContentText>
          Before automatically assigning Skills Library behaviours to the
          workers in this team, would you like to review or update the skills in
          the Skills Library?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => history.push("/skills")}
          color="secondary"
          disabled={loading}
        >
          Yes, take me to Skills Library
        </Button>
        <Button onClick={onCopyBehaviours} disabled={loading} color="primary">
          No, I am ready
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withRouter(SkillLibraryDialog);
