import React from 'react';
import styled, { css } from 'styled-components';

const COLORS = {
  default: '#d9dfea',
  focused: '#35cae2',
  greyText: '#959595'
};

const Wrapper = styled.div`
  ${props =>
    props.big &&
    css`
      position: relative;
      display: flex;
      width: 431px;
      height: 100px;
      margin-bottom: 16px;
      padding-top: 14px;
      padding-left: 8px;
      background: #fff;
      border-radius: 3px;
      border: ${props => {
        return props.checked ? `1px solid ${COLORS.focused}` : `1px solid ${COLORS.default}`;
      }};

      @media (max-width: 600px) {
        width: 100%;
      }

      &:hover {
        border: 1px solid ${COLORS.focused};
      }
    `}

  .container {
    display: ${props => (props.big ? 'flex' : 'block')};
    position: relative;
    padding-left: 25px;
    padding-right: ${props => props.big && '25px'};
    margin-top: ${props => !props.big && '12px'};
    cursor: pointer;
    font-size: 12px;
    color: ${COLORS.greyText};
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border: 2px solid ${COLORS.focused};
    border-radius: 3px;
    background-color: #ffffff;
  }

  .container input:checked ~ .checkmark {
    background-color: ${COLORS.focused};
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const Checkbox = props => {
  return (
    <Wrapper {...props}>
      <label className="container">
        {props.label}
        <input type="checkbox" onChange={props.onChange} value={props.value} {...props} />
        <span className="checkmark" />
      </label>
    </Wrapper>
  );
};
