import React from "react";

import TextInput from "../components/TextInput";

import {
  requiredRule,
  minLengthRule,
  maxLengthRule,
  passwordMatchRule,
} from "./validate";

/**
 * creates and returns object representation of form field
 *
 * @param {string} label - label to show with the form input
 * @param {string} name - input name
 * @param {string} type - input type
 * @param {string} defaultValue - default value for the input
 */
function createFormFieldConfig(label, name, type, defaultValue = "") {
  const isPasswordField = type === "password";

  return {
    renderInput: (
      handleChange,
      value,
      isValid,
      error,
      key,
      hidePassword,
      toggleHidePassword,
      values
    ) => {
      const fieldValue =
        type === "date" ? new Date(values[name]) : values[name];
      return (
        <TextInput
          fullWidth
          key={key}
          name={name}
          type={type}
          label={label}
          error={!isValid}
          value={fieldValue || value}
          onChange={handleChange}
          hidden={isPasswordField && hidePassword}
          toggleVisibility={
            isPasswordField ? toggleHidePassword : () => undefined
          }
          errorMessage={error}
        />
      );
    },
    label,
    value: defaultValue,
    valid: false,
    errorMessage: "",
    touched: false,
  };
}

// object representation of signup form
export const signupForm = {
  firstname: {
    ...createFormFieldConfig("First name*", "firstname", "text"),
    validationRules: [
      requiredRule("First name"),
      minLengthRule("First name", 3),
    ],
  },
  lastname: {
    ...createFormFieldConfig("Last name*", "lastname", "text"),
    validationRules: [requiredRule("Last name"), minLengthRule("Last name", 3)],
  },
  email: {
    ...createFormFieldConfig("Email Address*", "email", "email"),
    validationRules: [requiredRule("Email")],
  },
  // contact: {
  //   ...createFormFieldConfig("Contact", "contact", "number"),
  //   validationRules: [minLengthRule("Contact", 10)],
  // },
  password: {
    ...createFormFieldConfig("Password*", "password", "password"),
    validationRules: [requiredRule("Password"), minLengthRule("Password", 6)],
  },
  // startDate: {
  //   ...createFormFieldConfig("Start date*", "startDate", "date"),
  //   validationRules: [requiredRule("Start Date")],
  // },
};

export const teamForm = {
  jobTitle: {
    ...createFormFieldConfig("Job title", "jobTitle", "text"),
    validationRules: [minLengthRule("Job title", 3)],
  },
  level: {
    ...createFormFieldConfig("Level", "level", "text"),
    validationRules: [minLengthRule("Level", 3)],
  },
  address: {
    ...createFormFieldConfig("Address", "address", "text"),
    validationRules: [minLengthRule("Address", 3)],
  },
};
