// /* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { getNotification, updateNotification, } from "../modules/notifications/duck/actions";
import { authorized, unauthorized } from "./routeList";
import useNetwork from "../hooks/useOfflineStatus";
import OfflineWorker from "../workers/offlineSync.worker";
import { setup as DBSetup } from "../DB/setup";
import AuthRoute from "./AuthRoute";
import Snackbar from "../modules/Snackbar";

const offlineWorker = new OfflineWorker();

function logout () {
  window.localStorage.clear();
  window.location.href = "/";
}

function Routes ({ history, user, notifications, getNotification, updateNotification, }) {
  const { isOnline } = useNetwork();

  useEffect(() => {
    user && isOnline && offlineWorker.postMessage({ cmd : "ready", user });
  }, [ isOnline, user ]);

  useEffect(() => {
    DBSetup();
  }, []);

  useEffect(() => {
    user && getNotification(user.id);
  }, [ getNotification, user ]);

  return (
    <Fragment>
      <Snackbar/>
      {!user && (
        <Fragment>
          <Switch>
            {
              unauthorized.map((route) => (
                <Route key={route.path} path={route.path} component={route.component}/>
              ))
            }
            <Redirect to="/login"/>
          </Switch>
        </Fragment>
      )}
      {
        user && (
          <Switch>
            {authorized.map((route) => (
              <AuthRoute
                updateNotification={updateNotification}
                notifications={notifications}
                logout={logout}
                user={user}
                path={route.path}
                history={history}
                key={route.path}
                {...route}
              />
            ))}
            <Redirect to="/"/>
          </Switch>
        )
      }
    </Fragment>
  );
}

Routes.propTypes = {
  history : PropTypes.object,
};

const mapStateToProps = ({ auth : { user }, notification }) => ({
  user,
  notifications : notification.allIds
    .map((id) => notification.byId[id])
    .filter((id) => id.isRead === false),
});
export default connect(mapStateToProps, {
  getNotification,
  updateNotification,
})(withRouter(Routes));
