export const CREATE_BEHAVIOUR = "CREATE_BEHAVIOUR";
export const FETCH_BEHAVIOUR = "FETCH_BEHAVIOUR";
export const CREATE_BEHAVIOUR_SUCCESS = "CREATE_BEHAVIOUR_SUCCESS";
export const CREATE_BEHAVIOUR_FAILURE = "CREATE_BEHAVIOUR_FAILURE";

export const UPLOAD_BEHAVIOUR_SUCCESS = "UPLOAD_BEHAVIOUR_SUCCESS";
export const UPLOAD_BEHAVIOUR_FAILURE = "UPLOAD_BEHAVIOUR_FAILURE";

export const FETCH_BEHAVIOUR_SUCCESS = "FETCH_BEHAVIOUR_SUCCESS";
export const FETCH_BEHAVIOUR_FAILURE = "UPLOAD_BEHAVIOUR_FAILURE";

export const DELETE_BEHAVIOUR_SUCCESS = "DELETE_BEHAVIOUR_SUCCESS";
export const DELETE_BEHAVIOUR_FAILURE = "DELETE_BEHAVIOUR_FAILURE";

export const GET_MASTERED_BEHAVIOURS = "GET_MASTERED_BEHAVIOURS";
export const GET_MASTERED_BEHAVIOURS_SUCCESS =
  "GET_MASTERED_BEHAVIOURS_SUCCESS";
export const GET_MASTERED_BEHAVIOURS_FAILURE =
  "GET_MASTERED_BEHAVIOURS_SUCCESS_FAILURE";

export const UPDATE_BEHAVIOUR = "UPDATE_BEHAVIOUR";
