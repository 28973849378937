import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { RiFileDownloadLine, RiUploadCloud2Line } from "react-icons/all";

function Upload({ file, onClick, closeDiaglog, open, onSubmit }) {
  return (
    <Modal centered show={open} onHide={closeDiaglog}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Workers</Modal.Title>
      </Modal.Header>
      <Modal.Body className={"p-3"}>
        <div>
          <h5 className={"mb-2"}> Here’s an easy way to add the workers on this team all at once. </h5>
          <p>1. Download our Excel template with the button on the left.</p>
          <p>
            2. Fill in your workers’ information, without changing any of the column headings in row 1. Save as a .xls,
            .xlsx., or .csv file
          </p>
          <p>3. Come back here and upload your file using the button on the right.</p>
        </div>
        {file ? (
          <h3 style={{ textAlign : 'center' }}>Your file is ready to be uploaded</h3>
        ) : (
          <div className={"row justify-content-around pt-3"}>
            <Col className={"d-flex p-0"}>
              <p className={"my-auto"}>
                <span className={"line-height-1"}>Step 1: </span>
                <DownloadLink className={"btn p-0 v-align-baseline btn-link-secondary"}
                              href={`${process.env.PUBLIC_URL}/assets/employee.xlsx`} download>
                  <RiFileDownloadLine/> Download a template
                </DownloadLink></p>

            </Col>
            <Col className={"d-flex px-0"}>
              <p className={"my-auto"}>
                <span className={"line-height-1"}>Step 2: </span>
                <Button
                  className={"px-2 py-1 text-medium v-align-baseline ml-2"}
                  onClick={onClick} variant="outline-secondary"> <RiUploadCloud2Line/> Upload your file
                </Button>
              </p>
            </Col>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className={"mb-3"}>
        <Button onClick={closeDiaglog} className="btn btn-outline">Cancel</Button>
        <Button onClick={() => onSubmit('upload')}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const DownloadLink = styled.a`
  color: #646466;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
`;

Upload.propTypes = {
  getInputProps: PropTypes.func,
  getRootProps: PropTypes.func,
  open: PropTypes.bool,
  closeDiaglog: PropTypes.func,
  onSubmit: PropTypes.func,
};
export default Upload;
