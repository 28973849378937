import { useState } from 'react';

function useLoading({ initialState = false }) {
  const [loading, setLoading] = useState(initialState);

  return {
    loading,
    setLoading,
  };
}

export default useLoading;
