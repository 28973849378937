import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import qs from "query-string";
import Alert from "@material-ui/lab/Alert";
import { responseBadge } from "../profile/duck/actions";

export function ResponseBadge({
  location,
  match,
  history,
  error,
  success,
  responseBadge,
}) {
  const classes = useStyles();
  const timer = useRef(null);
  const params = qs.parse(location.search);

  useEffect(() => {
    responseBadge(match.params.userId, match.params.skillId, {
      status: params.answer,
    });
  }, [
    location.search,
    match,
    match.params.skillId,
    match.params.token,
    match.params.userId,
    params.answer,
    responseBadge,
  ]);

  useEffect(() => {
    timer.current = setTimeout(() => history.push("/"), 5000);

    return () => clearTimeout(timer);
  }, [history, success]);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Alert severity="success">{`Thanks for response (${params.answer.toUpperCase()}), You will be redirected in 5 secs!`}</Alert>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  flex: {
    display: "flex",
    flexDirection: "column",
  },
  wrapper: {
    marginTop: theme.spacing(6),
    position: "relative",
  },
}));

const mapState = ({ auth }) => ({
  loading: auth.loading,
  error: auth.error,
  success: auth.emailVerified,
});
export default connect(mapState, { responseBadge })(ResponseBadge);
