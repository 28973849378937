import React from "react";
import Table from "mpharma-react-table";
import { makeStyles } from "@material-ui/core";

function BaseTable({
  handleRowClick = () => {},
  headers = [],
  data = [],
  action,
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {action}
      <div className={classes.table + "table table-hover"}>
        <Table
          handleRowClick={handleRowClick}
          headers={headers}
          rowsPerPageOptions={[25]}
          data={data}
          emptyMessageStyle={{
            left: "45%",
            position: "absolute",
            fontSize: 25,
            width: "80%",
            paddingTop: 0,
          }}
          emptyMessage={`No records available`}
          tableBodyStyle={{ maxHeight: "50vh  " }}
          tableStyle={{
            width: "100%",
            backgroundColor: "#fff",
            marginTop: 0,
            paddingTop: 0,
          }}
          hover
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    // paddingTop: '1%',
  },
  table: {
    // paddingTop: '2%',
  },
}));

export default BaseTable;
