export const FETCH_SKILLS = "FETCH_SKILLS";
export const ADD_SKILL_SUCCESS = "ADD_SKILL_SUCCESS";
export const ADD_SKILL_FAILURE = "ADD_SKILL_FAILURE";

export const FETCH_SKILLS_SUCCESS = "FETCH_SKILLS_SUCCESS";
export const FETCH_SKILLS_FAILURE = "FETCH_SKILLS_FAILURE";

export const DELETE_SKILL_SUCCESS = "DELETE_SKILL_SUCCESS";
export const DELETE_SKILL_FAILURE = "DELETE_SKILL_FAILURE";

export const UPDATE_SKILL_SUCCESSFUL = "UPDATE_SKILL_SUCCESSFUL";

export const FILTER_BEHAVIOURS = "FILTER_BEHAVIOURS";
export const RESET_FILTER = "RESET_FILTER";
