import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { closeSnackbar } from './reducer';
import Alert from '../../components/Alert';

export class SnackBar extends React.PureComponent {
  handleClose = () => {
    this.props.closeSnackbar();
  };

  render() {
    const { message, position, open, success, error, warning, style, action } = this.props;

    return (
      <Alert
        action={action}
        handleClose={this.handleClose}
        open={open}
        position={position}
        error={error}
        style={style}
        success={success}
        warning={warning}
        message={message}
      />
    );
  }
}

SnackBar.propTypes = {
  message: PropTypes.any,
  position: PropTypes.string,
  open: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  style: PropTypes.object,
};

const mapStateToProps = state => {
  const { snackbar } = state;
  return { ...snackbar };
};

export default connect(mapStateToProps, { closeSnackbar })(SnackBar);
