import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { images, cleanDate } from '../helpers/utils';
import styled from 'styled-components';
import { CardHeader } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '90%',
  },
  action: {
    float: 'right',
  },
});

export default function SimpleCard({ record, onClick, children }) {
  const classes = useStyles();
  const { name, avatar, employeesCount, lastUpdated } = record;
  return (
    <Card className={classes.root}>
      <div style={{ display: 'flex' }}>
        <Container>
          <img height="100px" src={avatar || images.smeLogo} alt={avatar || ''} />
          <CardContent onClick={() => onClick(record)}>
            <Typography variant="h5" component="h2">
              {name}
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
              Manager:
              <strong>{`${record.manager && record.manager.firstname} ${
                record.manager && record.manager.lastname
              }`}</strong>{' '}
            </Typography>
            <Typography variant="body2" component="h2">
              # of Workers: &nbsp;
              <span>
                <strong>{employeesCount}</strong>
              </span>
            </Typography>
            {lastUpdated && (
              <Typography variant="body2" component="h2">
                Last updated: &nbsp; <strong> {cleanDate(lastUpdated)}</strong>
              </Typography>
            )}
          </CardContent>
        </Container>
        <CardHeader style={{ marginLeft: '17%' }} action={children} />
      </div>
    </Card>
  );
}

const Container = styled.div`
  display: flex;
`;

Card.propTypes = {
  record: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.object,
};
