import React, { useState, useEffect } from "react";
import uuid from "uuid/v4";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import useOfflineOnline from "../../hooks/useOfflineStatus";
import AddWorklog from "../worklogs/AddWorklog";
import { getUserBehaviours } from "../skills/duck/actions";
import { getEmployee } from "../employees/duck/actions";
import { createWorklog } from "../worklogs/duck/actions";
import { Button } from "@material-ui/core";
import { ReactComponent as LoadingIcon } from "../../images/spinner_icon.svg";
import Axios from "axios";
import { showSnackbarSuccess } from "../Snackbar/reducer";
import { Col, Row } from "react-bootstrap";
import { responseBadge} from "../UserProfile/duck/actions";

import {getQueryParams} from "../../helpers/utils";

const taskFieldId = uuid();
const initialState = { [taskFieldId]: {} };
const projectFieldId = uuid();
const initialProject = { [projectFieldId]: {} };

function BadgeRequestOutside({ match = {}, isShiftHappens }) {
  const loading = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    let {userId, skillId, answer} = getQueryParams();
    dispatch(responseBadge(userId, skillId, answer));
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div style={{ margin: "0 auto", width: 600, paddingTop: 20 }}>
      <Row className=" justify-content-end">
        <Col>
          <p className={"text-center"}>Please wait while we are processing your request</p>
        </Col>
      </Row>
    </div>
  );
}

export default BadgeRequestOutside;
