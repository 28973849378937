import React, { Fragment, useEffect, useRef, useState } from "react";
import Table from "mpharma-react-table";
import Header from "../../components/Header";
import { behavioursHeaders, skillsHeaders } from "../../helpers/constants";
import useForm from "../../hooks/useForm";
import { headerText } from "../../helpers/text";
import NavigateTo from "../../components/NavigateTo";
import BehaviourDetails from "./BehaviourDetails";
import useExcel from "../../hooks/useExcel";
import UploadBehavioursModal from "../../components/UploadBehaviourModal";
import DrawerWrapper from "../../components/DrawerWrapper";
import Ellipsis from "../../components/Ellipsis";
import { deepOrange } from "@material-ui/core/colors";
import { useDispatch } from "react-redux";
import { Button } from "@material-ui/core";
import useLoading from "../../hooks/useLoading";
import Loading from "../../components/Loading";
import SkillLibraryDialog from "../../components/SkillLibraryDialog";
import { copyBehaviours } from "./duck/actions";
import AddSkill from "../skills/AddSkills";

function Behaviour ({
                      user,
                      match,
                      getBehaviours,
                      updateBehaviour,
                      skills,
                      getSkills,
                      behaviours,
                      createBehaviour,
                      deleteBehaviour,
                      uploadBehaviours,
                      createSkill,
                      getSme,
                      sme,
                      employees = [],
                      getEmployees,
                      loading : isLoading,
                      assignSkill,
                      history,
                      isShiftHappens,
                    }) {
  const [ openDrawer, setopenDrawer ] = useState(false);
  const { values, setValues, handleChange } = useForm({});
  const [ selected, setSelected ] = useState(null);
  const { handleFileChange, data : records, setData } = useExcel();
  const [ open, setOpen ] = useState(false);
  const [ formType, setFormType ] = useState("create");
  const { loading, setLoading } = useLoading(false);
  const [ openDialog, setOpenDialog ] = useState(false);
  const [ format, setFormat ] = useState("");
  const fileInput = useRef();
  const dispatch = useDispatch();

  const [ description, setDescription ] = useState("");


  const moreActions = [
    { name : "Edit", action : "edit" },
    { name : "Delete", action : "delete", className : "danger" },
  ];

  console.log(user?.id);

  useEffect(() => {
    getSkills(user?.employers[0]?.id, user?.id);
  }, [ getSkills, user, skills.length ]);

  useEffect(() => {
    getBehaviours(match.params.id || user?.id);
    getEmployees(match.params.id);
    getSme(match.params.id);
  }, [ getBehaviours, getEmployees, getSme, match.params.id, user, user.id ]);

  async function onSubmit (type) {
    switch (type) {
      case "create":
        let link = values.links ? values.links.split(",") : [];
        setLoading(true);
        return createBehaviour(
          {
            ...values,
            description,
            links : link,
            smeId : match.params.id,
          },
          setLoading,
          setopenDrawer
        );

      case "edit":
        const {
          title,
          description : desc,
          id,
          sme : { id : smeId },
        } = values;
        setopenDrawer(false);
        return await updateBehaviour({ title, description : desc, id, smeId });
      case "upload":
        setOpen(false);
        const cleanedData = records.map((rec) => ({
          title : rec["Skill name (required)"],
          smeId : match.params.id,
          description : rec["Description (required)"],
          links : rec["Links to learning resources (optional)"]
            ? [ rec["Links to learning resources (optional)"] ]
            : [],
          customLevel : rec["Skills level (optional)"],
        }));
        createSkill(cleanedData);
        setOpen(false);
        return setData(null);
      default:
        return;
    }
  }

  function onSelect (action, data) {
    switch (action) {
      case "edit":
        setopenDrawer(true);
        setFormType("edit");
        return setValues(data);
      case "delete":
        let confirm = window.confirm(
          "Are you sure you want to delete this behaviour?"
        );
        return confirm ? deleteBehaviour(data.id) : null;
      default:
        return;
    }
  }

  function getDefaultSkills () {
    return behaviours.reduce((acc, rec) => {
      return [ ...new Set(acc.concat(acc, rec.skills)) ];
    }, []);
  }

  function openUpload () {
    return fileInput.current.click();
  }

  function onRowSelect (data) {
    setSelected(data);
  }

  function addBehaviour () {
    setFormType("create");
    setopenDrawer(true);
  }

  function handleSelect (value) {
    setValues({ ...values, title : value && value.label });
  }

  function onDismiss () {
    setSelected(null);
  }

  const onFormatChange = (v) => {
    setFormat(v);
  };

  const onDescriptionChange = (value) => {
    setDescription(value);

    // handleChange({ target : { name : "description", value } })
  };

  const onDescriptionBlur = (previousRange, source, editor) => {
    console.log("from on tasks desc", {
      editor,
      val : editor.getContents(),
      html : editor.getHTML(),
      text : editor.getText()
    });
    handleChange({ target : { name : "description", value : editor.getHTML() } })
  };

  function assignSelfSkill (skillId) {
    assignSkill(
      {
        userId : user.id,
        smeId : match.params.id,
        skillId,
      },
      setLoading,
      onDismiss
    );
  }

  if (isLoading) {
    return <Loading/>;
  }

  function onCopyBehaviours () {
    return dispatch(copyBehaviours(match.params.id, setLoading, setOpenDialog));
  }

  return (
    <div className="container-fluid">
      <Header
        header={`${sme && sme.name} ${isShiftHappens ? "Values" : "Behaviours"}`}
        subHeader={headerText["behaviour"].subText}
        navigate={
          <div style={{ display : "flex", width : "50vw" }}>
            <NavigateTo name="Go back to People" link={`/`}/>
            <NavigateTo
              name={isShiftHappens ? "Next: Assign Helpers and Detractors" : "Next: Assign Tasks"}
              link={`/teams/${match.params.id}/tasks`}
              left={"9%"}
            />
          </div>
        }
        action={
          <Fragment>
            <Button
              variant="contained"
              onClick={addBehaviour}
              style={{ marginRight : 10 }}
            >
              {isShiftHappens ? "Add value" : "Add behaviour"}
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor : deepOrange[500], color : "white" }}
              onClick={() => setOpenDialog(true)}
            >
              {isShiftHappens ? "Use Values Library" : "Use Skills Library"}
            </Button>
          </Fragment>
        }
      />

      <input
        onChange={handleFileChange}
        ref={fileInput}
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        id="my_file"
        onClick={openUpload}
        style={{ display : "none" }}
      />

      <div className="row">
        <div className="col-md-12" style={{ marginTop : "2%" }}>
          <Table
            handleRowClick={onRowSelect}
            headers={behavioursHeaders}
            rowsPerPageOptions={[ 25, 50 ]}
            data={behaviours}
            tableBodyStyle={{ maxHeight : "50vh" }}
            emptyMessageStyle={{
              left : "45%",
              position : "absolute",
              fontSize : 25,
              width : "80%",
              paddingTop : 20,
            }}
            emptyMessage={!behaviours.length ? "No Behaviours  available" : ""}
            tableStyle={{
              width : "100%",
              backgroundColor : "#fff",
              marginTop : 20,
            }}
            hover
            renderIcon={(data) => (
              <Ellipsis
                as="td"
                onSelect={onSelect}
                data={data}
                moreActions={moreActions}
              />
            )}
          />
        </div>
      </div>
      <DrawerWrapper formType={formType} size="500px" loading={loading}
                     onSubmit={onSubmit} title="Add Behaviour" open={openDrawer}
                     onClose={() => setopenDrawer(false)}>
        <AddSkill
          onDescriptionChange={onDescriptionChange}
          onDescriptionBlur={onDescriptionBlur}
          // onFormatChange={onFormatChange}
          description={description}
          handleChange={handleChange}
          values={values}
        />
      </DrawerWrapper>
      <DrawerWrapper open={Boolean(selected)} onClose={() => setSelected(null)} noFooter>
        <BehaviourDetails
          assignSelfSkill={assignSelfSkill}
          loading={loading}
          employees={employees}
          {...selected}
        />
      </DrawerWrapper>
      <UploadBehavioursModal
        onSubmit={onSubmit}
        onClick={openUpload}
        open={open}
        file={records}
        closeDiaglog={() => setOpen(false)}
      />

      <SkillLibraryDialog
        onCopyBehaviours={onCopyBehaviours}
        loading={loading}
        open={openDialog}
        setOpen={setOpenDialog}
      />
    </div>
  );
}

export default Behaviour;
