import axios from "axios";
import {
  CREATE_EMPLOYEE,
  CREATE_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEES,
  FETCH_EMPLOYEES_FAILURE,
  FETCH_EMPLOYEES_SUCCESS,
  UPLOAD_EMPLOYEES_SUCCESS,
  UPLOAD_EMPLOYEE_FAILURE,
  EDIT_EMPLOYEE_SUCCESS,
  EDIT_EMPLOYEE_FAILURE,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_FAILURE,
} from "./types";
import { API_ROOT } from "../../../helpers/constants";
import { normalizeSmes } from "./normalizr";
import { showSnackbarSuccess, showSnackbarError } from "../../Snackbar/reducer";
import toast from "react-hot-toast";

export const createEmployee = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_EMPLOYEE });
    const { data: resp } = await axios.post(
      `${API_ROOT}/auth/signup/employee`,
      data
    );
    dispatch({ type: CREATE_EMPLOYEE_SUCCESS, payload: resp });
    dispatch(
      showSnackbarSuccess({
        message: `${data.firstname} has been created successfully`,
      })
    );
  } catch (e) {
    dispatch({ type: CREATE_EMPLOYEE_FAILURE, payload: e });
    dispatch(
      showSnackbarError({
        message: "Sorry error occurred, try again ",
      })
    );
  }
};

export const getEmployees = (smeId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_EMPLOYEES });
    const { data: resp } = await axios.get(
      `${API_ROOT}/smes/${smeId}/employees`
    );
    dispatch({ type: FETCH_EMPLOYEES_SUCCESS, payload: normalizeSmes(resp) });
  } catch (e) {
    dispatch({ type: FETCH_EMPLOYEES_FAILURE, payload: e });
  }
};

export const getEmployee = (smeId, id) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_EMPLOYEES });
    const { data } = await axios.get(
      `${API_ROOT}/smes/${smeId}/employees/${id}`
    );
    dispatch({
      type: FETCH_EMPLOYEES_SUCCESS,
      payload: normalizeSmes([data]),
    });
  } catch (e) {
    showSnackbarError({
      message: "Sorry error occurred when fetching records, Please try again",
    });
    dispatch({ type: FETCH_EMPLOYEES_FAILURE, payload: e });
  }
};

export const uploadEmployees = (data) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_EMPLOYEES });
    const { data: resp } = await axios.post(
      `${API_ROOT}/auth/signup/employee/bulk`,
      data
    );
    dispatch({
      type: UPLOAD_EMPLOYEES_SUCCESS,
      payload: normalizeSmes(resp.data),
    });
    dispatch(
      showSnackbarSuccess({ message: "Employees uploaded successfully" })
    );
    window.location.reload();
  } catch (e) {
    dispatch(
      showSnackbarError({
        message: "Sorry error occurred, Email is already taken",
      })
    );
    dispatch({
      type: UPLOAD_EMPLOYEE_FAILURE,
      payload: "Error occurred",
    });
  }
};

export const updateEmployee = (id, rec) => async (dispatch) => {
  try {
    const { level, ...rest } = rec;

    if (level) {
      await axios.patch(`${API_ROOT}/users/${id}/level`, {
        employerId: rest.employerId,
        level,
      });
    }
    const { data } = await axios.patch(`${API_ROOT}/users/${id}`, rest);
    dispatch({ type: EDIT_EMPLOYEE_SUCCESS, payload: data });
    window.location.reload();
    dispatch(showSnackbarSuccess({ message: "Employee updated successfully" }));
  } catch (e) {
    window.location.reload();
    dispatch(
      showSnackbarError({
        message: "Sorry error occurred, try again ",
      })
    );
    dispatch({ type: EDIT_EMPLOYEE_FAILURE, payload: e });
  }
};

export const deleteEmployee = (id) => async (dispatch) => {
  try {
    await axios.delete(`${API_ROOT}/users/${id}`);
    dispatch({ type: DELETE_EMPLOYEE_SUCCESS, payload: id });
    dispatch(showSnackbarSuccess({ message: "Employee deleted successfully" }));
  } catch (e) {
    dispatch(
      showSnackbarError({
        message:
          (e && e.response && e.response.data.error.message) ||
          "Sorry error occurred, try again ",
      })
    );
    dispatch({ type: DELETE_EMPLOYEE_FAILURE, payload: e });
  }
};

export const getEmployeeMetrics = (smeId, employeeId) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${API_ROOT}/smes/${smeId}/employees/${employeeId}/progress`
    );
    console.log(data);
  } catch (error) {
    throw error;
  }
};

export const subscribeEmail = async (data, history) => {
  try {
    await axios.post(`${API_ROOT}/subscription`, data);
    history.push({ search: "" });
    toast.success("Subscribed successfully");
  } catch (error) {
    toast.error("Sorry something happened, try again");
  }
};
