import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Backdrop } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { verifyEmail } from '../../routes/ducks/reducer';

export function VerifyEmail({ loading, match, history, error, success, verifyEmail }) {
  const classes = useStyles();
  const timer = useRef(null);

  useEffect(() => {
    verifyEmail(match.params.token);
  }, [match.params.token, verifyEmail]);

  useEffect(() => {
    if (success) {
      timer.current = setTimeout(() => history.push('/login'), 5000);
    }
    return () => clearTimeout(timer);
  }, [history, success]);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        {loading && (
          <Backdrop className={classes.backdrop} open>
            <div className={classes.flex}>
              <CircularProgress style={{ marginLeft: '40%', color: '#fff', marginBottom: 10 }} color="inherit" />
              <h2 style={{ color: '#fff' }}>Verifying your email address</h2>
            </div>
          </Backdrop>
        )}

        {success && <Alert severity="success">Your email has be verified, you will be redirected in 5 secs!</Alert>}
        {error && <Alert severity="error">Sorry error occurred verifying your email — Please try again!</Alert>}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    marginTop: theme.spacing(6),
    position: 'relative',
  },
}));

const mapState = ({ auth }) => ({
  loading: auth.loading,
  error: auth.error,
  success: auth.emailVerified,
});
export default connect(mapState, { verifyEmail })(VerifyEmail);
