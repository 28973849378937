import React, { Fragment } from "react";
import Select from "../../components/BaseSelect";
import { Input, Label } from "../teams/AddTeam";
import { TextInputLabel } from "../../components/TextInput/TextInput.style";
import ReactQuill from "react-quill";

function AddTask ({
                    handleChange,
                    employees = [],
                    handleSelect,
                    values = {},
                    description,
                    onDescriptionChange,
                    onDescriptionBlur
                  }) {
  return (
    <Fragment>
      <Input
        name="title"
        value={values.title || ""}
        onChange={handleChange}
        label="Job Task*"
        placeholder="eg: Prepare work sheet every day"
      />

      {
        Boolean(employees.length) && (
          <Fragment>
            <Label>Workers assigned to this task (at least one)</Label>
            <Select
              style={{ width : "90%" }}
              value={values.members || ""}
              getOptionLabel={(option) => {
                return `${option.firstname} ${option.lastname}`
              }
              }
              getOptionValue={(option) => option.id}
              options={[ { id : 'all', firstname : 'Assign task', lastname : ' to everyone' }, ...employees ]}
              name="members"
              onChange={(value) => {
                if (Array.isArray(value) && (value.filter(item => item.id === 'all').length > 0)) {
                  handleSelect(employees);
                } else {
                  handleSelect(value);
                }
              }
              }
              placeholder="Choose workers on this team to assign task"
              isMulti
            />
          </Fragment>
        )}
      {/*<div style={{ marginTop: 12 }}>*/}
      {/*  <Input*/}
      {/*    name="description"*/}
      {/*    onChange={handleChange}*/}
      {/*    value={values.description || ""}*/}
      {/*    label="Description of job task (examples, additional resources)*"*/}
      {/*    placeholder="Fill with text and links"*/}
      {/*  />*/}
      {/*</div>*/}

      <TextInputLabel className={"mb-1"}>Description</TextInputLabel>
      <ReactQuill
        theme="snow"
        name="description"
        value={description || ""}
        onChange={onDescriptionChange}
        onBlur={onDescriptionBlur}
        placeholder={"Tell more about the project"}
      />

      {/*<div style={{ marginTop: 12 }}>*/}
      {/*  <Input*/}
      {/*    name="optional_url"*/}
      {/*    onChange={handleChange}*/}
      {/*    value={values.optional_url || ""}*/}
      {/*    label="Optional Links"*/}
      {/*    placeholder="http://optional-url.com"*/}
      {/*  />*/}
      {/*</div>*/}
    </Fragment>
  );
}

export default AddTask;
