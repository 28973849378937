import {
  GET_MASTERED_BEHAVIOURS,
  GET_MASTERED_BEHAVIOURS_SUCCESS,
  GET_MASTERED_BEHAVIOURS_FAILURE,
} from '../../behaviors/duck/types';
import { GET_PRACTICE_BEHAVIOURS_SUCCESS, GET_PRACTICE_BEHAVIOURS_FAILURE, RESCIND_SKILL_BADGE } from './types';

const INITIAL_STATE = {
  masteredBehaviors: [],
  practiceBehaviors: [],
  fetchPracticeFailed: null,
  fetchMasteredFailed: null,
  loading: false,
};

function profile(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_MASTERED_BEHAVIOURS:
      return { ...state, loading: true };
    case RESCIND_SKILL_BADGE:
      return { ...state, masteredBehaviors: state.masteredBehaviors.filter(bh => bh.id !== payload) };
    case GET_MASTERED_BEHAVIOURS_SUCCESS:
      return { ...state, loading: false, masteredBehaviors: payload };
    case GET_PRACTICE_BEHAVIOURS_SUCCESS:
      return { ...state, loading: false, practiceBehaviors: payload };
    case GET_PRACTICE_BEHAVIOURS_FAILURE:
      return { ...state, loading: false, fetchPracticeFailed: true };
    case GET_MASTERED_BEHAVIOURS_FAILURE:
      return { ...state, loading: false, fetchMasteredFailed: true };
    default:
      return state;
  }
}

export default profile;
