import { combineReducers } from "redux";
import {
  CREATE_SME,
  CREATE_SME_SUCCESS,
  FETCH_SMES,
  FETCH_SMES_FAILURE,
  FETCH_SMES_SUCCESS,
  EDIT_SME_SUCCESS,
  EDIT_SME_FAILURE,
  DELETE_SME_SUCCESS,
  DELETE_SME_FAILURE,
} from "./types";

function byId(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_SME_SUCCESS:
      return { ...state, [payload.id]: payload };
    case FETCH_SMES_SUCCESS:
      return payload.entities.smes || {};
    case EDIT_SME_SUCCESS:
      return {
        ...state,
        [payload.id]: payload,
      };

    default:
      return state;
  }
}

function allIds(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_SME_SUCCESS:
      return [payload.id, ...state];
    case FETCH_SMES_SUCCESS:
      return payload.result.reverse() || [];
    case DELETE_SME_SUCCESS:
      return state.filter((id) => id !== payload);
    default:
      return state;
  }
}

function loading(state = false, action) {
  const { type } = action;
  switch (type) {
    case CREATE_SME:
    case FETCH_SMES:
      return true;
    case FETCH_SMES_SUCCESS:
    case FETCH_SMES_FAILURE:
      return false;
    default:
      return state;
  }
}

function error(state = null, action) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SMES_FAILURE:
    case EDIT_SME_FAILURE:
    case DELETE_SME_FAILURE:
      return payload;
    default:
      return state;
  }
}

export default combineReducers({
  loading,
  byId,
  allIds,
  error,
});
