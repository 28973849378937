import React from "react";
import Rating from "react-star-rating-component";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import { Badge, Col, Row } from "react-bootstrap";
import { Checkbox } from "../../components/Checkbox";
import { Label } from "../teams/AddTeam";
import CreatableSelect from "react-select/creatable";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import RenderHTML from "../../components/RenderHTML";
import * as classnames from "classnames";

function Details({
  userId,
  setNotify,
  handleClose,
  handleSelect,
  taskLogs = [],
  projectLogs = [],
  behaviours = [],
  tasks = [],
  skills = [],
  values,
  projects = [],
  user = {},
  review = {},
  handleChange,
  feedback,
  setShow,
  isEmployee,
  mood = "Happy",
  feeling = "Happy",
}) {
  const classes = useStyles();

  function prepareOptions(type) {
    return type.reduce(
      (acc, rec) =>
        acc.concat({
          label: rec.title || rec.name || rec,
          value: rec.id || rec,
        }),
      []
    );
  }

  const cleanProjects = prepareOptions(projectLogs);
  const cleanTasks = prepareOptions(taskLogs);
  const cleanBehaviours = prepareOptions(skills);

  return (
    <div>
      {mood !== null ? (
        <Row>
          <Col>
            <div className={classnames("")}>
              <h4 className="text-primary mt-2">
                You were in {mood === "Apathy" ? " an" : "a"}{" "}
                <span className={"font-weight-600"}>
                  {mood.toLocaleLowerCase()}
                </span>{" "}
                <img
                  height={20}
                  src={`/img/emojis/${mood}.png`}
                  alt={`${mood.toLocaleLowerCase()} Emoji`}
                />{" "}
                mood{" "}
              </h4>
            </div>
          </Col>
        </Row>
      ) : null}

      <div style={{ marginTop: "3%" }}>
        <h4>
          <span className={"font-weight-600"}>Tasks worked on:</span>{" "}
          {taskLogs.length < 1 && <span className={"mutes"}>None</span>}
        </h4>
        {taskLogs.map((log, i) => (
          <div key={i}>
            <Badge variant="primary">{log.title}</Badge>
            <ul>
              <li style={{ paddingLeft: "4%" }}>{log.log}</li>
            </ul>
          </div>
        ))}
      </div>
      <div style={{ marginTop: "2%" }}>
        <h4>
          <span className={"font-weight-600"}>Projects worked on:</span>{" "}
          {projectLogs.length < 1 && <span className={"mutes"}>None</span>}
        </h4>
        {projectLogs.map((log, i) => (
          <div key={i}>
            <Badge variant="info">{log.title}</Badge>
            <ul>
              <li style={{ paddingLeft: "4%" }}>{log.log}</li>
            </ul>
          </div>
        ))}
      </div>

      <div style={{ marginTop: "3%" }}>
        <h4 className={"font-weight-600"}>Employee's feedback:</h4>
        <RenderHTML html={feedback} />
      </div>

      <div style={{ marginTop: "3%", marginBottom: "2%" }}>
        <h4>
          <span className={"font-weight-600"}>Behaviours mastered</span>{" "}
          {skills.length < 1 && <span className={"mutes"}>: None</span>}
        </h4>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
          {skills.map((bh, i) => (
            <div key={i} style={{ marginTop: 10 }}>
              <Checkbox key={i} disabled defaultChecked label={bh.title} />
            </div>
          ))}
        </div>
      </div>
      {review && review.rating ? (
        <div style={{ marginTop: "3%" }}>
          <h4
            style={{
              textAlign: "center",
              textDecoration: "underline",
              paddingBottom: 10,
            }}
          >
            Manager's feedback
          </h4>
          <Row>
            <Col sm={12}>
              <h5 className={"font-weight-600"}>Things you did well</h5>
              <p style={{ paddingLeft: 5 }}>{review && review.feedback} </p>
            </Col>

            <Col sm={12} className={"mt-3"}>
              <h5 className={"font-weight-600"}>
                Things that needs improvement
              </h5>
              <ul style={{ paddingLeft: 5 }}>
                {review &&
                  review &&
                  review.improvement &&
                  review.improvement.map((imp, i) => (
                    <li key={i}>{imp}</li>
                  ))}{" "}
              </ul>
            </Col>
          </Row>
          <div style={{ display: "flex", paddingTop: 20 }}>
            <div>Overall rating:</div>{" "}
            <Rating
              editing={false}
              name="rating"
              starCount={5}
              value={review && review.rating}
            />
          </div>
        </div>
      ) : (
        (!isEmployee || user.id !== userId) && (
          <div style={{ marginTop: "3%" }}>
            <p>Rate worklog </p>
            <div style={{ marginTop: "4%", width: "100%" }}>
              <Rating
                style={{ width: "100%" }}
                value={Number(values.rating) || ""}
                onStarClick={handleChange}
                name="rating"
                starCount={5}
              />
            </div>

            <div
              style={{ width: "100%", marginBottom: 15 }}
              className="multiselect"
            >
              <Label>What went well</Label>
              <CreatableSelect
                isClearable
                options={
                  [...cleanProjects, ...cleanTasks, ...cleanBehaviours] || []
                }
                name="feedback"
                onChange={handleSelect}
                placeholder="What went well"
                className="multiselect-dropdown"
                isMulti
              />
            </div>
            <div
              style={{ width: "100%", marginBottom: 15 }}
              className="multiselect"
            >
              <Label>What needs improvement</Label>
              <CreatableSelect
                isClearable
                options={
                  [...cleanProjects, ...cleanTasks, ...cleanBehaviours] || []
                }
                name="improvement"
                onChange={handleSelect}
                placeholder="What needs improvement"
                className="multiselect-dropdown"
                isMulti
              />
            </div>
          </div>
        )
      )}
      {user.id !== userId && !isEmployee && (
        <Tooltip title="Add" placement="left" aria-label="add">
          <Fab className={classes.fab} color="secondary" aria-label="edit">
            <EditIcon onClick={handleClose} />
          </Fab>
        </Tooltip>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    fab: {
      float: "right",
    },
  })
);

export default Details;
