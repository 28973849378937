import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import { images } from "../helpers/utils";
import { Avatar, Link } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import { APP_ROLES } from "../routes/roleList";
import InternetSign from "./InternetSign";
import { Col, Row } from "react-bootstrap";

export default function UserMenu ({
                                    isEmployee, links, isEmployeeAndManager, user = {}, history,
                                    notifications = [],
                                    updateNotification,
                                    logout,
                                    roles,
                                  }) {
  const classes = useStyles();
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ anchor, setAnchor ] = useState(null);
  const [ mobileMoreAnchorEl, setMobileMoreAnchorEl ] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  async function handleNotifyClick (data) {
    setAnchor(null);
    const url = data.url.split("/");
    const id = url[url.length - 1];
    await updateNotification(data.id);
    switch (data.type) {
      case "Review":
        if (isEmployeeAndManager || isEmployee) {
          return history.push(`/?type=reviews&id=${id}`);
        }
        break;
      case "Project":
        if (isEmployeeAndManager || isEmployee) {
          return history.push(`/?type=project&id=${id}`);
        }
        break;
      default:
        if (!isEmployee && data.type === "Worklog") {
          return history.push(
            `/teams/${data.smeId}/employees/${data.creatorId}?type=${data.type}&id=${id}`
          );
        }
        return history.push("/notifications");
    }
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleClose () {
    setAnchor(null);
  }

  function handleNotificationOpen (e) {
    setAnchor(e.currentTarget);
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical : "bottom", horizontal : "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical : "top", horizontal : "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {isEmployee && (

        <li
          className="py-3 MuiMenuItem-gutters MuiListItem-gutters border-bottom"
          tabIndex="0" role="menuitem" aria-disabled="false">
          <Row>
            <Col className={"d-flex"}>
              <Avatar style={{ fontSize : ".8rem" }}
                      onClick={() => history.push(`/profile/${user.id}`)}
                      className={classes.orange + " mr-3 m-auto"}>
                {user.firstname[0]}{user.lastname[0]}</Avatar>

            </Col>
          </Row>
          <Row>
            <Col>
              <p className="MuiTypography-root font-weight-600 cursor-pointer text-center MuiTypography-body1"
                 onClick={() => history.push(`/profile/${user.id}`)}>{user.firstname} {user.lastname}</p>
              {
                isEmployee && (
                <p className="MuiTypography-root text-center text-small">({user.employers?.[0]?.name} )</p>
              )
              }
              <p className={"text-center"}>
                {
                  isEmployeeAndManager ? (
                    <>
                      <span className={"cursor-pointer"} style={{ paddingRight : 10 }} onClick={() => history.push("/teams")}>Manager</span>|
                      <span className={"cursor-pointer"} style={{ paddingLeft : 10 }} onClick={() => history.push("/worklogs")}>Employee</span>
                    </>
                  ) : (
                    roles.map((role) =>
                      role.id ? APP_ROLES[role.id].label : APP_ROLES[role].label
                    )
                  )
                }
              </p>
            </Col>
          </Row>
        </li>

      )}


      {(isEmployeeAndManager || isEmployee) && (
        <MenuItem onClick={() => {
          setAnchor(null);
          history.push({ search : "email=true" });
        }}>
          Manage Skillnav Emails
        </MenuItem>
      )}

      <MenuItem onClick={logout}>Log out</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl} anchorOrigin={{ vertical : "top", horizontal : "right" }}
      id={mobileMenuId} keepMounted transformOrigin={{ vertical : "top", horizontal : "right" }}
      open={isMobileMenuOpen} onClose={handleMobileMenuClose}>
      <MenuItem>
        <IconButton onClick={handleNotificationOpen} aria-label=" new notifications" color="inherit">
          <Badge badgeContent={notifications.length} color="secondary">
            <NotificationsIcon/>
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>

      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton aria-label="account of current user" aria-controls="primary-search-account-menu"
                    aria-haspopup="true" color="inherit">
          <AccountCircle/>
        </IconButton>
        <p>Account</p>
      </MenuItem>
      {isEmployee && (
        <MenuItem onClick={() => history.push(`/me-profile/${user.id}`)}>
          <IconButton
            aria-label="account of current user" aria-controls="primary-search-account-menu"
            aria-haspopup="true" color="inherit">
            Profile
          </IconButton>
        </MenuItem>
      )}
      <MenuItem>
        <IconButton>
          <InternetSign/>
        </IconButton>
        <p>Online/Offline</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar color="default" position="static">
        <Toolbar variant="dense">
          <Typography onClick={() => history.push("/")} className={classes.title} variant="h6" noWrap>
            <img width="100" src={images.logo} alt="Skillnav"/>
          </Typography>
          <Typography className={classes.title}>
            {
              links.map((nav, i) => (
                <Link key={i} href="" onClick={(e) => {
                  e.preventDefault();
                  history.push(nav.link);
                }}
                      style={{ paddingLeft : 20, fontWeight : "bolder" }} color="secondary">
                  {nav.name}
                </Link>
              ))
            }
          </Typography>

          <div className={classes.grow}/>
          <div className={classes.sectionDesktop}>
            <IconButton>
              <InternetSign/>
            </IconButton>
            <IconButton onClick={handleNotificationOpen} aria-label="notifications" color="inherit">
              <Badge badgeContent={notifications.length} color="secondary">
                <NotificationsIcon style={{ fill : deepOrange[500] }}/>
              </Badge>
            </IconButton>
            <IconButton
              edge="end" aria-label="account of current user" aria-controls={menuId}
              aria-haspopup="true" onClick={handleProfileMenuOpen} color="inherit">
              <Avatar className={classes.orange}>{`${user.firstname[0]}`}</Avatar>
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton aria-label="show more" aria-controls={mobileMenuId} aria-haspopup="true"
                        onClick={handleMobileMenuOpen} color="inherit">
              <MoreIcon/>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}

      <Menu
        variant="menu" id="simple-menu" anchorEl={anchor}
        keepMounted open={Boolean(anchor) && Boolean(notifications.length)}
        onClose={handleClose}>
        {
          notifications.map((notify, i) => (
            <MenuItem key={i} onClick={() => handleNotifyClick(notify)}>
              {notify.message}
            </MenuItem>
          ))
        }
        {
          notifications.length > 0 && (
            <MenuItem onClick={() => history.push("/notifications")} style={{ color : "orange", paddingLeft : "25%" }}>
              View all notifications
            </MenuItem>
          )}
      </Menu>
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    grow : {
      flexGrow : 1,
    },
    menuButton : {
      marginRight : theme.spacing(2),
    },
    title : {
      cursor : "pointer",
      display : "none",
      [theme.breakpoints.up("sm")] : {
        display : "block",
      },
    },
    orange : {
      color : theme.palette.getContrastText(deepOrange[500]),
      backgroundColor : deepOrange[500],
    },
    sectionDesktop : {
      display : "none",
      [theme.breakpoints.up("md")] : {
        display : "flex",
      },
    },
    sectionMobile : {
      display : "flex",
      [theme.breakpoints.up("md")] : {
        display : "none",
      },
    },
  })
);
