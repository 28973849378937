import React from 'react';
import PropTypes from 'prop-types';
import { TextInputGroup, Input, TextInputLabel } from './TextInput.style';

const TextInput = (props) =>  {
  const {
    label,
    type = 'text',
    placeholder,
    name,
    fullWidth,
    error,
    errorMessage,
    testId,
    onChange,
    value,
    hidden,
    toggleVisibility,
    disabled,
    renderAction,
    groupStyle,
    defaultValue,
    ref,
    className,
    ...rest
  } = props;
  return (
    <TextInputGroup style={groupStyle} className={className}>
      <TextInputLabel className="mb-1">{label}</TextInputLabel>
      <Input
        
        ref={ref}
        type={type}
        placeholder={placeholder}
        name={name}
        fullWidth={fullWidth}
        error={error}
        errorMessage={errorMessage}
        data-testid={testId}
        defaultValue={defaultValue}
        onChange={onChange}
        value={value}
        hidden={hidden}
        toggleVisibility={toggleVisibility}
        disabled={disabled}
        renderAction={renderAction}
        {...rest}
      />
      {props.children}
    </TextInputGroup>
  );
}

export default TextInput;

TextInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  /** Apply styling to the input */
  style: PropTypes.object,
  name: PropTypes.string,
  width: PropTypes.number,
  fullWidth: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  testId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  hidden: PropTypes.bool,
  toggleVisibility: PropTypes.func,
  renderAction: PropTypes.func,
  /** Apply styling to the text input group */
  groupStyle: PropTypes.object,
};

TextInput.defaultProps = {
  hidden: true,
  errorMessage: 'This field cannot be empty',
};
