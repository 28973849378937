export const industries = [
  {
    label: 'Agriculture, forestry, fishing, hunting',
    value: 'Agriculture, forestry, fishing, hunting'
  },
  { label: 'Construction, real estate, property management', value: 'Construction, real estate, property management' },
  { label: 'Education', value: 'Education' },
  { label: 'Finance, investing, and insurance', value: 'Finance, investing, and insurance' },
  { label: 'Food and hospitality services', value: 'Food and hospitality services' },
  { label: 'Government (public administration and defense)', value: 'Government (public administration and defense)' },
  { label: 'Health care', value: 'Health care' },
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Media, arts, entertainment, recreation', value: 'Media, arts, entertainment, recreation' },
  { label: 'Mining, quarrying, oil and gas', value: 'Mining, quarrying, oil and gas' },
  { label: 'Retail and wholesale trade', value: 'Retail and wholesale trade' },
  {
    label: 'Tech & professional services (inc. consulting, HR, marketing)',
    value: 'Tech & professional services (inc. consulting, HR, marketing)'
  },
  { label: 'Transportation, logistics, storage', value: 'Transportation, logistics, storage' },
  {
    label: 'Utilities and energy (solar, gas, AC, water, waste)',
    value: 'Utilities and energy (solar, gas, AC, water, waste)'
  },
  { label: 'Other', value: 'Other' }
];

export const companyStrength = [
  {
    label: '1 to 5',
    value: '1 to 5'
  },
  { label: '6 to 10', value: '6 to 10' },
  { label: '11 to 20', value: '11 to 20' },
  { label: '21 to 30', value: '21 to 30' },
  { label: '31 to 50', value: '31 to 50' },
  { label: '51 to 100', value: '51 to 100' },
  { label: '101 to 200', value: '101 to 200' },
  { label: '201 to 500', value: '201 to 500' },
  { label: 'More than 500', value: 'More than 500' }
];


export const skillsSelect = [
 {label:'Most assigned', value:'most_assigned'},
 {label:'Most practiced', value:'most_practiced'},
 {label:"Most mastered", value:'most_mastered'},
 {label:'Most recent', value:'most_recent'}
]

export const libraryFilter = [{
  label: 'In my org', value:'in_my_org'
}, {
  label:'At my level', value:'at_my_level'
},{label:'In my industry', value:'in_my_industry'},
 {label:'In my region', value:'in_my_region'},
 {label:'In Skillnav', value:'in_skillnav'}
 ]
