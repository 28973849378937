// /* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment, useEffect } from "react";
import Header from "../../components/Header";
import useForm from "../../hooks/useForm";
import AddTeam from "./AddTeam";
import ViewMode from "../../components/ViewMode";
import Table from "mpharma-react-table";
import RenderGrid from "../../components/RenderGrid";
import { adminView, smeHeaders } from "../../helpers/constants";
import { headerText } from "../../helpers/text";
import TableLoader from "../../components/TableLoader";
import EmptyState from "../../components/EmptyModal";
import DrawerWrapper from "../../components/DrawerWrapper";
import Ellipsis from "../../components/Ellipsis";
import { deepOrange } from "@material-ui/core/colors";
import { Button } from "@material-ui/core";
import Loading from "../../components/Loading";
import { useForm as useFormNew } from "react-hook-form";
import useLoading from "../../hooks/useLoading";
import { Hint } from "../../components/Hint";

function TeamComponent ({ user, history, isOwner, createSme, smes, getOwnerSmes, loading, editSme,
                          deleteSme, superAdmin, match, location, isShiftHappens, }) {

  console.log({user});
  const moreActions = [
    { name : "View Workers", action : "employees" },
    { name : "View Behaviour", action : "behaviour" },
    { name : "View Task", action : "task" },
    { name : "View Projects", action : "project" },
    { name : "Edit Team", action : "edit" },
    { name : "Delete Team", action : "delete", className : "danger" },
  ];

  const [ open, setOpen ] = useState(false);
  const [ isEmpty, setIsEmpty ] = useState(false);
  const [ hidePassword, toggleHidePassword ] = useState(true);
  const [ formType, setformTpe ] = useState("create");
  const [ tab, setTab ] = useState("table");
  const { handleChange, values, setValues } = useForm({});
  const [ errors, setErrors ] = useState({});
  const { loading : isLoading, setLoading } = useLoading(false);
  const [ timezone, setTimeZone ] = useState({});

  useEffect(() => {
    getOwnerSmes(user.id, user.roles, match.params.id);
    // eslint-disable
  }, [ getOwnerSmes, match.params.id, user.id, user.roles ]);

  useEffect(() => {
    if (smes.length < 1) {
      setIsEmpty(true);
    }
  }, [ smes.length ]);

  function onDismiss () {
    if (!smes.length) {
      setIsEmpty(true);
    }
    setErrors({});
    setOpen(false);
  }

  function handleSelect ({ value }, { name }) {
    setValues({ ...values, [name] : value });
  }

  function onTabSelect (tab) {
    setTab(tab);
  }

  function onSubmit (type) {
    if (checkErrors()) return;
    switch (type) {
      case "create": {
        const {
          firstname,
          lastname,
          username,
          startDate,
          email,
          contact,
          address,
          password,
          ...rest
        } = values;

        const manager = {
          firstname,
          lastname,
          username,
          startDate,
          email,
          contact,
          address,
          password,
        };

        const data = {
          ...rest,
          manager,
          ownerId : user.id,
          sector : "Retail",
        };

        return createSme(data, setLoading, setOpen);
      }
      case "edit": {
        const { name, id } = values;
        return editSme(id, { name });
      }
      default: {
        return;
      }
    }
  }

  function checkErrors () {
    const error = [ "name", "firstname", "email", "password", "lastname" ].reduce(
      (acc, obj) => {
        if (!values[obj]) {
          return {
            ...acc,
            [obj] : true,
          };
        }
        return {};
      },
      {}
    );
    setErrors(error);
    return Boolean(Object.keys(error).length);
  }

  function onEdit (data) {
    setformTpe("edit");
    setOpen(true);
    setValues(data);
  }

  function onSelect (action, data) {
    switch (action) {
      case "employees":
        return history.push(
          superAdmin
            ? `teams/${data.id}/employees`
            : `teams/${data.id}/employees`
        );
      case "behaviour":
        return history.push(`teams/${data.id}/behaviours`);
      case "task":
        return history.push(`teams/${data.id}/tasks`);
      case "project":
        return history.push(`teams/${data.id}/projects`);
      case "edit":
        setValues({});
        return onEdit(data);
      case "delete":
        let confirm = window.confirm(
          "Are you sure you want to delete this team?"
        );
        return confirm ? deleteSme(data.id) : null;
      default:
        return;
    }
  }

  function handleRowClick (data) {
    if (superAdmin) {
      history.push(`/admin/${data.id}/teams`);
    } else {
      history.push(`/teams/${data.id}/employees`);
    }
  }

  function onSelectChange (value, action) {
    setValues({ ...values, [action.name] : value });
  }

  if (loading) {
    return <Loading/>;
  }

  function addTeam () {
    setValues({});
    setOpen(true);
  }

  function handleTimeZoneSelect (val) {
    console.log({ val });
    setTimeZone(val);
  }

  return (
    <div>
      <Header
        action={
          isOwner && (
            <Fragment>
              <Button
                variant="contained"
                style={{ backgroundColor : deepOrange[500], color : "white" }}
                onClick={addTeam}
              >
                Add Team
              </Button>
            </Fragment>
          )
        }
        // header={headerText["team"].header}
        header={user?.organization?.name || headerText["team"].header}
        headerHint={!isShiftHappens ? <Hint title={""} direction={"right"} minWidth={370} messages={headerText["team"].hint}/> : null}
        // subHeader={!isShiftHappens && headerText["team"].subText}
      />

      {!smes.length && (
        <EmptyState setOpen={setOpen} open={isEmpty} setIsEmpty={setIsEmpty}/>
      )}
      {!superAdmin && !match.params.id && smes.length !== 0 && (
        <ViewMode onSelectTab={onTabSelect} type={tab}/>
      )}

      <DrawerWrapper
        open={open}
        loading={isLoading}
        anchor="right"
        onClose={onDismiss}
        formType={formType}
        onSubmit={onSubmit}
        title={formType === "create" ? "Add New Team" : "Update Team"}
      >
        <AddTeam
          timezone={timezone}
          handleTimeZoneSelect={handleTimeZoneSelect}
          errors={errors}
          handleSelect={handleSelect}
          hidePassword={hidePassword}
          toggleHidePassword={toggleHidePassword}
          type={"team"}
          onSelectChange={onSelectChange}
          values={values}
          handleChange={handleChange}
        />
      </DrawerWrapper>
      {tab === "grid" ? (
        <RenderGrid
          onClick={(data) => history.push(`/teams/${data.id}/employees`)}
          moreActions={moreActions}
          onSelect={onSelect}
          data={smes}
        />
      ) : (
        <TableLoader loading={loading}>
          <Table
            handleRowClick={handleRowClick}
            headers={
              superAdmin && !match.params.id
                ? adminView
                : smeHeaders(isShiftHappens)
            }
            rowsPerPageOptions={[ 25 ]}
            data={loading ? [] : smes}
            tableStyle={{
              width : "100%",
              backgroundColor : "#fff",
              marginTop : 20,
            }}
            emptyMessageStyle={{
              left : "45%",
              position : "absolute",
              fontSize : 25,
              width : "80%",
              paddingTop : 20,
            }}
            hover
            renderIcon={(data) => (
              <Ellipsis
                as="td"
                onSelect={onSelect}
                data={data}
                moreActions={
                  superAdmin && location.pathname === "/" ? [] : moreActions
                }
              />
            )}
          />
        </TableLoader>
      )}
    </div>
  );
}

export default TeamComponent;
