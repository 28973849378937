import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

function NavigateTo({ name, classes, link, history, ...rest }) {
  return (
    <div className={classes.container} style={{ ...rest, paddingLeft: rest.left }}>
      <div style={{ fontSize: 15, fontWeight: 900, color: 'blue' }} onClick={() => history.push(link)}>
        {name}
      </div>
    </div>
  );
}

const styles = {
  container: {
    marginTop: '3%',
    cursor: 'pointer',
  },
};

NavigateTo.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
  history: PropTypes.object,
  rest: PropTypes.object,
};
export default withStyles(styles)(withRouter(NavigateTo));
