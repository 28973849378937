import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ReactComponent as TeamIcon } from "../images/team.svg";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

export default function EmptyState({ setOpen, setIsEmpty, open }) {
  return (
    <Dialog open={open}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle>Your Team is empty</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setIsEmpty(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <TeamIcon style={{ margin: "0 auto" }} />
      <DialogContent>
        <DialogContentText>
          Click the “Add Team” button below. Once you’ve created a team, click
          the menu button in the top right hand corner.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setIsEmpty(false);
            setOpen(true);
          }}
          color="secondary"
        >
          Add Team
        </Button>
      </DialogActions>
    </Dialog>
  );
}
