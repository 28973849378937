import React, { useState } from "react";
import Rating from "react-star-rating-component";
import { formatDistance } from "date-fns";
import { Badge } from "react-bootstrap";
import { Checkbox, Chip } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import { ellipsify } from "./utils";
import RenderHTML from "../components/RenderHTML";

export const API_ROOT = process.env.REACT_APP_URL;

export const smeHeaders = (isShiftHappens) => [
  {
    title: "Team",
    dataIndex: "name",
  },
  {
    title: isShiftHappens ? "Coach" : "Manager",
    dataIndex: "manager",
    width: "10%",
    render: (item) =>
      item &&
      item.manager &&
      `${item.manager.firstname} ${item.manager.lastname}`,
  },
  {
    title: isShiftHappens ? "Coachees" : "No of Workers",
    dataIndex: "workers",
    align: "right",
    render: (item) => item && item.employeesCount,
  },
  { title: "No of worklogs", dataIndex: "worklogsCount", align: "right" },
  {
    title: "Last Updated",
    dataIndex: "update",
    render: (item) =>
      item && item.lastUpdated && new Date(item.lastUpdated).toDateString(),
  },
  {
    title: "Date Created",
    dataIndex: "created",
    render: (item) => item && new Date(item.createdAt).toDateString(),
  },
];

export const adminView = [
  {
    title: "Full name",
    dataIndex: "name",
    render: (item) => item && `${item.firstname} ${item.lastname} `,
  },
  { title: "Email", dataIndex: "email" },
  { title: "Industry", dataIndex: "industry" },
  {
    title: "Size",
    dataIndex: "staffStrength",
  },

  {
    title: "Teams",
    dataIndex: "smeCount",
    align: "right",
  },
  {
    title: "Workers",
    dataIndex: "employeeCount",
    align: "right",
  },
  {
    title: "Worklogs",
    dataIndex: "worklogCount",
    align: "right",
  },
  {
    title: "Reviews",
    dataIndex: "reviewsCount",
    align: "right",
  },
  {
    title: "Avg Star Rating",
    dataIndex: "averageRating",
    render: (item) =>
      item && item.averageRating && item.averageRating.toFixed(2),
    align: "right",
  },
  {
    title: "Badges",
    dataIndex: "badgeCount",
    align: "right",
  },
  {
    title: "Start Date",
    dataIndex: "created",
    render: (item) => item && new Date(item.createdAt).toDateString(),
  },
];

export const employeeHeaders = (onClick) => [
  {
    name: "Manager",
    render: (item = {}) =>
      item.managers && item.managers.length
        ? `${item.managers[0].firstname} ${item.managers[0].lastname}`
        : "-",
  },
  {
    name: "Fullname",
    render: (item) =>
      item && (
        <p style={{ color: "blue" }} onClick={() => onClick(item) || null}>
          {item.firstname} {item.lastname}
        </p>
      ),
  },
  { name: "Job title", dataIndex: "jobTitle" },
  { name: "Email", dataIndex: "email" },
  { name: "Username", dataIndex: "username" },
  { name: "Contact info", dataIndex: "contact" },
  { name: "Address", dataIndex: "address" },
  {
    name: "Date started",
    render: (item) => item && new Date(item.startDate).toDateString(),
  },

  { name: "Last action (date)", dataIndex: "last_seen" },
];

export const tagsHeaders = [
  {
    name: "Tag Group Name",
    dataIndex: "name",
  },
  {
    name: "Tags",
    render: (item) => item && item.tags.map((tag) => `${tag.name}, `),
  },
];

export const behavioursHeaders = [
  { title: "Category", dataIndex: "customCategory" },
  { title: "Level", dataIndex: "customLevel" },
  { title: "Behaviour", dataIndex: "title" },
  {
    title: "Description",
    dataIndex: "description",
    render: (item) => <RenderHTML html={item.description} />,
  },
];

export const taskHeaders = [
  { title: "Title", dataIndex: "title" },
  {
    title: "Description",
    dataIndex: "description",
    width: "600",
    render: (item) => <RenderHTML html={item.description} />,
  },
  {
    title: "Members Assigned",
    dataIndex: "member",
    render: (item) =>
      item &&
      item.members &&
      item.members.map((mem, i) => (
        <p key={i}>
          <strong>{`${mem.firstname} ${mem.lastname}`}</strong>
        </p>
      )),
  },
  {
    title: "Date Created",
    dataIndex: "date",
    render: (item) => item && new Date(item.createdAt).toDateString(),
  },
];

export const projectHeaders = [
  { title: "Title", dataIndex: "title" },
  {
    title: "Description",
    dataIndex: "description",
    width: "600",
    render: ({ description }) => <RenderHTML html={description} />,
  },
  {
    title: "Starting Date",
    dataIndex: "date",
    render: (item) => new Date(item.startDate).toDateString(),
  },
  {
    title: "End Date",
    dataIndex: "end",
    render: (item) => new Date(item.endDate).toDateString(),
  },
  {
    title: "Members",
    dataIndex: "members",
    render: (item) =>
      item &&
      item.members.map((mem, i) => (
        <p key={i}>
          <strong>{`${mem.firstname} ${mem.lastname}`}</strong>
        </p>
      )),
  },
  {
    title: "Assigner",
    dataIndex: "assigner",
    render: (item) =>
      item && `${item.assigner.firstname} ${item.assigner.lastname} `,
  },
  {
    title: "Project Completed?",
    dataIndex: "completed",
    render: (item) => (item && item.isCompleted ? "Yes" : "No"),
  },
];

export const empProject = [
  {
    title: "Title of Project",
    dataIndex: "title",
    style: {
      maxWidth: 200,
    },
    render: (item) => {
      return item ? <p className={"text-normal"}>{item.title}</p> : null;
    },
  },
  {
    title: "Description of Project",
    render: ({ description }) => <RenderHTML html={description} />,
    dataIndex: "description",
    style: { maxWidth: 300 },
  },
  {
    title: "Starting Date",
    dataIndex: "start",
    render: (item) => new Date(item.startDate).toDateString(),
  },
  {
    title: "Assigned by",
    dataIndex: "assigner",
    render: (item) =>
      item.ProjectUser.userId === item.assignerId ? "Self" : "Manager",
  },
  {
    title: "Members Assigned",
    dataIndex: "member",
    render: (item) => {
      if (item && item.members) {
        let members = item.members; // localising for quick lookup
        let numberOfMembers = members.length; // memoizing the length so we don't recompute it or look it up every time
        let lastMemberIndex = numberOfMembers - 1; // memoizing the last member Index so we don't recompute it or look it up every time

        return members.map((member, memberIndex) => {
          return (
            <p key={memberIndex} className={"text-normal font-weight-500"}>
              {`${member.firstname} ${member.lastname}`}
              {numberOfMembers > 1 && memberIndex < lastMemberIndex ? "," : ""}
            </p>
          );
        });
      }
      return null;
    },
  },

  {
    title: "End Date",
    dataIndex: "end",
    render: (item) => new Date(item.endDate).toDateString(),
  },
  {
    title: "Project Completed?",
    dataIndex: "completed",
    render: (item) => (item && item.isCompleted ? "Yes" : "No"),
  },
];

export const empTaskHeaders = [
  { title: "Title of Tasks", dataIndex: "title" },
  {
    title: "Description of Task",
    dataIndex: "description",
    render: ({ description }) => <RenderHTML html={description} />,
  },
  {
    title: "Date Added",
    dataIndex: "data",
    render: (item) => item && new Date(item.createdAt).toDateString(),
  },
];

let checkedItems = {};

function handleOnCheckboxChange(item) {
  return ({ checked }) => {
    checkedItems = Object.assign({}, checkedItems, { [item.id]: checked });
  };
}

export const skillsHeaders = (filter, isShiftHappens) => [
  {
    Header: "Category",
    dataIndex: "customCategory",
    id: "customCategory",
    accessor: "customCategory",
  },
  { Header: "Level", id: "customLevel", accessor: "customLevel" },
  {
    Header: isShiftHappens ? "Name of Value" : "Name of Skill",
    accessor: "title",
  },
  { Header: "Link", accessor: "links" },
  {
    Header: "Members",
    id: "users",
    accessor: (values) => {
      values.users &&
        values.users.map((user) => (
          <p>{`${user.firstname} ${user.lastname}`}</p>
        ));
    },
  },
  {
    Header: filter ? filter : "Status",
    id: filter ? "count" : "status",
    accessor: (values) => {
      return filter ? (
        <p>{`${values} workers`}</p>
      ) : (
        values.isAssigned && (
          <Chip
            style={{ backgroundColor: deepOrange[400], color: "white" }}
            variant="outlined"
            size="small"
            label="Practicing"
          />
        )
      );
    },
  },
  {
    Header: "Date Added",
    id: "date",
    accessor: (values) => values && new Date(values.createdAt).toDateString(),
  },
];

export const worklogHeaders = (isEmployee) => [
  {
    name: "Task",
    render: (item) =>
      item &&
      item.taskLogs.map((log, i) => (
        <div key={i}>
          <Badge variant="primary">{log.title}</Badge>

          <p style={{ paddingLeft: 14 }}>{log.log}</p>
        </div>
      )),
  },
  {
    name: "Project",
    render: (item) =>
      item &&
      item.projectLogs.map((log, i) => (
        <div key={i}>
          <Badge variant="info">{log.title}</Badge>
          <ul>
            <li style={{ paddingLeft: "4%" }}>{log.log}</li>
          </ul>
        </div>
      )),
  },
  {
    name: "Feedback",
    render: (item) => item && ellipsify(item.feedback, 180),
  },

  {
    name: "What went well",
    render: (item) => item && item.review && item.review.feedback,
  },
  {
    name: "What did not go well",
    render: (item) =>
      item &&
      item.review && (
        <ul>
          {item.review.improvement.map((imp, i) => (
            <li key={i}>{imp}</li>
          ))}
        </ul>
      ),
  },

  {
    name: "Date Added",
    render: (item) =>
      item && formatDistance(new Date(item.createdAt), new Date()) + " ago",
  },
  {
    name: "Rating",
    render: (item) =>
      item && item.review ? (
        <Rating starCount={5} value={item.review.rating} />
      ) : (
        <p style={{ color: "red" }}>
          {isEmployee ? "No Rating Yet" : "Rate Now"}{" "}
        </p>
      ),
  },
];

export const reviewHeaders = [
  {
    name: "Task",
    render: (item) =>
      item &&
      item.taskLogs.map((log, i) => (
        <div key={i}>
          <Badge variant="primary">{log.title}</Badge>

          <p style={{ paddingLeft: 14 }}>{log.log}</p>
        </div>
      )),
  },
  {
    name: "Project",
    render: (item) =>
      item &&
      item.projectLogs.map((log, i) => (
        <div key={i}>
          <Badge variant="info">{log.title}</Badge>
          <ul>
            <li style={{ paddingLeft: "4%" }}>{log.log}</li>
          </ul>
        </div>
      )),
  },
  {
    name: "What went well",
    render: (item) => item && item.review && item.review.feedback,
  },
  {
    name: "What did not go well",
    render: (item) =>
      item &&
      item.review && (
        <ul>
          {item.review.improvement.map((imp, i) => (
            <li key={i}>{imp}</li>
          ))}
        </ul>
      ),
  },
  {
    name: "Date Added",
    render: (item) =>
      item && formatDistance(new Date(item.createdAt), new Date()) + " ago",
  },
  {
    name: "Rating",
    render: (item) =>
      item && item.review ? (
        <Rating starCount={5} value={item.review.rating} />
      ) : (
        <p style={{ color: "red" }}>Rate Now</p>
      ),
  },
];

export const notificationHeader = [
  { name: "Type", dataIndex: "type" },
  { name: "Read", render: (item) => (item && item.isRead ? "Yes" : "No") },
  { name: "Message", dataIndex: "message" },
  {
    name: "Date created",
    render: (item) =>
      item && formatDistance(new Date(item.createdAt), new Date()) + " ago",
  },
];

export const days = [
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
];
