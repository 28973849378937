import { formatDistance } from 'date-fns';

export function getRoleAccess(roles, user) {
  return roles?.some(role => {
    return user.roles.includes(role);
  });
}

export function readLocalStorage(key) {
  try {
    return JSON.parse(window.localStorage.getItem(key));
  } catch (error) {
    throw error;
  }
}

export const images = {
  logo: 'https://res.cloudinary.com/gen-technology/image/upload/v1543750137/skillnav-resources/full-logo-skillnav.png',
  smeLogo: 'https://res.cloudinary.com/gen-technology/image/upload/v1516483654/skillnav-avatars/default-sme-logo.png',
  heroImage: 'https://res.cloudinary.com/gen-technology/image/upload/v1516555569/skillnav-avatars/skills_zc5gr9.svg',
};

export function formatDate(date) {
  return date && new Date(date).toISOString().split('T')[0];
}

export function cleanDate(data) {
  return formatDistance(new Date(data), new Date()) + ' ago';
}

export function ExcelDateToJSDate(date) {
  return new Date(Math.round((date - 25569) * 86400 * 1000));
}

export const saveToOutBox = data => {
  if (localStorage.getItem('outBox')) {
    const outBox = JSON.parse(localStorage.getItem('outBox'));
    outBox.push(data);
    return localStorage.setItem('outBox', JSON.stringify(outBox));
  }
  return localStorage.setItem('outBox', JSON.stringify([data]));
};

export const readFromLocal = key => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    throw error;
  }
};

export const isDefined = function (value) {
  return typeof value !== "undefined";
}

export const noop = () => {
}

export const isEmpty = function isEmpty (str) {
  return null === str || undefined === str ? true : /^[\s\xa0]*$/.test(str);
};

/**
 *
 * @param strArr {Array<String>}
 */
export const allEmpty = function allEmpty (strArr) {
  return strArr.every(isEmpty)
};

/**
 *
 * @param strArr {Array<String>}
 */
export const someEmpty = function allAreEmpty (strArr) {
  return strArr.some(isEmpty)
};

/**
 *
 * @param object
 * @returns {boolean}
 */
export const objectIsEmpty = function (object) {
  return Object.keys(object).length < 1;
};

/**
 *
 * @param data
 * @returns {boolean}
 */
export const isObjectEmpty =  data => {
 return Object.keys(data).length === 0 && data.constructor === Object
}

/**
 *@use for reducing a string to a number of chars
 * @param str {string}
 * @param newLength {number}
 * @returns {string|String|*}
 */
export const truncate  = function (str, newLength) {
  if (!isEmpty(str)) {
    if (str.length > newLength && str !== "") {
      str = str.substr(0, newLength);
    }
    return str;
  }
  return ""
}

/**
 * @use for ellipsifying text if longer than the maxLength  ellipses
 *
 * @param str {String}
 * @param maxLength {Number}
 * @returns {*}
 */
export const ellipsify = function (str, maxLength) {  //
  if (isEmpty(str)) {
    return "";
  }
  if (str.length < maxLength) {
    return str;
  } else {
    return (truncate(str, maxLength - 3) + "...");
  }
}

/**
 *
 * @param arr {Array}
 * @param obj
 * @returns {boolean}
 */
export const removeArrayItem = function (arr, obj)
{
  let i = arr.findIndex(obj);

  if (i >= 0) {
    return Array.prototype.splice.call(arr, i, 1).length === 1;
  }
  return false;
}
/**
 *
 * @param url {String}
 * @returns {{}}
 */
export const getQueryParams = function (url) {
  if (url || typeof window !== 'undefined') {
    url = url ? url : window.location.href;
    let params = {};
    if (url.endsWith("#")) {
      url = url.replace(new RegExp('#' + '$'), '');
    }
    let queryString = url.split('?')[1];
    if (!queryString) {
      return params;
    }
    let queryArray = queryString.split('&');

    queryArray.forEach((param,) => {
      let [ key, value ] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
    });

    url = queryString = queryArray = null;

    return params;
  }
  return {}
}
