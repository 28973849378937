import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

const TableRowLoader = props => {
  return (
    <ContentLoader height={48} width={1200} speed={1} primarycolor="#eff5fe" secondarycolor="#ecebeb" {...props}>
      <circle cx="20" cy="20" r="12" />
      <rect x="50" y="20" rx="4" ry="4" width="80" height="10" />
      <rect x="100" y="20" rx="4" ry="4" width="100" height="10" />
      <rect x="250" y="20" rx="4" ry="4" width="100" height="10" />
      <rect x="400" y="20" rx="4" ry="4" width="100" height="10" />
      <rect x="550" y="20" rx="4" ry="4" width="100" height="10" />
      <rect x="700" y="20" rx="4" ry="4" width="100" height="10" />
      <rect x="850" y="20" rx="4" ry="4" width="100" height="10" />
      <rect x="980" y="20" rx="4" ry="4" width="100" height="10" />
      <rect x="1150" y="20" rx="4" ry="4" width="100" height="10" />
    </ContentLoader>
  );
};

const TableLoader = ({ children, loading }) => (
  <Fragment>
    {loading ? (
      <div>
        {children}
        {Array(10)
          .fill('')
          .map((e, i) => (
            <TableRowLoader
              key={i}
              style={{
                marginLeft: '2%',
                marginRight: '2%',
                borderBottom: '0.5px solid rgba(221, 221, 221, 0.5)'
              }}
            />
          ))}
      </div>
    ) : (
      children
    )}
  </Fragment>
);

TableLoader.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired
};

export default TableLoader;
