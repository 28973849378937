import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col, Row } from "react-bootstrap";
import { Hint } from "./Hint";

function Header({ header, headerHint, subHeader, action, navigate }) {
  return (
    <HeaderContainer>
      <Row>
        <Col><aside className="title">{header} {headerHint}</aside> </Col>
      </Row>
      <Row>
        <Col>
          <aside className="subText">{subHeader}</aside>
          <div>{navigate}</div>
        </Col>
        <div className={"d-flex col-auto flex-column justify-content-end text-right"}>
          {action}
        </div>
      </Row>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  margin-top: 32px;

  .title {
    color: #003271;
    font-size: 32px;
    font-weight: bold;

    @media (max-width: 600px) {
      font-size: 25px;
      padding-right: 20px;
      padding-left: 20px;
      width: 100%;
    }
  }

  .subText {
    padding-top: 12px;
    text-align: justify;
    color: #7b8d8d;
    font-size: 16px;
    width: 500px;
    line-height: 19px;

    @media (max-width: 600px) {
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;
      padding-bottom:30px;
    }
  }
`;

Header.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string,
  action: PropTypes.object,
  navigate: PropTypes.object,
};
export default withRouter(Header);
