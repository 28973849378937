import React, { Fragment, useEffect } from 'react';
import Header from '../../components/Header';
import TableWrapper from '../../components/Table';
import { notificationHeader } from '../../helpers/constants';
import MoreAction from '../../components/MoreActions';

function Notification({ user, notification = [], getNotification }) {
  useEffect(() => {
    getNotification(user.id);
  }, [getNotification, user.id]);

  function onSelect(action, data) {
    switch (action) {
      case 'view':
        return data;
      case 'mark':
        return;
      default:
        return;
    }
  }

  return (
    <Fragment>
      <div className="container-fluid">
        <Header header="Your notifications" subHeader="A list of all notifications" />
        <div style={{ marginTop: '5%' }} className="row">
          <div className="col-md-12">
            <TableWrapper data={notification} headers={notificationHeader}>
              {({ record }) => <MoreAction moreActions={[]} record={record} onSelect={onSelect} />}
            </TableWrapper>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Notification;
