import React, { useState } from "react";
import SignInForm from "./SignIn";
import "./styles.css";

function AuthComponent({ auth: { loading, error }, login }) {
  const [values, setValue] = useState({});
  const [hidePassword, toggleHidePassword] = useState(true);

  function onChange({ target }) {
    let { name, value } = target;
    setValue({ ...values, [name]: name === "email" ? value.trim() : value });
  }

  function onSubmit(e) {
    e.preventDefault();
    if (values.email.includes("@")) {
      return login(values, "login");
    }
    const data = {
      username: values.email,
      password: values.password,
    };
    return login(data, "signup");
  }

  return (
    <div className="loginContainer">
      <div className="hero"></div>
      <SignInForm
        hidePassword={hidePassword}
        toggleHidePassword={toggleHidePassword}
        loading={loading}
        error={error}
        onChange={onChange}
        values={values}
        onSubmit={onSubmit}
      />
    </div>
  );
}

export default AuthComponent;
