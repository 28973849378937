import { connect } from "react-redux";
import SkillComponent from ".";
import {
  createSkill,
  getSkills,
  deleteSkill,
  assignSkill,
  unAssignSkill,
  getAdminSkills,
  updateSkill,
  uploadSkills,
  filterBehaviours,
} from "./duck/actions";

const mapStateToProps = ({ skills: { allIds, byId, loading, filters } }) => {
  return {
    skills: allIds.map((id) => { return { ...(byId[ id ]), isChecked : false }; }), loading, filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSkill: (data, orgId, setLoading, setOpen) =>
      dispatch(createSkill(data, orgId, setLoading, setOpen)),
    uploadSkills: (data, orgId) => dispatch(uploadSkills(data, orgId)),
    updateSkill: (data, id) => dispatch(updateSkill(data, id)),
    getSkills: (smeId, userId) => dispatch(getSkills(smeId, userId)),
    deleteSkill: (id) => dispatch(deleteSkill(id)),
    assignSkill: (type, data, setLoading, closeDrawer) =>
      dispatch(assignSkill(type, data, setLoading, closeDrawer)),
    unAssignSkill: (type, data, setLoading, closeDrawer) =>
      dispatch(unAssignSkill(type, data, setLoading, closeDrawer)),
    getAdminSkills: (id) => dispatch(getAdminSkills(id)),
    filterBehaviours: (id, setLoading) =>
      dispatch(filterBehaviours(id, setLoading)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkillComponent);
