import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import Exception from "./Exception";
// import Alert from '@material-ui/lab/Alert';
import { getRoleAccess } from "../helpers/utils";
import ErrorBoundary from "../components/ErrorBoundary";
import NavBar from "../components/NavBar";
import { links } from "../helpers/navLink";
import Snackbar from "../modules/Snackbar";
import UserMenu from "../components/Menu";
import SubscribeMailModal from "../components/SubscribeMailModal";

const AuthRoute = ({
  roles,
  logout,
  component: Component,
  updateNotification,
  notifications,
  user = {},
  history,
  ...rest
}) => {
  let roleExists = getRoleAccess(roles, user) || true;

  let isShiftHappens = user?.email?.includes("kwaku@org");

  //let superAdmin = user.roles.includes(1);
  let isOwner = Boolean(user.roles.find((role) => role.name === "sme-owner"));
  let isManager = Boolean(
    user.roles.find((role) => role.name === "sme-manager")
  );
  let isEmployeeAndManager =
    user.roles.length > 1
      ? user.roles.every((role) =>
          ["sme-manager", "sme-employee"].includes(role.name)
        )
      : false;

  let isEmployee =
    user.roles.length === 1 && user.roles[0].name === "sme-employee";

  let superAdmin = user.roles[0].name === "super-admin";

  return (
    <Route
      {...rest}
      render={(props) =>
        roleExists ? (
          <ErrorBoundary>
            <UserMenu
              user={user}
              roles={user.roles}
              history={history}
              links={links(
                isEmployee,
                isOwner,
                isManager,
                isEmployeeAndManager,
                isShiftHappens
              )}
              isEmployeeAndManager={isEmployeeAndManager}
              isManager={isManager}
              isOwner={isOwner}
              updateNotification={updateNotification}
              isEmployee={isEmployee}
              notifications={notifications}
              logout={logout}
            />

            {/* {!user.isVerified && (
              <div style={{ width: 500, margin: '0 auto' }}>
                <Alert variant="filled" severity="warning">
                  Your email is not verified, Please check your email and verify
                </Alert>
              </div>
            )} */}
            <SubscribeMailModal
              isEmployee={isEmployee}
              isEmployeeAndManager={isEmployeeAndManager}
            />

            <div style={{ marginLeft: "3%", marginRight: "3%" }}>
              <Component
                isShiftHappens={isShiftHappens}
                superAdmin={superAdmin}
                isEmployeeAndManager={isEmployeeAndManager}
                isOwner={isOwner}
                isManager={isManager}
                isEmployee={isEmployee}
                user={user}
                {...props}
              />
            </div>
          </ErrorBoundary>
        ) : (
          <NavBar logout={logout} links={links(isEmployee, isOwner)}>
            <Exception history={history} />
          </NavBar>
        )
      }
    />
  );
};

AuthRoute.propTypes = {
  history: PropTypes.object,
  component: PropTypes.any,
  user: PropTypes.object,
  //roles: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired
};

export default AuthRoute;
