import React, { Fragment, useState } from "react";
import TextInput from "../../components/TextInput";
import { Button } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

function AddEmployee({
  type,
  formType = "create",
  handleChange,
  setValue,
  values,
  showFooter = false,
}) {
  const classes = useStyles();
  const [hidePassword, toggleHidePassword] = useState(true);

  return (
    <div className={classes.container}>
      {/* <form onSubmit={handleSubmit(onSubmit)}> */}
      <div className={classes.grid}>
        <TextInput
          type="text"
          fullWidth
          name="firstname"
          onChange={handleChange}
          // value={values?.firstname || ""}
          errorMessage="First name is required"
          label="First name*"
          placeholder="First name"
        />

        <TextInput
          type="text"
          fullWidth
          name="lastname"
          onChange={handleChange}
          // value={values?.lastname || ""}
          // {...register("lastname", { required: true })}
          // errorMessage="Last name is required"
          // error={errors?.lastname}
          label="Last name*"
          placeholder="Last name"
        />

        <TextInput
          fullWidth
          label="Email Address*"
          disabled={type === "edit"}
          type="email"
          name="email"
          onChange={handleChange}
          // value={values?.email || ""}
          // {...register("email", { required: true })}
          // label="Email address*"
          // errorMessage="Email field is required"
          // error={errors?.email}
          placeholder="Email address"
        />
        <TextInput
          label="Password*"
          type="password"
          name="password"
          onChange={handleChange}
          // {...register("password", { required: true })}
          // error={errors?.password}
          placeholder="User's password"
          fullWidth
          errorMessage={"Must be more than 8 characters"}
          hidden={hidePassword}
          toggleVisibility={toggleHidePassword}
        />
      </div>
      {
        formType !== "team" && (
          <Fragment>
            <TextInput
              type="date"
              fullWidth
              onChange={handleChange}
              // value={values?.startDate || ""}
              name="startDate"
              disabled={type === "edit"}
              // {...register("startDate", { required: true })}
              label="Start Date"
            />

            <TextInput
              type="text"
              fullWidth
              name="jobTitle"
              onChange={handleChange}
              // value={values?.jobTitle || ""}
              // {...register("jobTitle", { required: true })}
              label="Job Title"
            placeholder="eg: Software Engineer"
          />

          <TextInput
            type="text"
            fullWidth
            name="level"
            onChange={handleChange}
            disabled={type === "edit"}
            // value={values?.level?.level || ""}
            // {...register("level", { required: true })}
            label="Level"
            placeholder="eg: Entry level"
          />

            <TextInput
              type="text"
              fullWidth
              name="address"
              // value={values?.address || ""}
              onChange={handleChange}
              // {...register("address", { required: true })}
              label="Address"
              placeholder="Address"
            />
          </Fragment>
        )}

      {
        showFooter && (
          <div style={{ float : "right" }} className={"mt-3"}>
            <Button
              style={{ marginRight : 10 }}
              variant="contained"
              className={"mt-3 mr-3"}
              // onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              // disabled={loading || disableButton}
              // onClick={() => onSubmit(formType === "create" ? "create" : "edit")}
            >
              Confirm
              {/* {loading && <LoadingIcon style={{ height: 20 }} />} */}
            </Button>
          </div>
        )
      }
      {/* </form> */}
    </div>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      width: 600,
      "@media (max-width: 600px)": {
        width: "100%",
      },
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gridColumnGap: 15,

      "@media (max-width: 600px)": {
        gridTemplateColumns: "1fr",
      },
    },
  })
);

export default AddEmployee;
