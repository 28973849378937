import React, { useEffect, useRef, useState } from "react";
import Table from "mpharma-react-table";
import Header from "../../components/Header";
import { projectHeaders } from "../../helpers/constants";
import useForm from "../../hooks/useForm";
import AddProject from "./AddProject";
import { headerText } from "../../helpers/text";
import NavigateTo from "../../components/NavigateTo";
import ProjectDetails from "./ProjectDetails";
import DrawerWrapper from "../../components/DrawerWrapper";
import Ellipsis from "../../components/Ellipsis";
import { deepOrange } from "@material-ui/core/colors";
import { Button } from "@material-ui/core";

function Project({
  user,
  match,
  sme = {},
  employees,
  getEmployees,
  getProjects,
  projects,
  createProject,
  getSme,
  deleteProject,
  completeProject,
  editProject,
}) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [formType, setFormType] = useState("create");
  const { values, setValues, handleChange } = useForm();
  const [selected, setSelected] = useState(null);
  const fileInput = useRef();
  const [description, setDescription] = useState("");

  const moreActions = [
    { name: "Complete Project", action: "complete" },
    { name: "Edit", action: "edit" },
    { name: "Delete", action: "delete" },
  ];

  function openUpload() {
    return fileInput.current.click();
  }

  useEffect(() => {
    getProjects(match.params.id);
    getEmployees(match.params.id);
    getSme(match.params.id);
  }, [getEmployees, getProjects, getSme, match.params.id]);

  function onSubmit(type) {
    setOpenDrawer(false);
    switch (type) {
      case "create":
        const data = {
          ...values,
          description,
          smeId: match.params.id,
          members: values?.members?.map((mem) => mem.id) || [],
        };

        return createProject(data);
      case "edit":
        const rec = {
          title: values.title,
          description: description,
          startDate: values.startDate,
          endDate: values.endDate,
          members:
            (values.members && values.members.map((mem) => mem.id)) || [],
        };

        return editProject(values.id, rec);
      case "complete":
        return completeProject(data.id);
      default:
        return;
    }
  }

  function onRowClick(data) {
    setSelected(data);
  }

  function addProject() {
    setValues({});
    setFormType("create");
    setOpenDrawer(true);
  }

  function onSelect(action, data) {
    switch (action) {
      case "edit":
        setOpenDrawer(true);
        setFormType("edit");
        return setValues(data);
      case "delete":
        let confirm = window.confirm(
          "Are you sure you want to delete this project?"
        );
        return confirm ? deleteProject(data.id) : null;
      case "complete":
        return completeProject(data.id);
      default:
        return;
    }
  }

  function handleSelect(name) {
    setValues({ ...values, members: name });
  }

  const onDescriptionChange = (value) => {
    setDescription(value);

    // handleChange({ target : { name : "description", value } })
  };
  const onDescriptionBlur = (previousRange, source, editor) => {
    console.log("from on tasks desc", {
      editor,
      val: editor.getContents(),
      html: editor.getHTML(),
      text: editor.getText(),
    });
    handleChange({ target: { name: "description", value: editor.getHTML() } });
  };

  return (
    <div>
      <Header
        header={headerText["project"].header}
        navigate={<NavigateTo name="Go back to People" link={`/`} />}
        subHeader={headerText["project"].subText}
        action={
          <Button
            variant="contained"
            style={{ backgroundColor: deepOrange[500], color: "white" }}
            onClick={addProject}
          >
            Add Project
          </Button>
        }
        onClick={openUpload}
      />
      <div className="row">
        <div className="col-md-12" style={{ marginTop: "2%" }}>
          <Table
            handleRowClick={onRowClick}
            headers={projectHeaders}
            rowsPerPageOptions={[25]}
            emptyMessageStyle={{
              left: "45%",
              position: "absolute",
              fontSize: 25,
              width: "80%",
              paddingTop: 20,
            }}
            emptyMessage={!projects.length ? "No projects are available" : ""}
            data={projects}
            tableBodyStyle={{ maxHeight: "50vh" }}
            tableStyle={{
              width: "100%",
              backgroundColor: "#fff",
              marginTop: 20,
            }}
            hover
            renderIcon={(data) => (
              <Ellipsis
                as="td"
                onSelect={onSelect}
                data={data}
                moreActions={moreActions}
              />
            )}
          />
        </div>
      </div>

      <DrawerWrapper
        disableButton={
          !values.title ||
          !values.startDate ||
          !values.endDate ||
          !values.members
        }
        size="500px"
        formType={formType}
        onSubmit={onSubmit}
        title={formType === "create" ? "Add Project" : "Update Project"}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <AddProject
          employees={employees}
          handleSelect={handleSelect}
          handleChange={handleChange}
          values={values}
          description={description}
          onDescriptionChange={onDescriptionChange}
          onDescriptionBlur={onDescriptionBlur}
        />
      </DrawerWrapper>
      <DrawerWrapper
        noFooter
        onClose={() => setSelected(null)}
        open={Boolean(selected)}
        title={
          "Project created: " +
          new Date(selected && selected.createdAt)?.toDateString()
        }
      >
        <ProjectDetails {...selected} />
      </DrawerWrapper>
    </div>
  );
}

export default Project;
