import { connect } from 'react-redux';
import Notification from '.';
import { getNotification } from './duck/actions';

const mapStateToProps = ({ notification: { allIds, byId } }) => {
  return {
    notification: allIds.map(id => byId[id])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getNotification: id => dispatch(getNotification(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
