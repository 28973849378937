import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";

/**
 *
 * @param setAddWorklogDrawerOpen
 * @param setTab
 * @param setIsEmpty
 * @param open
 * @param isEmployeeAndManager
 * @returns {JSX.Element}
 * @constructor
 */
function EmptyWorklogModal({
  setAddWorklogDrawerOpen,
  setTab,
  setIsEmpty,
  open,
  isEmployeeAndManager,
}) {
  let history = useHistory();
  const handleClose = () => {
    setIsEmpty(false);
  };
  return (
    <Modal centered show={open} onHide={handleClose}>
      <Modal.Header className={"justify-content-center px-4"} closeButton>
        <Modal.Title className="">Log work here!</Modal.Title>
      </Modal.Header>

      <Modal.Body className={"px-4"}>
        <p>
          Click <strong>Add Worklog</strong> button to start
        </p>
        <div>
          <p>Not sure what to report on your worklogs? Review your targets </p>
          <p>
            (Click on <strong>Tasks, Projects and Behaviours</strong> on your
            Timeline )
          </p>
        </div>

        {isEmployeeAndManager && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>Click to see the teams you manage</p>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer className="p-4">
        {isEmployeeAndManager && (
          <Button onClick={() => history.push("/teams")}>See your team</Button>
        )}
        <Button
          onClick={() => {
            setIsEmpty(false);
            setTab(3);
          }}
        >
          Review my target skills
        </Button>
        <Button
          onClick={() => {
            setIsEmpty(false);
            setAddWorklogDrawerOpen(true);
          }}
        >
          Add Worklog
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EmptyWorklogModal;
