export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAILURE = 'CREATE_EMPLOYEE_FAILURE';

export const FETCH_EMPLOYEES = 'FETCH_EMPLOYEES';
export const FETCH_EMPLOYEES_FAILURE = 'FETCH_EMPLOYEES_FAILURE';
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';

export const UPLOAD_EMPLOYEES_SUCCESS = 'UPLOAD_EMPLOYEE_SUCCESS';
export const UPLOAD_EMPLOYEE_FAILURE = 'UPLOAD_EMPLOYEE_FAILURE';

export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const EDIT_EMPLOYEE_SUCCESS = 'EDIT_EMPLOYEE_SUCCESS';
export const EDIT_EMPLOYEE_FAILURE = 'EDIT_EMPLOYEE_FAILURE';

export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAILURE = 'DELETE_EMPLOYEE_FAILURE';
