import axios from "axios";
import { API_ROOT } from "../../helpers/constants";
export const REQUEST_USER = "REQUEST_USER";
export const USER_SUCCESS = "USER_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
const RESET_PASSWORD = "RESET_PASSWORD";
const EMAIL_VERIFIED = "EMAIL_VERIFIED";
const NEW_PASSWORD = "NEW_PASSWORD";
export const USER_FAILURE = "USER_FAILURE";
export const LOG_OUT = "LOG_OUT";

export const getUser = () => (dispatch) => {
  try {
    const user = JSON.parse(localStorage.getItem("sn:user"));
    axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
    dispatch({ type: USER_SUCCESS, payload: user });
  } catch (error) {
    return dispatch({ type: USER_FAILURE, payload: null });
  }
};

export const login = (data, type) => async (dispatch) => {
  dispatch({ type: REQUEST_USER });
  try {
    let user = {};
    if (type === "login") {
      user = await axios.post(`${API_ROOT}/auth/login`, data);
    } else {
      user = await axios.post(`${API_ROOT}/auth/signup`, data);
    }
    window.localStorage.setItem("sn:user", JSON.stringify(user.data));
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + user.data.token;
    dispatch({ type: USER_SUCCESS, payload: user.data });
  } catch (error) {
    dispatch({
      type: LOGIN_FAILED,
      payload: "Sorry, invalid credentials",
    });
  }
};

export const resetPassword = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_USER });
  try {
    await axios.post(`${API_ROOT}/auth/resetPassword`, { email: data });
    dispatch({ type: RESET_PASSWORD });
  } catch (error) {
    dispatch({
      type: LOGIN_FAILED,
      payload: "Email does not exist",
    });
  }
};

export const newPassword = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_USER });
  try {
    axios.defaults.headers.common["Authorization"] = data.token;
    await axios.patch(`${API_ROOT}/auth/updatePassword`, {
      password: data.password,
    });
    dispatch({ type: NEW_PASSWORD });
  } catch (error) {
    dispatch({
      type: LOGIN_FAILED,
      payload: "Error occurred",
    });
  }
};

export const verifyEmail = (token) => async (dispatch) => {
  dispatch({ type: REQUEST_USER });
  try {
    await axios.get(`${API_ROOT}/auth/verify?token=${token}`);
    dispatch({ type: EMAIL_VERIFIED });
    window.localStorage.removeItem("sn:user");
  } catch (error) {
    dispatch({
      type: LOGIN_FAILED,
      payload: "Error occurred",
    });
  }
};

const INITIAL_STATE = {
  loading: false,
  user: undefined,
  error: null,
  emailSent: false,
  passwordReset: false,
  emailVerified: false,
};

const AuthReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_USER:
      return { ...INITIAL_STATE, loading: true };
    case USER_SUCCESS:
      return { ...INITIAL_STATE, user: payload };
    case NEW_PASSWORD:
      return { ...INITIAL_STATE, passwordReset: true };
    case USER_FAILURE:
      return { ...INITIAL_STATE, user: undefined };
    case RESET_PASSWORD:
      return { ...state, loading: false, emailSent: true };
    case EMAIL_VERIFIED:
      return { ...INITIAL_STATE, emailVerified: true };
    case LOGIN_FAILED:
      return { ...INITIAL_STATE, error: payload };
    case LOG_OUT:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default AuthReducer;
