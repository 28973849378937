import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Header from "../../components/Header";
import Drawer from "../../components/DrawerWrapper";
import useForm from "../../hooks/useForm";
import { skillsHeaders } from "../../helpers/constants";
import { headerText } from "../../helpers/text";
import Ellipsis from "../../components/Ellipsis";
import AddSkill from "./AddSkills";
import SkillDetail from "../../components/SkillDetails";
import useLoading from "../../hooks/useLoading";
import Loading from "../../components/Loading";
import Select from "../../components/BaseSelect";
// import { libraryFilter, skillsSelect } from "../../helpers/selectFields";
import { Button } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import useExcel from "../../hooks/useExcel";
import UploadBehavioursModal from "../../components/UploadBehaviourModal";
import { useDispatch } from "react-redux";
import { RESET_FILTER } from "./duck/types";
import { Hint } from "../../components/Hint";
import DataTable from "../../components/DataTable";

function Skills({
  user,
  createSkill,
  skills = [],
  getSkills,
  deleteSkill,
  assignSkill,
  unAssignSkill,
  loading: fetchingSkills,
  getAdminSkills,
  updateSkill,
  isOwner,
  uploadSkills,
  filterBehaviours,
  filters,
  isEmployee,
  isShiftHappens,
}) {
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openUp, setOpenUp] = useState(false);
  const [selected, setSelected] = useState({});
  const { loading, setLoading } = useLoading(false);

  const { handleFileChange, setData, data: records } = useExcel();
  const [format, setFormat] = useState("");

  const [skillsData, setSkillsData] = useState(skills);

  const [filter, setFilter] = useState("");
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  const [selectedRows, setSelectedRows] = useState([]);

  const [description, setDescription] = useState("");

  const dispatch = useDispatch();

  const fileInput = useRef();

  const moreActions = [
    ...(isEmployee
      ? [{ name: "Learn more", action: "learn" }]
      : [{ name: "Learn more", action: "learn" }]),
  ];

  const [formType, setFormTpe] = useState("create");

  const { handleChange, values, setValues } = useForm({});

  useEffect(() => {
    if (skillsData !== skills) {
      setSkillsData(skills);
    }
    if (!openDrawer) {
      setValues({});
      setSelected({});
      setDescription("");
      setFormTpe("create");
    }
  }, [openDrawer, setValues, skills, skillsData]);

  useEffect(() => {
    if (user.myOrganization) {
      getAdminSkills(user.myOrganization.id);
    } else if (user.organizationId) {
      getAdminSkills(user.organizationId);
    } else if (user.organization) {
      getAdminSkills(user.organization.id);
    }
  }, [
    getAdminSkills,
    getSkills,
    isOwner,
    user,
    user.employers,
    user.id,
    user.myOrganization,
  ]);

  function onDismiss(type) {
    !type ? setOpen(false) : setOpenDrawer(false);
    setSelected({});
  }

  function onRowClick(data) {
    setOpenDrawer(true);
    setSelected(data.original);
  }

  function onSubmit(type) {
    switch (type) {
      case "create":
        let link = values.links ? values.links.split(",") : [];
        setLoading(true);
        createSkill(
          { ...values, description, links: link },
          (user.myOrganization && user.myOrganization.id) ||
            (user.organization && user.organization.id),
          setLoading,
          setOpen
        );

        return setValues({});
      case "upload":
        const cleanedData = records.map((rec) => ({
          customCategory: rec["Skills category (optional)"] || "",
          title: rec["Skill name (required)"],
          description: rec["Description (required)"],
          links: rec["Links to learning resources (optional)"]
            ? [rec["Links to learning resources (optional)"]]
            : [],
          customLevel: rec["Skills level (optional)"],
          creatorId: user.id,
        }));

        uploadSkills(
          cleanedData,
          user.myOrganization && user.myOrganization.id
        );
        setOpenUp(false);

        return setData(null);
      case "edit":
        const {
          customLevel,
          customCategory,
          title,
          description: desc,
          links,
          id,
        } = values;

        const data = {
          customLevel: customLevel || " ",
          customCategory: customCategory || " ",
          title,
          description: desc,
          links: links ? links.flat() : [],
        };

        updateSkill(data, id);
        setValues({});

        return setOpen(false);
      default:
        return;
    }
  }

  function onEdit(data) {
    setOpen(true);
    setFormTpe("edit");
    setValues(data);
    setDescription(data?.description);
  }

  function assignSelfSkill() {
    assignSkill(
      {
        userId: user.id,
        smeId: user.employers[0].id,
        skillId: selected.id,
      },
      setLoading,
      onDismiss
    );
  }

  function unAssign() {
    unAssignSkill(
      {
        userId: user.id,
        smeId: user.employers[0].id,
        skillId: selected.id,
      },
      setLoading,
      onDismiss
    );
  }

  function onSelect(action, data) {
    switch (action) {
      case "edit":
        return onEdit(data);
      case "delete":
        let confirm = window.confirm(
          "Are you sure you want to delete this skill?"
        );
        return confirm ? deleteSkill(data.id) : null;
      case "learn":
        setOpenDrawer(true);
        setSelected(data);
        return;
      default:
        return;
    }
  }

  function handleSelect(data) {
    setFilter(data);
  }

  function handleClick() {
    filterBehaviours(
      (user.myOrganization && user.myOrganization.id) ||
        (user.organization && user.organization.id),
      setLoading
    );
  }

  const onFormatChange = (value) => {
    setFormat(value);

    handleChange({ target: { name: "description", value } });
  };

  const onDescriptionChange = (value) => {
    setDescription(value);

    // handleChange({ target : { name : "description", value } })
  };
  const onDescriptionBlur = (previousRange, source, editor) => {
    handleChange({ target: { name: "description", value: editor.getHTML() } });
  };

  function resetFilter() {
    setFilter(null);
    dispatch({ type: RESET_FILTER });
  }

  function openUpload() {
    return fileInput.current.click();
  }

  if (fetchingSkills) {
    return <Loading />;
  }

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.
  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setSkillsData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  return (
    <Fragment>
      <input
        onChange={handleFileChange}
        ref={fileInput}
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        id="my_file"
        onClick={openUpload}
        style={{ display: "none" }}
      />

      <div className="container-fluid">
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          className={"mb-2"}
        >
          <div>
            <Header
              header={
                isShiftHappens
                  ? "Your Organization's Values"
                  : (!isEmployee
                      ? user?.myOrganization?.name
                      : user?.organization?.name) + headerText["skills"].header
              }
              // subHeader={!isShiftHappens && headerText["skills"].subText}
              headerHint={
                <Hint
                  direction={"right"}
                  minWidth={370}
                  title={
                    "Your organization needs workers to practice and master skills on the job."
                  }
                  messages={headerText["skills"].hint}
                />
              }
            />
            <div style={{ display: "flex", paddingTop: 10 }}>
              <Select
                width={160}
                style={{ height: 10 }}
                value={filter}
                placeholder={"Select filter"}
                isMulti={false}
                onChange={handleSelect}
                options={[
                  { label: "Most assigned", value: "mostAssigned" },
                  { label: "Most practiced", value: "mostPracticed" },
                  { label: "Most mastered", value: "mostMastered" },
                ]}
              />
              <Button
                variant="contained"
                disabled={loading || !filter}
                style={{
                  backgroundColor: "orange",
                  color: "white",
                  height: 45,
                  marginLeft: 10,
                  marginRight: 10,
                }}
                disableElevation={true}
                onClick={handleClick}
              >
                {loading ? "Filtering.." : "Filter"}
              </Button>
              <Button
                variant="contained"
                disabled={loading || !filter}
                style={{
                  backgroundColor: "red",
                  color: "white",
                  height: 45,
                  marginLeft: 5,
                  marginRight: 10,
                }}
                onClick={resetFilter}
              >
                Cancel
              </Button>
            </div>
          </div>

          {!isEmployee && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "5%",
              }}
            >
              {isOwner && (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: deepOrange[500],
                    color: "white",
                    height: 40,
                    marginRight: 10,
                  }}
                  onClick={() => setOpenUp(true)}
                >
                  {isShiftHappens ? "Upload values" : "Upload behaviours"}
                </Button>
              )}
              <Button
                onClick={() => setOpen(true)}
                style={{ height: 50, marginLeft: 10 }}
              >
                {isShiftHappens
                  ? "Add to Values Library"
                  : "Add to Skills Library"}
              </Button>
            </div>
          )}
        </div>
        {loading ? (
          <Loading />
        ) : (
          /* <Table
                headers={
                  skillsHeaders(filter?.label, isShiftHappens)
                    .filter((skill) => skill.title !== "Members"
                    )}
                handleRowClick={onRowClick}
                rowsPerPageOptions={[ 25, 50, 100 ]}
                data={filter && filters[filter.value] ? filters[filter.value] : skills}
                emptyMessageStyle={{
                  left : "45%",
                  position : "absolute",
                  fontSize : 25,
                  width : "80%",
                  paddingTop : 20,
                }}
                emptyMessage={` is not in any of the worklogs`}
                tableStyle={{
                  width : "100%",
                  backgroundColor : "#fff", marginTop : 20,
                }}
                tableBodyStyle={{ maxHeight : "50vh", }}
                hover
                renderIcon={(data) => {
                  const userCreated = user.id === data.creatorId;
                  const filter = userCreated
                    ? [
                      ...moreActions,
                      { name : "Edit", action : "edit" },
                      { name : "Delete", action : "delete" },
                    ]
                    : moreActions;
                  return (
                    <Ellipsis
                      as="td"
                      onSelect={onSelect}
                      data={data}
                      moreActions={filter}
                    />
                  );
                }}
              />*/

          <DataTable
            className={"table-responsive-sm table mb=5"}
            columns={skillsHeaders(filter?.label, isShiftHappens).filter(
              (skill) => skill.title !== "Members"
            )}
            cellPlaceholder={<span>&mdash;</span>}
            onRowClick={onRowClick}
            data={
              filter && filters[filter.value]
                ? filters[filter.value]
                : skillsData
            }
            skipPageReset={skipPageReset}
            updateMyData={updateMyData}
            pagination={true}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        )}

        <Drawer
          disableButton={!Boolean(values.title)}
          formType={formType}
          title={
            formType === "create"
              ? `${
                  isShiftHappens
                    ? "Add value to Value Library"
                    : "Add Behaviour to Skill Library"
                }`
              : `${isShiftHappens ? "Update Value" : "Update Behaviour"}`
          }
          open={open}
          loading={loading}
          onSubmit={onSubmit}
          onClose={() => onDismiss()}
        >
          <AddSkill
            // onFormatChange={onFormatChange}

            onDescriptionChange={onDescriptionChange}
            onDescriptionBlur={onDescriptionBlur}
            // onFormatChange={onFormatChange}
            description={description}
            isShiftHappens={isShiftHappens}
            handleChange={handleChange}
            values={values}
          />
        </Drawer>
        <Drawer noFooter open={openDrawer} onClose={() => onDismiss(true)}>
          <SkillDetail
            user={user}
            isEmployee={isEmployee}
            assignSkill={assignSelfSkill}
            unAssignSkill={unAssign}
            loading={loading}
            title={selected.title}
            description={selected.description}
            links={selected.links}
            onEdit={onEdit}
            {...selected}
          />
        </Drawer>
        <UploadBehavioursModal
          onSubmit={onSubmit}
          onClick={openUpload}
          open={openUp}
          file={records}
          closeDiaglog={() => {
            setOpenUp(false);
            setData(null);
          }}
        />
      </div>
    </Fragment>
  );
}

Skills.propTypes = {
  user: PropTypes.object,
  createSkill: PropTypes.func,
  skills: PropTypes.array,
  getSkills: PropTypes.func,
  deleteSkill: PropTypes.func,
};

export default Skills;
