import { connect } from 'react-redux';
import EmployeeComponent from '.';
import { getTasks, createTask, editTask, deleteTask } from './duck/actions';
import { getEmployees } from '../employees/duck/actions';

const mapStateToProps = ({ tasks: { allIds, byId, loading }, employees }) => {
  return {
    tasks: allIds.map(id => byId[id]),
    loading,
    employees: employees.allIds.map(id => employees.byId[id])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getEmployees: smeId => dispatch(getEmployees(smeId)),
    getTasks: data => dispatch(getTasks(data)),
    createTask: smeId => dispatch(createTask(smeId)),
    editTask: (id, data) => dispatch(editTask(id, data)),
    deleteTask: id => dispatch(deleteTask(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeComponent);
