import { normalize, schema } from 'normalizr';

const work = new schema.Entity('worklogs');

const worklist = new schema.Array(work);

export function normaliz(data = []) {
  return normalize(data, worklist);
}

const review = new schema.Entity('review');

const reviewList = new schema.Array(review);

export function normalizReviews(data = []) {
  return normalize(data, reviewList);
}
