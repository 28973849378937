import { useState } from 'react';
import XLSX from 'xlsx';

const useExcel = (initialValue = null) => {
  const [data, setData] = useState(initialValue);

  const handleFileChange = async ({ target }) => {
    const reader = new FileReader();
    reader.onload = async evt => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary', skipHeader: false });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 2
      });
      setData(data);
    };
    reader.readAsBinaryString(target.files[0]);
  };

  return {
    handleFileChange,
    data,
    setData
  };
};

export default useExcel;
