import React, { useState } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import TextInput from "../../components/TextInput";
import { newPassword } from "../../routes/ducks/reducer";
import { Link } from "@material-ui/core";
import { images } from "../../helpers/utils";
import { ReactComponent as LoadingIcon } from "../../images/load.svg";

export function ResetPassword({
  loading,
  newPassword,
  match,
  error,
  passwordReset,
}) {
  const classes = useStyles();
  const [values, setValues] = useState({});

  const [hidePassword, toggleHidePassword] = useState(true);

  function onChange({ target }) {
    setValues({ ...values, [target.name]: target.value });
  }

  function onSubmit(e) {
    e.preventDefault();
    return newPassword({
      password: values.password,
      token: match.params.token,
    });
  }

  return (
    <div style={{ display: "flex" }}>
      <div className="hero"></div>;
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            style={{ margin: "0 auto", paddingBottom: "2%" }}
            width="150"
            src={images.logo}
            alt="logo"
          />
          <Typography component="h1" variant="h5">
            Create new password
          </Typography>
          {error && (
            <Alert severity="error">Sorry error occurred, try again</Alert>
          )}
          {passwordReset && (
            <Alert severity="success">
              Password reset successful,{" "}
              <Link href="/login">Proceed to login</Link>
            </Alert>
          )}

          <form className={classes.form} noValidate>
            <TextInput
              onChange={onChange}
              hidden={hidePassword}
              toggleVisibility={toggleHidePassword}
              placeholder="New password"
              required
              fullWidth
              type="password"
              label="New password*"
              name="password"
            />
            <TextInput
              hidden={hidePassword}
              onChange={onChange}
              toggleVisibility={toggleHidePassword}
              placeholder="Confirm password*"
              error={Boolean(
                values.password !== values.confirmPassword &&
                  values.confirmPassword
              )}
              errorMessage="Password does not match"
              required
              fullWidth
              type="password"
              label="Confirm password*"
              name="confirmPassword"
            />

            <Button
              disabled={
                !values.password ||
                values.password !== values.confirmPassword ||
                loading
              }
              type="submit"
              onClick={onSubmit}
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              Verify {loading && <LoadingIcon />}
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    height: "10px !important", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(0, 0, 2),
  },
}));

const mapState = ({ auth }) => ({
  loading: auth.loading,
  error: auth.error,
  passwordReset: auth.passwordReset,
});

export default connect(mapState, { newPassword })(ResetPassword);
