import { connect } from "react-redux";
import Employee from ".";
import { getEmployee } from "../employees/duck/actions";
import {
  createWorklog,
  getWorklogs,
  reviewWorklog,
  getReview,
} from "./duck/actions";
import { createProject } from "../projects/duck/actions";
import { createTask } from "../tasks/duck/actions";
import { getUserBehaviours } from "../skills/duck/actions";

const mapStateToProps = ({ worklogs: { allIds, byId, loading }, employees, behaviours, reviews, }) => {
  return {
    employee: employees.allIds.map((id) => employees.byId[id])[0] || {},
    worklogs: allIds.map((id) => byId[id]),
    review: reviews.allIds.map((id) => reviews.byId[id])[0] || {},
    behaviours: behaviours.allIds.map((id) => behaviours.byId[id]),
    loading,
    byId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmployee: (smeId, id) => dispatch(getEmployee(smeId, id)),
    getBehaviours: (userId) => dispatch(getUserBehaviours(userId)),
    getReview: (id) => dispatch(getReview(id)),
    createWorklog: (data, isOnline, userId) => dispatch(createWorklog(data, isOnline, userId)),
    createProject: (data, isEmployee) => dispatch(createProject(data, isEmployee)),
    createTask: (data, isEmployee) => dispatch(createTask(data, isEmployee)),
    getWorklogs: (employeeId, smeId) => dispatch(getWorklogs(employeeId, smeId)),
    reviewWorklog: (data) => dispatch(reviewWorklog(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Employee);
