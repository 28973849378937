import React from 'react';
import { Spinner } from 'react-bootstrap';

function Loader() {
  return (
    <div style={{ marginTop: '300px' }} className="row">
      <div className="col-md-12">
        <div className="center-text">
          <Spinner animation="grow" variant="primary" />
          <Spinner animation="grow" variant="secondary" />
          <Spinner animation="grow" variant="success" />
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="warning" />
          <Spinner animation="grow" variant="info" />
          <Spinner animation="grow" variant="dark" />
        </div>
      </div>
    </div>
  );
}

export default Loader;
