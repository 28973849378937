/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import SignupForm from "./Register";
import "./styles.css";

function SignupComponent({ auth: { loading, error }, login }) {
  const [values, setValue] = useState({});

  const [hidePassword, toggleHidePassword] = useState(true);

  function handleSelect({ value }, { name }) {
    setValue({ ...values, [name]: value });
  }

  function onChange({ target }) {
    setValue({ ...values, [target.name]: target.value });
  }

  function onSubmit(e) {
    e.preventDefault();
    return login(values, "signup");
  }

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div className="hero"></div>;
      <SignupForm
        hidePassword={hidePassword}
        handleSelect={handleSelect}
        toggleHidePassword={toggleHidePassword}
        loading={loading}
        error={error}
        onChange={onChange}
        values={values}
        onSubmit={onSubmit}
      />
    </div>
  );
}

export default SignupComponent;
