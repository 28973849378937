import { connect } from 'react-redux';
import RateComponent from './RateComponent';
import { addBlanketReview } from './duck/action';

const mapDispatchToProps = dispatch => {
  return {
    addBlanketReview: (token, history, setLoading, data) =>
      dispatch(addBlanketReview(token, history, setLoading, data)),
  };
};

export default connect(null, mapDispatchToProps)(RateComponent);
