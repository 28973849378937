import React, { Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import VisibilityIcon from "../../images/VisibilityIcon";
import VisibilityOffIcon from "../../images/VisibilityOffIcon";

export const COLORS = {
  default: "#D9DFEA",
  focused: "#35CAE2",
  error: "#FF647C",
  disabled: {
    backgroundColor: "rgba(217,223,234,0.7)",
    color: "#AFB5C1",
  },
};

const customInput = ({
  fullWidth,
  width,
  currency,
  toggleVisibility,
  error,
  errorMessage,
  hidden,
  renderAction,
  defaultValue,
  ...props
}) => {
  if (props.type === "password") {
    return (
      <Fragment>
        <StyledInputContainer fullWidth={fullWidth} width={width}>
          <input {...props} className={"form-control"} type={hidden ? "password" : "text"} />
          {hidden ? (
            <VisibilityOffIcon
              onClick={() => toggleVisibility(false)}
              style={iconStyle}
            />
          ) : (
            <VisibilityIcon
              onClick={() => toggleVisibility(true)}
              style={iconStyle}
            />
          )}
        </StyledInputContainer>
        {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Fragment>
    );
  }
  return (
    <Fragment>
      <StyledInputContainer
        currency={currency}
        fullWidth={fullWidth}
        width={width}
        className={"mb-2"}
      >
        {currency && <span style={currencyStyle}>{currency}</span>}
        <input defaultValue={defaultValue} className="form-control" {...props} />
        <div style={iconStyle}>{renderAction && renderAction()}</div>
      </StyledInputContainer>
      {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Fragment>
  );
};

export const Input = customInput;

export const TextInputLabel = styled.label`
  text-align: left;
`;

const StyledTextInputGroup = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? "row" : "column")};
`;

export const TextInputGroup = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? "row" : "column")};
`;

const ErrorMessage = styled.span`
  color: ${COLORS.error}
  font-size: 13px;
`;

const StyledInputContainer = styled.div`
  width: ${(props) => {
    if (props.fullWidth) return "100%";
    if (props.width) return `${props.width}px`;
    return "200px";
  }}
  display: flex;
  position: relative;
`;

const iconStyle = {
  position: "absolute",
  right: 20,
  top: 8,
  color: "rgba(0,0,0,0.8)",
  fontSize: 17,
  cursor: "pointer",
};

const currencyStyle = {
  position: "absolute",
  alignSelf: "center",
  paddingLeft: "10px",
  fontSize: "13px",
  color: "rgba(0,0,0,0.8)",
};

customInput.propTypes = {
  fullWidth: PropTypes.bool,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  width: PropTypes.number,
  hidden: PropTypes.bool,
  renderAction: PropTypes.func,
};

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  testId: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  hidden: PropTypes.bool,
  toggleVisibility: PropTypes.func,
};
