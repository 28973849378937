import React, { Fragment } from "react";
import { format } from "date-fns";
import { Input, Label } from "../teams/AddTeam";
import Select from "../../components/BaseSelect";
import { Form, Col, Row } from "react-bootstrap";
import { formatDate } from "../../helpers/utils";
import { TextInputLabel } from "../../components/TextInput/TextInput.style";
import ReactQuill from "react-quill";

function AddProject({
  handleChange,
  employees,
  handleSelect,
  values = {},
  description,
  onDescriptionChange,
  onDescriptionBlur,
}) {
  const latestDate = format(new Date(), "yyyy-MM-dd");
  return (
    <Fragment>
      <Input
        name="title"
        value={values.title || ""}
        onChange={handleChange}
        label="Project name*"
        placeholder="eg: Prepare balance sheet"
      />

      {/*<Input*/}
      {/*  name="description"*/}
      {/*  onChange={handleChange}*/}
      {/*  value={values.description || ""}*/}
      {/*  label="Project description, include what the completed deliverable should be*"*/}
      {/*  placeholder="Tell more about the project"*/}
      {/*/>*/}
      <TextInputLabel className={"mb-1"}>Description</TextInputLabel>
      <ReactQuill
        theme="snow"
        name="description"
        value={description || ""}
        onChange={onDescriptionChange}
        onBlur={onDescriptionBlur}
        placeholder={"Tell more about the project"}
      />

      {/* <div style={{ marginTop: 12 }}>
        <Input
          name="optional_url"
          onChange={handleChange}
          value={values.optional_url || ""}
          label="Optional Links"
          placeholder="http://optional-url.com"
        />
      </div>*/}

      <Row>
        <Col>
          <Input
            type="date"
            onChange={handleChange}
            value={formatDate(values.startDate) || ""}
            label="Start Date*"
            name="startDate"
            min={latestDate}
            placeholder="When is the Start date"
          />
        </Col>

        <Col>
          <Input
            min={latestDate}
            type="date"
            onChange={handleChange}
            value={formatDate(values.endDate) || ""}
            label="End Date*"
            name="endDate"
            placeholder="When is the Start date"
          />
        </Col>

        {Boolean(employees.length) && (
          <Fragment>
            <Label>Workers assigned to this project (at least one)</Label>
            <Select
              style={{ width: "100%" }}
              value={values.members || ""}
              getOptionLabel={(option) =>
                `${option.firstname} ${option.lastname}`
              }
              getOptionValue={(option) => option.id}
              options={employees}
              name="members"
              onChange={handleSelect}
              placeholder="Choose workers on this team to assign project"
              isMulti
            />
          </Fragment>
        )}
      </Row>
    </Fragment>
  );
}

export default AddProject;
