import React, { Component } from "react";
import PropTypes from "prop-types";
import WorklogContainer from "../modules/worklogs/WorklogContainer";
import SmeContainer from "../modules/teams/SmeContainer";
import TeamsContainer from "../modules/employeeTeams/teamsContainer";
import UserProfileComponent from "../modules/UserProfile/UserProfileComponent";

class Dashboard extends Component {
  render () {
    const {
      user : { roles, ...rest },
    } = this.props;

    // Todo allow every employee to view graph`
    switch (true) {
      // case roles.some((role) => role.name === "sme-manager"):
      //   return (
      //     <TeamsContainer {...this.props} user={rest} teams={rest.employers} />
      //   );
      case roles.length === 1 && roles[0].name === "sme-employee":
        // return <WorklogContainer {...this.props} user={this.props.user}/>;
        return <UserProfileComponent {...this.props} user={this.props.user}/>;
      case roles.some((role) => role.name === "sme-manager" && role.name === "sme-employee"):
        return <WorklogContainer {...this.props} user={this.props.user}/>;
      default:
        return <SmeContainer {...this.props} />;
    }
  }
}

Dashboard.propTypes = {
  user : PropTypes.object.isRequired,
};

export default Dashboard;
