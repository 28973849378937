export const CREATE_SME = 'CREATE_SME';
export const CREATE_SME_SUCCESS = 'CREATE_SME_SUCCESS';
export const CREATE_SME_FAILURE = 'CREATE_SME_FAILURE';

export const FETCH_SMES = 'FETCH_SMES';
export const FETCH_SMES_FAILURE = 'FETCH_SME_FAILURE';
export const FETCH_SMES_SUCCESS = 'FETCH_SMES_SUCCESS';

export const DELETE_SME_SUCCESS = 'DELETE_SME_SUCCESS';
export const DELETE_SME_FAILURE = 'DELETE_SME_FAILURE';

export const EDIT_SME_SUCCESS = 'EDIT_SME_SUCCESS';
export const EDIT_SME_FAILURE = 'EDIT_SME_FAILURE';
