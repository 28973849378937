import styled from 'styled-components';
import { COLORS } from '../TextInput/TextInput.style';

export const TextArea = styled.textarea`
  resize: none;
  outline: none;
  border: 1px solid ${COLORS.default};
  border-radius: 3px;
  background-color: #ffffff;
  height: 102px;
  font-size: 13px;
  font-family: 'Sofia Pro';
  padding-left: 16px;
  padding-top: 13px;
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  :focus {
    border: 1px solid ${COLORS.focused};
  }

  ::placeholder {
    color: ${COLORS.default};
  }
`;
