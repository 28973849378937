import React from 'react';
import { Dropdown, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import MaterialIcon from 'react-google-material-icons';

function TableWrapper({ headers, data = [], onRowClick = () => {}, children }) {
  return (
    <div style={{ height: '50vh', overflow: 'scroll' }}>
      <Table hover responsive>
        <thead>
        <tr>
          {
            headers.map((header, i) => (
              <th key={i}>{header.name}</th>
            ))
          }
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
          {data.map((record, i) => (
            <tr style={{ cursor: 'pointer' }} key={i}>
              {headers.map((header, i) => (
                <td onClick={() => onRowClick(record)} key={i}>
                  {record[header.dataIndex] || (header.render && header.render(record))}
                </td>
              ))}
              <td>
                <div className="pull-right">
                  <Dropdown>
                    <Dropdown.Toggle variant="link" id="moreOptions">
                      <MaterialIcon icon="more_vert" size={24} />
                    </Dropdown.Toggle>
                    {children && children({ record })}
                  </Dropdown>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

TableWrapper.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array,
  onRowClick: PropTypes.func,
  children: PropTypes.any,
};

export default TableWrapper;
