import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import MaterialIcon from 'material-icons-react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { images } from '../helpers/utils';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { APP_ROLES } from '../routes/roleList';

function NavContainer({
  links,
  match,
  history,
  isEmployee,
  updateNotification,
  roles,
  isEmployeeAndManager,
  logout,
  notifications = [],
}) {
  async function handleNotifyClick(data) {
    const url = data.url.split('/');
    const id = url[url.length - 1];
    await updateNotification(data.id);
    switch (data.type) {
      case 'Review':
        if (isEmployeeAndManager || isEmployee) {
          return history.push(`/?type=reviews&id=${id}`);
        }
        break;
      case 'Project':
        if (isEmployeeAndManager || isEmployee) {
          return history.push(`/?type=project&id=${id}`);
        }
        break;
      default:
        if (!isEmployee && data.type === 'Worklog') {
          return history.push(`/teams/${data.smeId}/employees/${data.creatorId}?type=${data.type}&id=${id}`);
        }
        return history.push('/notifications');
    }
  }

  return (
    <Navbar className="primary-navbar p-t-0 p-b-0" sticky="top" expand="lg" variant="light" bg="light">
      <Navbar.Brand as={Link} to="/">
        <img width="100" src={images.logo} alt="Skillnav" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="skillnav-navbar" />
      <Navbar.Collapse>
        <Nav className="mr-auto">
          {links.map((nav, i) => (
            <NavLink
              style={{
                borderBottom: match.path === nav.link ? '3px solid #F97B6B' : '',
              }}
              as={Link}
              to={nav.link}
              key={i}
            >
              {nav.name}
            </NavLink>
          ))}
        </Nav>
        {notifications.length > 0 && (
          <NavDropdown
            alignRight
            title={
              <Fragment>
                <NotifyIcon />
                <NotifyNumber>{notifications.length}</NotifyNumber>
              </Fragment>
            }
          >
            {notifications.length > 0 &&
              notifications.map((notify, i) => (
                <Notify onClick={() => handleNotifyClick(notify)} key={notify.id}>
                  {notify.message}
                </Notify>
              ))}
            <p
              style={{ textAlign: 'center', color: 'green', cursor: 'pointer' }}
              onClick={() => history.push('/notifications')}
            >
              Read all notifications
            </p>
          </NavDropdown>
        )}

        <NavDropdown alignRight title={<MaterialIcon icon="account_circle" size={30} />}>
          <NavDropdown.Item onClick={() => history.push('/profile/4884848')}>Profile</NavDropdown.Item>
          <NavDropdown.Item disabled>
            Your role:{' '}
            {isEmployeeAndManager ? (
              <>
                <p>Manager |Employee</p>
              </>
            ) : (
              <span>{roles.map(role => (role.id ? APP_ROLES[role.id].label : APP_ROLES[role].label))}</span>
            )}
          </NavDropdown.Item>
          <NavDropdown.Item onClick={logout}>
            <span>Sign out</span>
          </NavDropdown.Item>
        </NavDropdown>
      </Navbar.Collapse>
    </Navbar>
  );
}

const NotifyIcon = styled.div`
  position: relative;
  display: block;
  height: 50px;
  width: 50px;
  background: url('http://i.imgur.com/evpC48G.png');
  background-size: contain;
  text-decoration: none;
`;

const NotifyNumber = styled.span`
  position: absolute;
  padding: 10;
  right: 26px;
  top: 12px;
  color: #fff;
`;

const NavLink = styled(Nav.Link)`
  height: 19px;
  padding-top: 16px;
  padding-left: 36px;
  padding-right: 36px;
  position: relative;
  padding-bottom: 16px;
  color: #003271;
  font-family: 'Glacial Indifference';
  font-size: 16px;

  font-weight: bold;
  line-height: 19px;
  text-align: center;
  height: 100%;
`;

const Notify = styled.p`
  padding: 10px;
  width: 200px;
  margin: 0 auto;
  cursor: pointer;
  line-height: 1.5;
  letter-spacing: 1px;
  word-break: break-all;

  :hover {
    color: red;
  }
`;

export default withRouter(NavContainer);
