import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

export const Exception = ({ history }) => {
  return (
    <Fragment>
      <div style={styles.notAllowed}>
        <p>You are not authorized to see this page :)</p>
        <Button onClick={history.goBack} className="btn btn-outlined"></Button>
      </div>
    </Fragment>
  );
};

const styles = {
  notAllowed: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
};

Exception.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Exception;
