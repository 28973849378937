import React from "react";
import ReactSelect from "react-select";
import PropTypes from "prop-types";
import { Label } from "../modules/teams/AddTeam";
import * as classes from "classnames"

const customStyles = {
  control : (props) => ({
    // none of react-select's styles are passed to <Control />
    ...props,
    backgroundColor : "#fff",
    minHeight : 8,
  }),
  menu : (props) => {
    return {
      ...props,
      zIndex : 1000,
    };
  },
};

function Select ({
                   options, disabled = false, placeholder, label,
                   labelClassName= "", className = "", wrapperClassName="",
                   width = "100%", isMulti = true, height, ...rest
                 }) {
  return (
    <div style={{ width : width, height : height }} className={classes("multiselect", wrapperClassName)}>
      {label && <Label className={labelClassName + "mb-1"}>{label}</Label>}
      <ReactSelect
        isMulti={isMulti}
        className={classes("multiselect-dropdown form-control", className, { disabled })}
        options={options}
        placeholder={placeholder}
        isSearchable
        isDisabled={disabled}
        styles={customStyles}
        {...rest}
      />
    </div>
  );
}


Select.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  isMulti: PropTypes.bool,
};

export default Select;
