import axios from "axios";
import {
  CREATE_BEHAVIOUR,
  CREATE_BEHAVIOUR_FAILURE,
  CREATE_BEHAVIOUR_SUCCESS,
  DELETE_BEHAVIOUR_FAILURE,
  DELETE_BEHAVIOUR_SUCCESS,
  FETCH_BEHAVIOUR_FAILURE,
  FETCH_BEHAVIOUR_SUCCESS,
  GET_MASTERED_BEHAVIOURS,
  GET_MASTERED_BEHAVIOURS_FAILURE,
  GET_MASTERED_BEHAVIOURS_SUCCESS,
  UPDATE_BEHAVIOUR,
} from "./types";
import { API_ROOT } from "../../../helpers/constants";
import { normaliz } from "./normalizr";
import { showSnackbarError, showSnackbarSuccess } from "../../Snackbar/reducer";

export const createBehaviour =
  (data, setLoading, setOpen) => async (dispatch) => {
    try {
      const { data: resp } = await axios.post(`${API_ROOT}/skills`, data);
      dispatch({ type: CREATE_BEHAVIOUR_SUCCESS, payload: resp });
      dispatch(
        showSnackbarSuccess({ message: "Behaviour created successfully" })
      );
      setLoading(false);
      setOpen(false);
    } catch (e) {
      dispatch(
        showSnackbarError({ message: "Sorry error occurred, try again" })
      );
      dispatch({ type: CREATE_BEHAVIOUR_FAILURE, payload: e });
      setLoading(false);
    }
  };

export const uploadBehaviours = (data) => async (dispatch) => {
  try {
    const { data: resp } = await axios.post(
      `${API_ROOT}/behaviours/bulk`,
      data
    );
    dispatch({ type: CREATE_BEHAVIOUR_SUCCESS, payload: normaliz(resp) });
    dispatch(
      showSnackbarSuccess({ message: "Behaviour uploaded successfully" })
    );
  } catch (e) {
    dispatch(
      showSnackbarError({
        message:
          e.response.data.error.errors ||
          e.response.data.error.message ||
          "Sorry error occurred, try again ",
      })
    );
    dispatch({
      type: CREATE_BEHAVIOUR_FAILURE,
      payload: e.response.data.error.errors,
    });
  }
};

export const getBehaviours = (smeId) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_BEHAVIOUR });
    const { data: resp } = await axios.get(
      `${API_ROOT}/skills/library/smes/${smeId}`
    );

    /*let sumoYooyi = {
      "id" : 248,
      "title" : "Palyful",
      "description" : "<p>gome <strong>good beha</strong>viour</p>",
      "links" : [],
      "library" : false,
      "skillnavCategory" : null,
      "customCategory" : "Team Building",
      "skillnavLevel" : null,
      "customLevel" : "1",
      "creatorId" : 33,
      "smeId" : 8,
      "organizationId" : 6,
      "parentSkillId" : null,
      "createdAt" : "2021-10-20T06:05:52.071Z",
      "updatedAt" : "2021-10-20T06:05:52.071Z",
      "organization" : {
        "id" : 6,
        "ownerId" : 33,
        "name" : "BARGE Studios",
        "createdAt" : "2021-10-19T20:37:37.486Z",
        "updatedAt" : "2021-10-19T20:37:37.486Z"
      },
      "sme" : {
        "id" : 8,
        "name" : "Frontend Avengers",
        "location" : null,
        "avaterUrl" : null,
        "description" : null,
        "sector" : "Retail",
        "ownerId" : 33,
        "managerId" : 34,
        "organizationId" : 6,
        "createdAt" : "2021-10-19T20:38:55.470Z",
        "updatedAt" : "2021-10-19T20:38:55.470Z",
        "deletedAt" : null
      },
      "users" : [
        {
          "id" : 35,
          "firstname" : "Kwame",
          "lastname" : "Danso",
          "username" : null,
          "email" : "testingmic12345@mailinator.com",
          "password" : "$2a$10$4EZwM8h5RnzkUI1bDsDZMuZGITJCpYKIDXz3GGQ.os0jBzsG3MgY6",
          "address" : "Accra",
          "isVerified" : false,
          "organizationName" : null,
          "timezone" : null,
          "startDate" : "2021-10-08T00:00:00.000Z",
          "staffStrength" : null,
          "industry" : null,
          "contact" : null,
          "jobTitle" : "Senior Mgr",
          "lastActive" : null,
          "keyActivityId" : null,
          "levelId" : null,
          "organizationId" : 6,
          "createdAt" : "2021-10-19T20:40:54.203Z",
          "updatedAt" : "2021-10-19T20:40:54.224Z",
          "deletedAt" : null,
          "SkillUser" : {
            "userId" : 35,
            "skillId" : 248,
            "count" : 0,
            "isMastered" : false,
            "smeId" : 8,
            "selfAssigned" : false,
            "isAssigned" : true,
            "createdAt" : "2021-10-20T06:05:52.078Z",
            "updatedAt" : "2021-10-20T06:05:52.078Z"
          }
        }
      ],
      "creator" : {
        "id" : 33,
        "firstname" : "Kobina",
        "lastname" : "Koomson",
        "username" : null,
        "email" : "testingmic12@mailinator.com",
        "password" : "$2a$10$bX/xg/TUbcuu9RZr8DnWAOiPvphEp0rdfNM3cnS1xUEFs2Ie9f1la",
        "address" : null,
        "isVerified" : false,
        "organizationName" : null,
        "timezone" : null,
        "startDate" : null,
        "staffStrength" : "21 to 30",
        "industry" : "Finance, investing, and insurance",
        "contact" : null,
        "jobTitle" : null,
        "lastActive" : null,
        "keyActivityId" : null,
        "levelId" : null,
        "organizationId" : null,
        "createdAt" : "2021-10-19T20:37:37.473Z",
        "updatedAt" : "2021-10-19T20:37:37.473Z",
        "deletedAt" : null
      }
    };*/

    let groups = {};

    resp.forEach((behaviour, s) => {
      groups[behaviour.customCategory] = groups[behaviour.customCategory] || [];
      groups[behaviour.customCategory].push(behaviour);
    });

    console.log("behaviour groups", groups);

    // Getting the keys of JavaScript Object.
    let sortedGroups = Object.keys(groups)
      .sort() // Sort and calling a method on keys on sorted fashion.
      .reduce(function (Obj, key) {
        // Adding the key-value pair to the new object in sorted keys manner
        Obj[key] = groups[key];
        return Obj;
      }, {});

    let sorted = Object.values(sortedGroups)
      .map((category /**@type Array*/) => {
        return category.sort(function (a, b) {
          return a.customLevel - b.customLevel;
        });
      })
      .flat()
      .reverse();
    dispatch({ type: FETCH_BEHAVIOUR_SUCCESS, payload: normaliz(sorted) });
  } catch (e) {
    dispatch({ type: FETCH_BEHAVIOUR_FAILURE, payload: e });
  }
};

export const updateBehaviour = (data) => async (dispatch) => {
  const { id, ...rest } = data;
  try {
    await axios.patch(`${API_ROOT}/skills/${id}`, rest);
    dispatch({ type: UPDATE_BEHAVIOUR, payload: data });
    dispatch(
      showSnackbarSuccess({ message: "Behaviour updated successfully" })
    );
  } catch (e) {
    dispatch(showSnackbarError({ message: "Error deleting behaviour" }));
  }
};

export const deleteBehaviour = (id) => async (dispatch) => {
  try {
    await axios.delete(`${API_ROOT}/skills/${id}`);
    dispatch({ type: DELETE_BEHAVIOUR_SUCCESS, payload: id });
    dispatch(
      showSnackbarSuccess({ message: "Behaviour deleted successfully" })
    );
  } catch (e) {
    dispatch(showSnackbarError({ message: "Error deleting behaviour" }));
    dispatch({ type: DELETE_BEHAVIOUR_FAILURE, payload: e });
  }
};

export const getMasteredBehaviors = (id) => async (dispatch) => {
  dispatch({ type: GET_MASTERED_BEHAVIOURS });
  try {
    const { data: resp } = await axios.get(
      `${API_ROOT}/users/${id}/skills/evolving`
    );
    dispatch({
      type: GET_MASTERED_BEHAVIOURS_SUCCESS,
      payload: normaliz(resp),
    });
  } catch (e) {
    dispatch({ type: GET_MASTERED_BEHAVIOURS_FAILURE, payload: e });
  }
};

export const requestBadge =
  (userId, skillId, managerId) => async (dispatch) => {
    try {
      await axios.post(
        `${API_ROOT}/users/${userId}/skills/${skillId}/requestBadge`,
        {
          managerId,
        }
      );
      window.location.reload();
      dispatch(
        showSnackbarSuccess({
          message: "Your request for a badge on this skill is successful",
        })
      );
    } catch (e) {
      dispatch(
        showSnackbarError({ message: "Sorry error occurred, try again" })
      );
      window.location.reload();
      dispatch({ type: CREATE_BEHAVIOUR_FAILURE, payload: e });
    }
  };

export const copyBehaviours =
  (smeId, setLoading, setOpen) => async (dispatch) => {
    try {
      setLoading(true);
      await axios.patch(`${API_ROOT}/skills/library/smes/${smeId}/copy`);
      setLoading(false);
      showSnackbarSuccess({
        message: "Skills library records copied successfully",
      });
      dispatch(getBehaviours(smeId));
      setOpen(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };
