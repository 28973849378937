import React from 'react';

export default function ErrorIcon(props) {
  return (
    <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Alert/Danger/Dismiss" transform="translate(-15.000000, -15.000000)">
          <g id="Alert">
            <g>
              <g id="Alert-/-error_outline">
                <g transform="translate(13.000000, 13.000000)">
                  <g>
                    <polygon id="Bounds" points="0 0 25 0 25 25 0 25" />
                    <path
                      d="M11.4583333,15.625 L13.5416667,15.625 L13.5416667,17.7083333 L11.4583333,17.7083333 L11.4583333,15.625 Z M11.4583333,7.29166667 L13.5416667,7.29166667 L13.5416667,13.5416667 L11.4583333,13.5416667 L11.4583333,7.29166667 Z M12.4895833,2.08333333 C6.73958333,2.08333333 2.08333333,6.75 2.08333333,12.5 C2.08333333,18.25 6.73958333,22.9166667 12.4895833,22.9166667 C18.25,22.9166667 22.9166667,18.25 22.9166667,12.5 C22.9166667,6.75 18.25,2.08333333 12.4895833,2.08333333 L12.4895833,2.08333333 Z M12.5,20.8333333 C7.89583333,20.8333333 4.16666667,17.1041667 4.16666667,12.5 C4.16666667,7.89583333 7.89583333,4.16666667 12.5,4.16666667 C17.1041667,4.16666667 20.8333333,7.89583333 20.8333333,12.5 C20.8333333,17.1041667 17.1041667,20.8333333 12.5,20.8333333 L12.5,20.8333333 Z"
                      id="Icon"
                      fill="#FFFFFF"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
