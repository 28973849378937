import axios from "axios";
import {
  ADD_SKILL_SUCCESS,
  ADD_SKILL_FAILURE,
  FETCH_SKILLS_SUCCESS,
  FETCH_SKILLS_FAILURE,
  DELETE_SKILL_SUCCESS,
  UPDATE_SKILL_SUCCESSFUL,
  FETCH_SKILLS,
  FILTER_BEHAVIOURS,
  RESET_FILTER,
} from "./types";
import { API_ROOT } from "../../../helpers/constants";
import { normaliz } from "./normalizr";
import { showSnackbarSuccess, showSnackbarError } from "../../Snackbar/reducer";
import {
  FETCH_BEHAVIOUR_FAILURE,
  FETCH_BEHAVIOUR_SUCCESS,
} from "../../behaviors/duck/types";

export const uploadSkills = (data = [], orgId) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_SKILLS });
      await await Promise.allSettled(
        data.map((rec) =>
          axios.post(`${API_ROOT}/skills/library/organizations/${orgId}`, {
            ...rec,
          })
        )
      );
      window.location.reload();
      dispatch(
        showSnackbarSuccess({
          message: "Behaviours have been uploaded successfully",
        })
      );
    } catch (error) {
      throw error;
    }
  };

export const createSkill = (data, orgId, setLoading, setOpen) => async (dispatch) => {
    try {
      let { data: resp } = await axios.post(
        `${API_ROOT}/skills/library/organizations/${orgId}`,
        {
          ...data,
        }
      );
      dispatch({
        type: ADD_SKILL_SUCCESS,
        payload: { ...resp },
      });
      setOpen && setOpen(false);
      setLoading && setLoading(false);
      // window.location.reload();
      dispatch(
        showSnackbarSuccess({
          message: "Behaviours have been uploaded successfully",
        })
      );
    } catch (e) {
      setLoading && setLoading(false);
      dispatch({
        type: ADD_SKILL_FAILURE,
        payload: "Sorry error occured, Please try again",
      });
    }
  };

// /api/skills/library/organizations/orgId

export const getSkills = (smeId, userId) => async (dispatch) => {
  console.log(userId);
  try {
    dispatch({ type: FETCH_SKILLS });
    const { data: resp } = await axios.get(
      `${API_ROOT}/skills/library/smes/${smeId}/users/${userId}`
    );
    dispatch({ type: FETCH_SKILLS_SUCCESS, payload: normaliz(resp) });
  } catch (e) {
    dispatch({ type: FETCH_SKILLS_FAILURE, payload: e });
  }
};

export const getAdminSkills = (orgId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_SKILLS });
    const { data: resp } = await axios.get(`${API_ROOT}/skills/library/organizations/${orgId}`);


    let groups = {};

    resp.forEach((skill, s) => {
      groups[skill.customCategory] = groups[skill.customCategory] || [];
      groups[skill.customCategory].push(skill);
    });

    // Getting the keys of JavaScript Object.
    let sortedGroups = Object.keys(groups)
      .sort() // Sort and calling a method on keys on sorted fashion.
      .reduce(function(Obj, key) {
        // Adding the key-value pair to the new object in sorted keys manner
        Obj[key] = groups[key];
        return Obj;
      }, {});

    let sorted = Object.values(sortedGroups)
    .map((category /**@type Array*/) => {
        return category.sort(function (a, b) {
          return a.customLevel - b.customLevel;
        })
      }).flat().reverse();

    console.log("sorted from getAdminskills", sorted);

    dispatch({ type: FETCH_SKILLS_SUCCESS, payload: normaliz(sorted) });
  } catch (e) {
    dispatch({ type: FETCH_SKILLS_FAILURE, payload: e });
  }
};

export const updateSkill = (data, id) => async (dispatch) => {
  try {
    await axios.patch(`${API_ROOT}/skills/${id}`, data);
    dispatch({ type: UPDATE_SKILL_SUCCESSFUL, payload: { id, data } });
    dispatch(showSnackbarSuccess({ message: "Skill updated successfully" }));
  } catch (e) {
    dispatch(showSnackbarError({ message: "An error occurred in updating skill" }));
  }
};

export const deleteSkill = (id) => async (dispatch) => {
  try {
    await axios.delete(`${API_ROOT}/skills/${id}`);
    dispatch({ type: DELETE_SKILL_SUCCESS, payload: id });
    dispatch(showSnackbarSuccess({ message: "Skill deleted successfully" }));
  } catch (e) {
    dispatch(showSnackbarError({ message: "An error occurred in deleting skill" }));
  }
};

export const assignSkill = (data, setLoading, closeDrawer) => async (dispatch) => {
    try {
      setLoading(true);
      await axios.post(`${API_ROOT}/skills/library/assign`, data);
      dispatch({ type: DELETE_SKILL_SUCCESS, payload: data.skillId });
      dispatch(
        showSnackbarSuccess({
          message: "Behaviour has ben assigned successfully",
        })
      );
      closeDrawer(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      dispatch(
        showSnackbarError({
          message: "Sorry an error occurred in assigning behaviour, Please try again",
        })
      );
    }
  };

export const unAssignSkill = (data, setLoading, closeDrawer) => async (dispatch) => {
    try {
      setLoading(true);
      await axios.delete(`${API_ROOT}/skills/library/smes/${data.smeId}/users/${data.userId}/skills/${data.skillId}/unassign`);

      dispatch({ type: UPDATE_SKILL_SUCCESSFUL, payload: data.skillId });
      dispatch(showSnackbarSuccess({ message: "Behaviour has been unassigned successfully", }));
      closeDrawer(true);
    } catch (e) {
      setLoading(false);
      dispatch(showSnackbarError({ message: "Sorry an error occurred in assigning behaviour, Please try again", }));
    }
  };

export const getUserBehaviours = (userId) => async (dispatch) => {
  try {
    const { data: resp } = await axios.get(`${API_ROOT}/users/${userId}/skills/evolving`);
    dispatch({ type: FETCH_BEHAVIOUR_SUCCESS, payload: normaliz(resp) });
  } catch (e) {
    dispatch({ type: FETCH_BEHAVIOUR_FAILURE, payload: e });
  }
};

export const filterBehaviours = (id, setLoading) => async (dispatch) => {
  try {
    setLoading(true);
    dispatch({ type: RESET_FILTER });
    const { data } = await axios.get(
      `${API_ROOT}/skills/library/statistics/organizations/${id}`
    );
    dispatch({ type: FILTER_BEHAVIOURS, payload: data });
    setLoading(false);
  } catch (error) {
    setLoading(false);
    throw error;
  }
};
