import React from "react";
import { Button } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import renderHTML from "react-render-html";

function SkillDetail({
  title,
  description,
  links,
  loading,
  assignSkill,
  unAssignSkill,
  showButton = true,
  isEmployee,
  onEdit,
  ...props
}) {
  const { selfAssigned } = props;

  return (
    <div>
      <div style={{ marginTop: 10 }}>
        <strong>Skill</strong> : <span style={{ fontSize: 18 }}>{title}</span>
      </div>
      {description && (
        <div style={{ paddingTop: 20 }}>
          <div>
            <strong>Description</strong>:
          </div>
          {renderHTML(description)}
        </div>
      )}

      {links?.length > 0 && (
        <div style={{ marginTop: 15 }}>
          <h3>Additional Reading:</h3>
          <div>
            {links.map((link, i) => (
              <a
                style={{ fontWeight: "bold" }}
                key={i}
                href={link}
                target="__blank"
              >
                Read more
              </a>
            ))}
          </div>
        </div>
      )}
      {isEmployee && (
        <div>
          {selfAssigned ? (
            <Button
              onClick={unAssignSkill}
              disabled={loading}
              style={{
                backgroundColor: deepOrange[300],
                color: "white",
                marginTop: 20,
              }}
            >
              {loading ? "Unassign Skill..." : "Un-assign Skill"}
            </Button>
          ) : (
            <Button
              onClick={assignSkill}
              disabled={loading}
              style={{
                backgroundColor: deepOrange[500],
                color: "white",
                marginTop: 20,
              }}
            >
              {loading ? "Assigning Skill..." : "Assign  Skill to Myself"}
            </Button>
          )}
        </div>
      )}
      {!isEmployee && (
        <>
          <Button
            onClick={() => onEdit({ description, title, links, ...props })}
            style={{
              marginTop: 20,
              color: "white",
              backgroundColor: "#3f51b5",
              float: "right",
            }}
          >
            Edit
          </Button>
        </>
      )}
    </div>
  );
}

export default SkillDetail;
