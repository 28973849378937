import Drawer from "../../components/DrawerWrapper";
import React, { useState } from "react";
import uuid from "uuid/v4";
import moment from "moment";
import useOfflineOnline from "../../hooks/useOfflineStatus";
import { Col, Row } from "react-bootstrap";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from "@material-ui/lab";
import RenderHTML from "../../components/RenderHTML";

const taskFieldId = uuid();
const initialState = { [taskFieldId] : {} };
const projectFieldId = uuid();
const initialProject = { [projectFieldId] : {} };


export function ViewReflectionsDrawer ({
                                         open, onClose, shiftHappens, match, setAddWorklogDrawerOpen, worklogs,
                                         reflections = [],
                                         employee, user, behaviours, createWorklog,
                                       }) {

  const [ data, setData ] = useState(initialState);
  const [ record, setRecord ] = useState({ projects : [], tasks : [] });
  const [ projectData, setProjectData ] = useState(initialProject);
  const [ feedback, setFeedback ] = useState("");
  const [ feeling, setFeeling ] = useState("");
  const { isOnline } = useOfflineOnline();

  let today = new Date();


  let reflectionsLastIndex = Array.isArray(reflections) ? reflections.length - 1 : 0;

  function onSubmit (type) {
    setAddWorklogDrawerOpen(false);
    const rep = {
      ...record,
      feedback,
      projects : record.projects.filter((pro) => Boolean(pro.log)), // Remove all projects and tasks with no log
      tasks : record.tasks.filter((task) => Boolean(task.log)),
      smeId : (user && match?.params?.employerId) || user.employers?.[0]?.id,
    };
    return createWorklog(rep, isOnline, user.id);
  }


  let drawerHeader = (
    <h4 className={"mb-4 text-primary font-weight-600"}>Reflections</h4>
  );


  return <Drawer
    width="700px"
    formType={"create"}
    title={drawerHeader}
    open={open}
    noFooter
    // onSubmit={onSubmit}
    onClose={onClose}
  >
    <Row className={"mb-3"}>
      <Col>
        <Timeline>
          {
            reflections && reflections.map(({ date, skill, reflection }, r) => {
              return (
                <TimelineItem key={r} className={""}>
                  <TimelineOppositeContent className={"col-auto my-auto"} style={{ flex : 'unset' }}>
                    <h6 className={"text-secondary text-small-normal text-center mb-0 font-weight-600"}>
                      <span>{moment(date).format("ddd")}</span> {" "}
                      <span className={"text-muted text-center font-weight-600"}>{moment(date).format("DD")}</span>
                    </h6>
                    <span className={"text-muted text-center text-small-normal font-weight-600"}>{moment(date).format("MMM, YYYY")}</span>

                  </TimelineOppositeContent>

                  <TimelineSeparator>
                    {r !== 0 && <TimelineConnector/>} {/*DON'T show the head connector for the first*/}
                    <TimelineDot color={"primary"} variant="outlined" className={"my-1"}/>
                    <TimelineConnector/>
                  </TimelineSeparator>

                  <TimelineContent sx={{ py : '12px', px : 2 }} className={"my-2 d-flex"}>
                    <RenderHTML html={reflection} tag={"p"} className={"my-auto"}/>
                  </TimelineContent>
                </TimelineItem>
              )
            })
          }
        </Timeline>
      </Col>
    </Row>
  </Drawer>;
}
