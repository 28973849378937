import { connect } from "react-redux";
import SmeComponent from ".";
import { createSme, getOwnerSme, editSme, deleteSme } from "./duck/actions";

const mapStateToProps = ({ smes: { allIds, byId, loading } }) => {
  return {
    smes: allIds.map((id) => byId[id]),
    loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSme: (data, setLoading, setOpen) => dispatch(createSme(data, setLoading, setOpen)),
    getOwnerSmes: (ownerId, isOwner, superAdmin) =>
      dispatch(getOwnerSme(ownerId, isOwner, superAdmin)),
    editSme: (id, record) => dispatch(editSme(id, record)),
    deleteSme: (id) => dispatch(deleteSme(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SmeComponent);
