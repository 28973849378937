import React from "react";
import * as PropTypes from "prop-types";
import { InfoOutlined } from "@material-ui/icons";

/**
 * @param messages {Array<String>|String}
 * @param {String} [title]
 * @param {String<left|right>} [direction]
 * @param [minWidth]
 * @param {Array<String>|String} [key] key = ["X = (0..9)", "& = (A..Z)"]
 * @returns {JSX.Element}
 * @constructor
 */
export function Hint ({ title = "", direction = 'left', minWidth = 300, messages, key = false, children }) {
  return (
    <span className={"hint-icon text-gray " + direction}>
      <InfoOutlined className={'text-primary'}/>
      {/*<i className="fa fa-info-circle"></i>*/}
      {
        children ? children : <span className="text-gray validation-hint" style={{ minWidth : minWidth + 'px' }}>
          {title !== "" && <h6 className="heading text-smaller mb-1">{title}</h6>}
          {
            Array.isArray(messages) ?
              messages.map((format, i) => <p className="format" key={i}>{format}</p>) :
              <p className="format ">{messages}</p>
          }
          {
            key && <>
              <span className="diamond"/>
              <small className="">Where </small>
              {
                (Array.isArray(key) ?
                  key.map((k, j) => <span className="" key={j}>{k} </span>) :
                  <small className="">{key}</small>)
              }
            </>
          }
      </span>
      }

  </span>
  );
}

Hint.propTypes = {
  title : PropTypes.string,
  direction : PropTypes.string,
  messages : PropTypes.oneOf([ PropTypes.array, PropTypes.string ]),
  key : PropTypes.any
}
