import React from "react";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import TextInput from "../../components/TextInput";
import { ReactComponent as LoadingIcon } from "../../images/load.svg";
import { images } from "../../helpers/utils";
import * as classNames from "classnames";

export default function SignIn ({
                                  onSubmit,
                                  hidePassword,
                                  toggleHidePassword,
                                  onChange,
                                  values,
                                  loading,
                                  error,
                                }) {
  const classes = useStyles();

  return (
    <div className="lg-form">
      <CssBaseline/>
      <div className={classes.paper}>
        <img
          style={{ margin : "0 auto", paddingBottom : "2%" }}
          width="150"
          src={images.logo}
          alt="logo"
        />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {
          error && (<Alert severity="error">Sorry invalid credentials, try again!</Alert>)
        }
        <form className={classes.form} noValidate>
          <TextInput
            onChange={onChange}
            required
            fullWidth
            label="Email address"
            name="email"
          />
          <TextInput
            hidden={hidePassword}
            onChange={onChange}
            toggleVisibility={toggleHidePassword}
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
          />
          <Button
            disabled={!values.email || !values.password || loading}
            type="submit"
            onClick={onSubmit}
            fullWidth
            variant="contained"
            color="secondary"
            className={classNames(classes.submit, "mt-3")}
          >
            Sign In {loading && <LoadingIcon/>}
          </Button>
          <div className={classes.forgetPasswordText}>
            <Link href="/reset-password" variant="body2">
              Reset password
            </Link>
            <Link href="/signup" variant="body2">
              {"Open new account"}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  paper : {
    paddingTop : theme.spacing(5),
    overflow : "auto",
    // width: '25vw',
    height : "100vh",
    backgroundColor : "#ffffff",
    padding : "0 20px 20px 20px",
    boxShadow : "0 0 20px 2px rgba(0, 0, 0, 0.4)",
    textAlign : "center",
  },
  avatar : {
    margin : theme.spacing(1),
    backgroundColor : theme.palette.secondary.main,
  },
  form : {
    width : "100%",
    height : "10px !important", // Fix IE 11 issue.
    marginTop : theme.spacing(1),
  },
  submit : {
    margin : theme.spacing(0, 0, 2),
  },
  forgetPasswordText : {
    display : "flex",
    justifyContent : "space-between",
  },
}));
