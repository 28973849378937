import axios from "axios";
import {
  GET_MASTERED_BEHAVIOURS,
  GET_MASTERED_BEHAVIOURS_SUCCESS,
  GET_MASTERED_BEHAVIOURS_FAILURE,
  GET_PRACTICE_BEHAVIOUR,
  GET_PRACTICE_BEHAVIOURS_SUCCESS,
  GET_PRACTICE_BEHAVIOURS_FAILURE,
  RESCIND_SKILL_BADGE,
} from "./types";
import { API_ROOT } from "../../../helpers/constants";
import { showSnackbarSuccess, showSnackbarError } from "../../Snackbar/reducer";

export const getPracticedBehaviors = (id) => async (dispatch) => {
  dispatch({ type: GET_PRACTICE_BEHAVIOUR });
  try {
    const { data: resp } = await axios.get(
      `${API_ROOT}/users/${id}/skills/evolving`
    );
    dispatch({ type: GET_PRACTICE_BEHAVIOURS_SUCCESS, payload: resp.data });
  } catch (e) {
    dispatch({ type: GET_PRACTICE_BEHAVIOURS_FAILURE, payload: e });
  }
};

export const getMasteredBehaviors = (id) => async (dispatch) => {
  dispatch({ type: GET_MASTERED_BEHAVIOURS });
  try {
    const { data: resp } = await axios.get(
      `${API_ROOT}/users/${id}/skills/mastered`
    );

    dispatch({ type: GET_MASTERED_BEHAVIOURS_SUCCESS, payload: resp.data });
  } catch (e) {
    dispatch({ type: GET_MASTERED_BEHAVIOURS_FAILURE, payload: e });
  }
};

export const requestBadge =
  (userId, skillId, managerId) => async (dispatch) => {
    try {
      await axios.post(
        `${API_ROOT}/users/${userId}/skills/${skillId}/requestBadge`,
        {
          smeId: managerId,
        }
      );
      dispatch(
        showSnackbarSuccess({
          message:
            "Your request for a badge on this skill is successful, your manager is notified",
        })
      );
      window.location.reload();
    } catch (e) {
      dispatch(
        showSnackbarError({ message: "Sorry error occurred, try again" })
      );
      window.location.reload();
    }
  };

export const responseBadge = (userId, skillId, status) => async (dispatch) => {
  try {
    await axios.post(
      `${API_ROOT}/users/${userId}/skills/${skillId}/reeqestBadgeResponse`,
      {
        status: status,
      }
    );
    if (status === "revoke") {
      dispatch({ type: RESCIND_SKILL_BADGE, payload: skillId });
      return dispatch(showSnackbarSuccess({ message: 'Skill badge has been rescinded successfully' }));
    }

    return dispatch(showSnackbarSuccess({ message: 'skill badge request successfully granted approved' }));
  } catch (e) {
    dispatch(showSnackbarError({ message: "Sorry error occurred, try again" }));
  }
};
