import { connect } from "react-redux";
import EmployeeComponent from ".";
import {
  createEmployee,
  getEmployees,
  uploadEmployees,
  updateEmployee,
  deleteEmployee,
} from "./duck/actions";
import { getSme } from "../teams/duck/actions";

const mapStateToProps = ({
  employees: { allIds, byId, loading },
  smes: { byId: smeById },
}) => {
  return {
    employees: allIds.map((id) => byId[id]),
    sme: Object.values(smeById)[0],
    loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createEmployee: (data) => dispatch(createEmployee(data)),
    getEmployees: (smeId) => dispatch(getEmployees(smeId)),
    uploadEmployees: (data) => dispatch(uploadEmployees(data)),
    updateEmployee: (id, data) => dispatch(updateEmployee(id, data)),
    deleteEmployee: (id) => dispatch(deleteEmployee(id)),
    getSme: (smeId) => dispatch(getSme(smeId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeComponent);
