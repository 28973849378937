import React from "react";
import Table from "mpharma-react-table";
import { deepOrange } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core";
import { Badge } from "react-bootstrap";
import { ellipsify } from "../helpers/utils";
import RenderHTMl from "../components/RenderHTML";

function WorklogTable({
  isEmployee,
  isEmployeeAndManager,
  openDrawer,
  onRowClick,
  headers = [],
  data = [],
  isShiftHappens,
}) {
  const classes = useStyles();
  headers = [
    {
      title: "Date issued",
      align: "left",
      dataIndex: "create_date",
      render: (item) => item && new Date(item.createdAt).toDateString(),
    },
    {
      title : isShiftHappens ? "Helpers you harnessed" : "Task Performed",
      align : "left",
      dataIndex : "task",
      style : {maxWidth : 220},
      render : (item) => item && item.taskLogs.map((log, i) => (
        <span key={i}>
            <Badge className={"py-2 px-3 mb-1"} bg="secondary" pill size="small" label={log.title}>{log.title}</Badge>
            <span className={"d-block"}>{log.log}</span>
          </span>
        )),
    },
    {
      title: "Project Performed",
      align: "left",
      render: (item) =>
        item &&
        item.projectLogs.map((log, i) => (
          <span key={i}>
            <Badge
              className={"p-2 px-3"}
              bg="secondary"
              size="small"
              label={log.title}
            />
            <span className={"d-block"}>{log.log}</span>
          </span>
        )),
      dataIndex: "project",
    },
    {
      title: isShiftHappens ? "Reflection" : "Feedback",
      style: { width: 400, lineHeight : 1 },
      align: "left",
      dataIndex: "feedback",
      name: "Feedback",
      render: (item) =>
        item && <RenderHTMl html={ellipsify(item?.feedback, 180)} />,
    },
    {
      title: "Reviewed",
      align: "left",
      dataIndex: "reviewed",
      render: (item) =>
        (item && item.review) && (<Badge className={"px-3 py-2"} pill variant={"primary"} title={"Reviewed"}>Reviewed</Badge>),
    },
    // {
    //   title: "Date Reviewed",
    //   align: "left",
    //   dataIndex: "reviewed",
    //   render: (item) =>
    //     item && new Date(item.reviewedAt && item.reviewedAt).toDateString(),
    // },
    // {
    //   title: "What went well",
    //   dataIndex: "reviewed",
    //   render: (item) =>
    //     item &&
    //     item.review && (
    //       <ul>
    //         {item.review.feedback &&
    //         item.review.feedback.map((imp, i) => <li key={i}>{imp}</li>)}
    //       </ul>
    //     ),
    // },
    // {
    //   title: "What did not go well",
    //   dataIndex: "improvement",
    //   render: (item) =>
    //     item &&
    //     item.review && (
    //       <ul>
    //         {item.review.improvement &&
    //         item.review.improvement.map((imp, i) => <li key={i}>{imp}</li>)}
    //       </ul>
    //     ),
    // },
  ];
  return (
    <div className={classes.root}>
      <div className={classes.table}>
        <Table
          handleRowClick={onRowClick}
          headers={headers}
          rowsPerPageOptions={[25]}
          data={data}
          emptyMessageStyle={{
            left: "45%",
            position: "absolute",
            fontSize: 25,
            width: "80%",
            // paddingTop: 20,
          }}
          emptyMessage={`No worklogs available`}
          tableStyle={{
            margin: "0 auto",
            width: "100%",
            backgroundColor: "#fff",
          }}
          tableBodyStyle={{
            maxHeight: "50vh",
          }}
          hover
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  // root: {
  //   paddingTop: "1%",
  // },
  // table: {
  //   paddingTop: "2%",
  // },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  button: {
    float: "right",
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
}));

export default WorklogTable;
