import Dexie from "dexie";

const db = new Dexie("skillnav");

export function setup() {
  db.version(1).stores({
    outBox: "++id",
  });

  db.open()
    .then(function (db) {
      // Database opened successfully
    })

    .catch(function (err) {
      throw err;
    });
}

export default db;
