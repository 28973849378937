import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from "../../components/BaseSelect";
import { Button } from "@material-ui/core";
import { deepOrange } from "@material-ui/core/colors";
import RenderHTML from "../../components/RenderHTML";
function BehaviourDetails({
  title,
  id,
  description,
  loading,
  assignSelfSkill,
  employees = [],
  links = [],
}) {
  const [employee, setEmployee] = useState(null);

  function onSelect({ id }) {
    setEmployee(id);
  }

  return (
    <div>
      <div>
        <h3>
          Skill : <strong>{title}</strong>
        </h3>
      </div>
      {description && (
        <div style={{ marginTop: "3%" }}>
          <h3>Description</h3>
          <RenderHTML html={description} />
        </div>
      )}
      {links && (
        <div style={{ marginTop: "3%", marginBottom: 10 }}>
          <h3>Learn more</h3>
          <p>
            {links &&
              links.map((link, i) => (
                <a target="__blank" key={i} href={link}>
                  {link}
                </a>
              ))}
          </p>
        </div>
      )}
      <Select
        onChange={onSelect}
        getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
        getOptionValue={(option) => option.id}
        isMulti={false}
        label="Select employee to assign behaviour"
        options={employees}
        placeholder="Select employees to assign behaviour"
      />

      <Button
        onClick={() => assignSelfSkill(id)}
        disabled={loading || !employee}
        style={{
          backgroundColor: deepOrange[300],
          color: "white",
          marginTop: 20,
        }}
      >
        {loading ? "Assigning Skill..." : "Assign Skill"}
      </Button>
    </div>
  );
}

BehaviourDetails.propTypes = {
  name: PropTypes.string,
  skills: PropTypes.array,
  description: PropTypes.string,
  links: PropTypes.array,
};

export default BehaviourDetails;
